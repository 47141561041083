import * as React from 'react'

import { IWithExistentUser } from '../../../../../types/interfaces'

import DashboardChangeUsername from './DashboardChangeUsername'
import Input from '../../../../components/form/Input'
import DashboardChangePassword from './DashboardChangePassword'
import dispatchMessage from '../../../../messages/dispatchMessage'
import { Api, Auth, PrivateApi, PromiseButton } from '@faceyourmanga/fym-shared'
import ConfirmPromise from '../../../../components/Confirm'

function DashboardUserSettings(props: IWithExistentUser) {
	const [bMoreSettings, setMoreSettings] = React.useState(false)
	const [nickname, setNickname] = React.useState(props.user.nickname || '')

	async function changeNickname() {
		try {
			await PrivateApi.put('v1/me', { nickname })
			dispatchMessage('Nickname changed')
			Auth.setUser({ ...props.user, nickname })
		} catch (e) {
			dispatchMessage('An error has occurred')
		}
	}

	async function deleteAccount() {
		if (await ConfirmPromise('The account cannot be restored. Are you sure you want to continue?')) {
			await Api.post(`auth/delete-account`)
			Auth.signout()
		}
	}

	return (
		<div className="dashboard__user-settings">
			<div className="dashboard__user-settings__row">
				<div>
					<label>Country</label>
					<b>{props.user.nationality}</b>
				</div>
				{props.user.email && (
					<div>
						<label>Email</label>
						<b>{props.user.email}</b>
					</div>
				)}
			</div>

			<div className="dashboard__user-settings__row">
				<Input label="Name" value={nickname} onChange={setNickname} />
				<PromiseButton
					className={`button ${
						nickname === props.user.nickname ? 'button--disabled' : ''
					} button--green button--rounded button--small button--save`}
					onClick={changeNickname}
				>
					CHANGE
				</PromiseButton>
			</div>

			<div className="dashboard__user-settings__row">
				<DashboardChangeUsername user={props.user} />
			</div>

			<div className="dashboard__user-settings__row">
				{props.user.email && <DashboardChangePassword email={props.user.email} />}
			</div>

			
			<div className="dashboard__user-settings__delete">
				<div style={{margin: '0 0 2rem'}}>
					<button className="button" onClick={() => setMoreSettings(!bMoreSettings)}>MORE SETTINGS</button>
				</div>

				{ bMoreSettings && ( 
					<div>
						<button className="button button--red button--rounded" onClick={deleteAccount}>
							Delete my account
						</button>
						<div>
							<small>
								All your personal data will be ereased and you’re not be able to access anymore. your avatar, purchases and
								downloads will be lost. This action cannot be undone.
							</small>
						</div> 
					</div>
				)}
			</div>
		</div>
	)
}

export default React.memo(DashboardUserSettings)
