import { Auth } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import LoadingIcon from '../components/LoadingIcon'

function OAuth() {
	var params = new URLSearchParams(window.location.search)
	var code = params.get('code')
	var type = params.get('state')

	const [logged, setLogged] = React.useState<boolean>(false)
	const [error, setError] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (type && code) {
			Auth.oauth(code, type)
				.then(() => {
					setLogged(true)
				})
				.catch(e => {
					setError(true)
				})
		} else {
			setError(true)
		}
	}, [type, code])

	if (error) {
		return (
			<section className="app__page oauth">
				<div className="app__content">
					<div className=" oauth__error">
						<h2>We were unable to log in, check the credentials of the chosen provider</h2>

						<Link className="button" to="/sign-up">
							SIGN UP
						</Link>
					</div>
				</div>
			</section>
		)
	}

	if (logged) {
		return <Redirect to="/" />
	}

	return (
		<section className="app__page oauth">
			<div className="app__content">
				<div className=" oauth__progress">
					<LoadingIcon />
					<h2>Login in progress</h2>
				</div>
			</div>
		</section>
	)
}

export default React.memo(OAuth)
