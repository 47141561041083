import { IPublicAvatar } from '@faceyourmanga/fym-lib'
import { Auth, PrivateApi } from '@faceyourmanga/fym-shared'
import React from 'react'
import { RiDeleteBin2Line } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IWithExistentUser } from '../../../../types/interfaces'
import { IRootState } from '../../../../types/store'
import AdsH from '../../../ads/AdsH'
import UserProfileImage from '../../../components/UserProfileImage'

function DashboardProfile(props: IWithExistentUser & { bMobile: boolean }) {
	async function removeProfileImage() {
		try {
			await PrivateApi.delete<IPublicAvatar>(`v1/me/thumb`)

			Auth.setUser({ ...props.user, thumb: undefined })
		} catch (e) {
			console.log([e])
		}
	}

	return (
		<section className="dashboard__profile__meta">
			{props.bMobile && <AdsH slot={'4725160523'} />}

			<div className="dashboard__profile__meta__image">
				<UserProfileImage user={props.user} />

				{props.user.thumb && (
					<div className="dashboard__profile__meta__image__delete">
						<RiDeleteBin2Line onClick={removeProfileImage} />
					</div>
				)}
			</div>

			<div className="dashboard__profile__meta__info">
				{props.user.nickname && <div className="dashboard__profile__meta__info__nickname">{props.user.nickname}</div>}

				{props.user.username && (
					<div className="dashboard__profile__meta__info__username">
						<b>@{props.user.username}</b>{' '}
						<small className="dashboard__profile__meta__info__username__link">
							<Link to={`/@${props.user.username}`}>view public profile</Link>
						</small>
					</div>
				)}

				<button
					className="button button--small button--gray"
					onClick={() => {
						Auth.signout()
					}}
				>
					Logout
				</button>
			</div>

			{!props.bMobile && <AdsH slot={'4685594081'} />}
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		bMobile: state.device.bMobile,
	}))(DashboardProfile)
)
