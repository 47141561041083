import * as React from 'react'
import * as ReactDOM from 'react-dom'

const container = document.getElementById('alert-root') as HTMLDivElement

interface AlertProps {
	onResult: (data: boolean) => void
	label: string | JSX.Element
}

const Alert: React.FunctionComponent<AlertProps> = ({ onResult, label }: AlertProps) => {
	const alertRef = React.createRef<HTMLDivElement>()

	React.useEffect(() => {
		function alert_focus() {
			if (alertRef.current) {
				const container = alertRef.current as HTMLDivElement

				container.classList.remove('alert--focus')
				setTimeout(() => {
					container.classList.add('alert--focus')
					setTimeout(() => {
						container.classList.remove('alert--focus')
					}, 500)
				})
			}
		}

		container.className = 'open'
		document.addEventListener('click', alert_focus, { passive: true })

		return () => {
			container.className = ''
			document.removeEventListener('click', alert_focus)
		}
	}, [alertRef])

	return (
		<div ref={alertRef} className="alert">
			<div>
				<div className="alert__label">{label}</div>
				<div className="alert__buttons">
					<button className="button button--small alert__button" onClick={() => onResult(true)}>
						OK
					</button>
				</div>
			</div>
		</div>
	)
}

const Empty = () => null

let id = 0
function AlertPromise(label: string | JSX.Element): void {
	function handleResult() {
		ReactDOM.render(<Empty />, container)
	}

	ReactDOM.render(<Alert key={++id} onResult={handleResult} label={label} />, container)
}

export default AlertPromise
