import * as React from 'react'
import * as ReactDOM from 'react-dom'

import './extension'

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import store from './redux/root'
import { base } from './redux/app/appDefaultState'

import App from './app/App'

// initData={initData}
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter basename={base}>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// function AdBlockDetect() {
// 	const adBlockDetected = useDetectAdBlock()

// 	React.useEffect(() => {
// 		if (adBlockDetected) {
// 			console.log('ad block detected')
// 		}
// 	}, [adBlockDetected])

// 	return <div>{adBlockDetected ? 'yes ad block' : 'No adblock'}</div>
// }

// function useDetectAdBlock() {
// 	const [bAdblock, setbAdblock] = React.useState(false)

// 	React.useEffect(() => {
// 		try {
// 			fetch(
// 				new Request('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
// 					method: 'HEAD',
// 					mode: 'no-cors',
// 				})
// 			).catch(error => {
// 				setbAdblock(true)
// 			})
// 		} catch (e) {
// 			// Request failed, likely due to ad blocker
// 			setbAdblock(true)
// 		}
// 	}, [])

// 	return bAdblock
// }
