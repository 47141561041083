import * as React from 'react'
import { RiMenuLine } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toggleMenu } from '../redux/app/actions'
import { IRootState } from '../types/store'
import Nav from './Nav'

interface IHeaderProps {
	bMobile: boolean
	bHomepage: boolean
	toggleMenu: () => void
}

interface IScrollStatus {
	lastUpdate: number
	lastScroll: number
	minimized: boolean
}

function Header(props: IHeaderProps) {
	const _scrollY = window.scrollY

	const [scrollStatus, setScrollStatus] = React.useState<IScrollStatus>({
		lastUpdate: 0,
		lastScroll: _scrollY,
		minimized: _scrollY > 0 || !props.bHomepage,
	})

	React.useEffect(() => {
		if (!props.bMobile) return

		if (!props.bHomepage) {
			if (!scrollStatus.minimized) {
				setScrollStatus({
					lastUpdate: 0,
					lastScroll: 0,
					minimized: true,
				})
			}
			return
		}

		if (props.bHomepage && scrollStatus.minimized && window.scrollY === 0) {
			setScrollStatus({
				lastUpdate: 0,
				lastScroll: 0,
				minimized: false,
			})
		}

		function handleScroll(e: Event) {
			const scrollY = window.scrollY

			const timestamp = e.timeStamp

			if (timestamp - scrollStatus.lastUpdate > 300 && scrollStatus.lastScroll !== scrollY) {
				const minimized = scrollY !== 0 && scrollY - scrollStatus.lastScroll > 0
				setScrollStatus({
					lastUpdate: scrollStatus.minimized !== minimized ? timestamp : scrollStatus.lastUpdate,
					lastScroll: scrollStatus.minimized === minimized ? scrollY : scrollStatus.lastScroll,
					minimized,
				})
			}
		}

		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [scrollStatus, props.bMobile, props.bHomepage])

	return (
		<header className={`app__header ${scrollStatus.minimized ? 'app__header--minimized' : ''}`}>
			<div className="app__content">
				<Link to="/" className="app__header__logo">
					<img className="app__header__logo__image" src="/assets/images/logo.svg" alt="faceyourmanga logo" />
				</Link>

				{props.bMobile ? <RiMenuLine onClick={props.toggleMenu} /> : <Nav />}
			</div>
		</header>
	)
}

export default React.memo(
	connect(
		(state: IRootState) => ({
			bMobile: state.device.bMobile,
			bHomepage: state.app.bHomepage,
		}),
		dispatch => ({
			toggleMenu: () => dispatch(toggleMenu()),
		})
	)(Header)
)
