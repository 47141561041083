import * as React from 'react'

const redirect = getenv('REACT_APP_OAUTH_REDIRECT_SIGNIN')

const facebookUrl =
	`https://www.facebook.com/v10.0/dialog/oauth` +
	`?client_id=${getenv('REACT_APP_FACEBOOK_CLIENT_ID')}` +
	`&state=facebook` +
	`&redirect_uri=${redirect}`

const googleUrl =
	`https://accounts.google.com/o/oauth2/v2/auth` +
	`?client_id=${getenv('REACT_APP_GOOGLE_CREDENTIAL_ID')}` +
	`&state=google` +
	`&response_type=code` +
	`&access_type=online` +
	`&include_granted_scopes=true` +
	`&prompt=consent` +
	`&scope=https://www.googleapis.com/auth/userinfo.profile` +
	`&redirect_uri=${redirect}`

function AuthProviders() {
	return (
		<div className="auth-providers">
			<div className="auth-providers__bar">OR</div>

			<div className="auth-providers__providers">
				<button
					onClick={() => (window.location.href = facebookUrl)}
					className="button auth-providers__providers__item auth-providers__providers__item--facebook"
				>
					{/* <img alt="login with facebook" src="/assets/images/facebook-logo.svg" /> */}
					Sign In with Facebook
				</button>
				<button
					onClick={() => (window.location.href = googleUrl)}
					className="button auth-providers__providers__item auth-providers__providers__item--google"
				>
					{/* <img alt="login with google" src="/assets/images/google-logo.svg" /> */}
					Sign In with Google
				</button>
			</div>
		</div>
	)
}

export default React.memo(AuthProviders)
