import { IPublicAvatarWithUsername } from '@faceyourmanga/fym-lib/dist'
import { useSimplePaginator } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiArrowDropLeftLine, RiArrowDropRightLine } from 'react-icons/ri'

interface IAvatarPaginatorProps {
	items: Array<any>
	forPage?: number
	children: (item: IPublicAvatarWithUsername, index: number) => JSX.Element
	fill?: boolean
}

function AvatarPaginator(props: IAvatarPaginatorProps) {
	const paginator = useSimplePaginator(props.items, props.forPage)
	const paginatorRef = React.createRef<HTMLDivElement>()

	if (props.fill) {
		const forPage = props.forPage || 2
		const list = paginator.list
		if (list.length < forPage) {
			for (let i = list.length; i < forPage; i++) {
				list[i] = undefined
			}
		}
	}

	React.useEffect(() => {
		setTimeout(() => {
			if (paginatorRef && paginatorRef.current) {
				paginatorRef.current.style.height = paginatorRef.current.offsetHeight + 'px'
			}
		}, 700)
	}, [paginatorRef])

	return (
		<div className="avatar-paginator">
			<div ref={paginatorRef} className="avatar-paginator__list">
				{paginator.list.map(props.children)}
			</div>
			<div className="avatar-paginator__actions">
				<div
					className={
						`avatar-paginator__actions__arrow avatar-paginator__actions__arrow--left avatar-paginator__actions__arrow--` +
						(paginator.bFirst ? 'disabled' : 'enabled')
					}
					onClick={paginator.prev}
				>
					<RiArrowDropLeftLine />
				</div>

				<div
					className={
						`avatar-paginator__actions__arrow avatar-paginator__actions__arrow--right avatar-paginator__actions__arrow--` +
						(paginator.bLast ? 'disabled' : 'enabled')
					}
					onClick={paginator.next}
				>
					<RiArrowDropRightLine />
				</div>
			</div>
		</div>
	)
}

export default React.memo(AvatarPaginator)
