import React from 'react'
import useAvatarCount from '../../hooks/useAvatarCount'

function chunkArray<T>(array: Array<T>, count: number): Array<Array<T>> {
	const result: Array<Array<T>> = []

	while (array.length > 0) {
		const l = array.length
		result.push(array.splice(l <= count ? 0 : l - count, count))
	}

	return result.reverse()
}

function HomeAvatarsCreated() {
	const { millions, total } = useAvatarCount()

	return total > 0 ? (
		<section className="app__content">
			<div className="home-avatars-created">
				<div className="home-avatars-created__counter">
					<h1>
						Over {millions} millions
						<br />
						of avatar created created!
					</h1>
					<div className="home-avatars-created__numbers">
						{chunkArray(total.toString().split(''), 3).map((chunks, ci) => (
							<div className="home-avatars-created__numbers__separator" key={ci}>
								{chunks.map((n, i) => (
									<span className="home-avatars-created__numbers__number" key={i}>
										{n}
									</span>
								))}
							</div>
						))}
					</div>
				</div>
				<div className="home-avatars-created__image">
					<img src="/assets/images/home/avatar-created.jpg" alt="Avatar created" />
				</div>
			</div>
		</section>
	) : null
}

export default React.memo(HomeAvatarsCreated)
