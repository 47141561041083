import { Store } from 'redux'
import { resetDeviceInformation } from './actions'

export default function bindDevice(store: Store) {
	window.addEventListener(
		'orientationchange',
		function () {
			store.dispatch(resetDeviceInformation())
		},
		{ passive: true }
	)

	let tid = 0
	const onResize = function () {
		if (tid) window.clearTimeout(tid)

		tid = window.setTimeout(() => {
			store.dispatch(resetDeviceInformation())
		}, 300)
	}

	window.addEventListener('resize', onResize, { passive: true })
}
