import React from 'react'
import { NavLink } from 'react-router-dom'
import Appear from '../../components/Appear'

interface IHomeSlideProps {
	bMobile: boolean
	logged: boolean
}

function HomeSlide(props: IHomeSlideProps) {
	const duration = 1600
	const baseDelay = 33
	const delayMultiplier = 60

	return (
		<section className="bg">
			<div className="app__content home-slide">
				<div className="home-slide__caption">
					<h1>
						<div>
							<Appear stopOnVisible={true} duration={duration} delay={baseDelay}>
								The world’s leading
							</Appear>
						</div>
						<div>
							<Appear stopOnVisible={true} duration={duration} delay={baseDelay + delayMultiplier}>
								avatar generator
							</Appear>
						</div>
						<div>
							<Appear stopOnVisible={true} duration={duration} delay={baseDelay + delayMultiplier * 2}>
								is back!
							</Appear>
						</div>
					</h1>
					<Appear stopOnVisible={true} duration={duration} delay={baseDelay + delayMultiplier * 3}>
						<NavLink to="/create">
							<button className="button button--shadowed button--big">GET STARTED</button>
						</NavLink>
					</Appear>
				</div>
				<div className="home-slide__image">
					<Appear
						stopOnVisible={true}
						origin="bottom center"
						duration={duration - (props.bMobile ? baseDelay + delayMultiplier * 4 : baseDelay)}
						delay={props.bMobile ? baseDelay + delayMultiplier * 4 : baseDelay}
						style={{ display: 'block' }}
					>
						<img src="assets/images/slide.png" alt="" />
					</Appear>
				</div>
			</div>
		</section>
	)
}

export default React.memo(HomeSlide)
