import { IPublicAvatar, Price } from '@faceyourmanga/fym-lib'
import { Api } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiCloseCircleLine } from 'react-icons/ri'
import CartController from '../../cart/CartController'
import { ITempCartItem } from '../../cart/CartTypes'
import ConfirmPromise from '../../components/Confirm'
import LoadingIcon from '../../components/LoadingIcon'
import { resolutionToNumber, sortPrices } from '../../utilities/pricelist'
import privateImageToBlob from '../../utilities/privateImageToBlob'

interface ICartItemProps {
	avatarUrl: string
	currency: string
	item: ITempCartItem
	availableFreePurchase: number
	prices: Record<string, Price>
}

function CartItem(props: ICartItemProps) {
	const [avatarRef, setAvatarRef] = React.useState<IPublicAvatar | null>(null)
	const [avatarImage, setAvatarImage] = React.useState<string | null>(null)

	React.useEffect(() => {
		Api.get<IPublicAvatar>('v1/avatars/' + props.item.avatar)
			.then(setAvatarRef)
			.then(() => {
				privateImageToBlob(getenv('REACT_APP_SERVER_URL') + 'v1/avatars/' + props.item.avatar + '/thumb').then(
					setAvatarImage
				)
			})
			.catch(e => {
				CartController.remove(props.item)
			})
	}, [props.item.avatar])

	if (!avatarRef || !props.prices || Object.keys(props.prices).length === 0) {
		return (
			<div>
				<LoadingIcon size="2" />
			</div>
		)
	}

	function isSmallesResolution() {
		if (props.item.resolution) {
			const resolutions: Array<number> = Object.keys(props.prices).map(resolutionToNumber)

			return Math.min.apply(null, resolutions) === resolutionToNumber(props.item.resolution)
		}

		return false
	}

	async function removeItem() {
		if (await ConfirmPromise('Are you sure?')) {
			CartController.remove(props.item)
		}
	}

	const bUseFreePurchase = props.availableFreePurchase > 0 && isSmallesResolution()

	return (
		<div className="cart__body__item cartItem">
			<div className="cartItem__col1">
				<div className="cartItem__delete" onClick={removeItem}>
					<RiCloseCircleLine />
				</div>
				<div className="cartItem__image">{avatarImage && <img src={avatarImage} alt="" />}</div>

				<div className="cartItem__resolution">
					<small>Select Format</small>
					<select
						className={`select select--${props.item.resolution ? 'valid' : 'invalid'}`}
						value={props.item.resolution}
						onChange={e =>
							CartController.update(props.item, { ...props.item, resolution: e.target.value, useFreePurchase: false })
						}
					>
						{sortPrices(props.prices).map(price => (
							<option key={price.resolution} value={price.resolution}>
								{price.name.toUpperCase()} ({price.resolution})
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="cartItem__col2">
				<div className="cartItem__free-purchase">
					{props.item.useFreePurchase ? (
						<button
							className="button button--tertiary button--small"
							onClick={() => CartController.add({ ...props.item, useFreePurchase: false })}
						>
							Remove Offer
						</button>
					) : bUseFreePurchase ? (
						<button
							className="button button--secondary button--small"
							onClick={() => CartController.add({ ...props.item, useFreePurchase: true })}
						>
							Apply Offer
						</button>
					) : (
						'-'
					)}
				</div>
			</div>

			<div className="cartItem__col3">
				<div className={`cartItem__price ${props.item.useFreePurchase ? 'cartItem__price--offer' : ''}`}>
					{props.item.useFreePurchase
						? '0 ' + props.currency
						: props.item.resolution
						? props.prices[props.item.resolution].price + ' ' + props.currency
						: '-'}
				</div>
			</div>
		</div>
	)
}

export default React.memo(CartItem)
