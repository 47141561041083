import * as React from 'react'

const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
const CURRENT_YEAR = new Date().getFullYear()
const DAYS = new Array(31).fill(1).map((v, i) => v + i)
const YEARS = new Array(90).fill(CURRENT_YEAR - 16).map((v, i) => v - i)

interface IState {
	year: string
	month: string
	day: string
}

const now = new Date()

function strToState(str?: string) {
	const parsed = str ? str.split('-') : [undefined, undefined, undefined]

	return {
		year: (parsed[0] || CURRENT_YEAR + '').padStart(4, '0'),
		month: (parsed[1] || now.getMonth() + '').padStart(2, '0'),
		day: (parsed[2] || now.getDate() + '').padStart(2, '0'),
	}
}

function SelectDate(props: { value?: string; onChange: (date: string) => void }) {
	const [state, setState] = React.useState<IState>(strToState())

	function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
		state[e.target.name as keyof IState] = e.target.value.padStart(2, '0')

		const dateStr = stateToString(state)

		if (isValidDate(dateStr)) {
			props.onChange(dateStr)
		}
	}

	function isValidDate(dateStr: string) {
		const date = Date.parse(dateStr)

		return !isNaN(date)
	}

	function stateToString(state: IState) {
		return `${state.year}-${state.month}-${state.day}`
	}

	// if (typeof props.value === 'undefined' || !isValidDate(props.value)) {
	// 	props.onChange(stateToString(state))
	// }

	React.useEffect(() => {
		if (typeof props.value !== 'undefined' && isValidDate(props.value)) {
			setState(strToState(props.value))
		}
	}, [props.value])

	return (
		<div className="select-birth">
			<select className="select" name="year" value={parseInt(state.year)} onChange={onChange}>
				{YEARS.map(year => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>
			<select className="select" name="month" value={parseInt(state.month)} onChange={onChange}>
				{MONTHS.map((month, i) => (
					<option key={month} value={i + 1}>
						{month}
					</option>
				))}
			</select>
			<select className="select" name="day" value={parseInt(state.day)} onChange={onChange}>
				{DAYS.map(day => (
					<option key={day} value={day}>
						{day}
					</option>
				))}
			</select>
		</div>
	)
}

export default React.memo(SelectDate)
