function getenv(field: keyof Window['env']): string {
	const value = window.env[field]

	if (value === undefined) {
		throw new Error(`Missing env variable [${field}]`)
	}

	return value
}

window.getenv = getenv

export {}
