import { IAppState } from '../../types/store'

const baseTags = document.getElementsByTagName('base')
const base = (baseTags && baseTags.length ? baseTags[0].href : document.URL).replace(window.location.origin, '')

////////////////////////

const defaultAppState: IAppState = {
	bHomepage: window.location.pathname === base,
	bMenuOpen: false,
}

////////////////////////

export { defaultAppState, base }
