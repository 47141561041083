import { Auth } from '@faceyourmanga/fym-shared'
import { blobToBase64 } from './blobToBase64'

export default function privateImageToBlob(endpoint: string): Promise<string> {
	return new Promise(resolve => {
		fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
			},
		}).then(response => {
			response.blob().then(blob => {
				blobToBase64(blob).then(image => {
					resolve(image)
				})
			})
		})
	})
}
