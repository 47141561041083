import { Auth, PrivateApi, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { Link } from 'react-router-dom'
import InputHashtags from '../components/form/InputHashtags'

function LoginRequiredModal({ close }: { close?: () => void }) {
	return (
		<div className="login-required-modal">
			<h2 className="login-required-modal__title">To perform this action you must be registered</h2>

			<p className="login-required-modal__message">
				Go to the login or registration page to access all the benefits of <b>faceyourmanga</b>!
			</p>
			<p className="login-required-modal__message">
				You can create and <i>save</i> your own avatars, <i>share</i> them with friends or <i>buy</i> the ones you like
				best!
			</p>

			<div className="login-required-modal__buttons">
				<Link className="button" to={`/sign-in?from=${window.location.pathname}`} onClick={close}>
					Login
				</Link>
				<Link className="button" to="/sign-up" onClick={close}>
					SignUp
				</Link>
			</div>
		</div>
	)
}

export default React.memo(LoginRequiredModal)
