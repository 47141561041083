import { IPublicTransaction } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import privateImageToBlob from '../../../../utilities/privateImageToBlob'

function DashboardTransactionsProducts({ transaction }: { transaction: IPublicTransaction }) {
	const [products, setProducts] = React.useState<Array<string>>([])

	React.useEffect(() => {
		if (transaction.status === 'completed' && transaction.purchases && transaction.purchases.length > 0) {
			const promises: Array<Promise<string>> = []

			transaction.purchases.slice(0, 3).forEach(purchase => {
				promises.push(privateImageToBlob(getenv('REACT_APP_SERVER_URL') + 'v1/me/purchase/' + purchase + '/thumb'))
			})

			Promise.all(promises).then(setProducts)
		}
	}, [transaction])

	const totals = transaction.purchases?.length || 0
	const bMore = products.length && totals > 3

	return (
		<div className="dashboard__transaction__products__items">
			{products.map((image, index) => (
				<img className="dashboard__transaction__products__items__image" key={index} src={image} />
			))}

			{bMore ? <div className="dashboard__transaction__products__items__more">+{totals - 3}</div> : null}
		</div>
	)
}

export default React.memo(DashboardTransactionsProducts)
