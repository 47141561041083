import * as React from 'react'

interface IBaseAdsProps extends React.HTMLAttributes<HTMLElement> {
	style?: React.StyleHTMLAttributes<HTMLElement>
	client: string
	height?: number
	slot: string
	format?: string
	responsive?: string
}

function BaseAds({ client, slot, format, responsive, className, height, style, ...others }: IBaseAdsProps) {
	const [size, setSize] = React.useState<{ width: string; height: string } | null>(null)
	const ref = React.createRef<HTMLDivElement>()
	const [loaded, setLoaded] = React.useState(false)

	if (slot.length === 0) {
		console.warn('SLOT NOT DEFINED')
	}

	React.useEffect(() => {
		setTimeout(() => {
			if (size === null && ref && ref.current) {
				const bounding = ref!.current!.getBoundingClientRect()
				const h = height || bounding.height
				setSize({ width: bounding.width + 'px', height: h ? h + 'px' : 'auto' })
			}
		}, 100)
	}, [ref, size])

	React.useEffect(() => {
		if (size && !loaded) {
			function appendAd() {
				//@ts-ignore
				if (!window.adsbygoogle) {
					setTimeout(appendAd, 500)
				} else {
					//@ts-ignore
					;(adsbygoogle = window.adsbygoogle || []).push({})
					setLoaded(true)
					// window.setTimeout(() => {
					// 	if (ref && ref.current) {
					// 		ref.current.style.height = size?.height + 'px'
					// 		// @ts-ignore
					// 		ref.current.children[0]!.style.height = size?.height + 'px'
					// 	}
					// }, 300)
				}
			}

			window.setTimeout(appendAd, 500)
		}
	}, [size, loaded])

	return (
		<div
			className="ads-container"
			ref={ref}
			style={size ? { width: size.width + 'px', height: size.height + 'px', overflow: 'hidden' } : {}}
		>
			{size ? (
				<div style={{ width: size.width + 'px', height: size.height + 'px', overflow: 'hidden' }}>
					<ins
						className={`${className} adsbygoogle`}
						data-ad-client={client}
						data-ad-slot={slot}
						data-ad-format={format || 'fluid'}
						data-full-width-responsive={responsive || 'true'}
						style={{ display: 'inline-block', ...style, width: size.width, height: size.height }}
						{...others}
					/>
				</div>
			) : (
				<div className={`${className}`}></div>
			)}
		</div>
	)
}

const pub = 'ca-pub-5030497361336964'

export { pub }

export default BaseAds
