import { ISharedAuthState } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiMagicLine, RiShoppingCartLine, RiUserLine } from 'react-icons/ri'
import { GoGlobe } from 'react-icons/go'
import { RiHistoryFill } from 'react-icons/ri'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { IRootState } from '../types/store'
import UserProfileImage from './components/UserProfileImage'
import CartController from './cart/CartController'

interface INavProps {
	auth: ISharedAuthState
	bMobile: boolean
}

function Nav(props: INavProps) {
	const [cartItems, setCartItems] = React.useState(0)

	React.useEffect(() => {
		const b = CartController.onChange(() => {
			setCartItems(CartController.getItems().length)
		})

		return () => {
			CartController.unbind(b)
		}
	}, [])

	return (
		<nav className="app__nav">
			{props.bMobile ? (
				!props.auth.check ? (
					<NavLink className="app__nav__link" to="/sign-in">
						<RiUserLine />
						<div>Login</div>
					</NavLink>
				) : (
					<React.Fragment>
						<NavLink className="app__nav__link app__nav__link__profile" to="/profile">
							<UserProfileImage className="app__nav__profile-image" user={props.auth.user!} />
							<div>{props.auth.user!.username ? '@' + props.auth.user!.username : null}</div>
						</NavLink>
						<NavLink className="app__nav__link app__nav__link--cart" to="/cart">
							<RiShoppingCartLine />
							<div>Cart</div>
							<small style={{ alignSelf: 'center' }}>({cartItems})</small>
						</NavLink>
					</React.Fragment>
				)
			) : null}

			{/* <NavLink className="app__nav__link" to="/create">
				<RiMagicLine />
				<span>Create</span>
			</NavLink> */}
			<NavLink className="app__nav__link" to="/gallery">
				<GoGlobe />
				<div>FYM World</div>
			</NavLink>

			<a className="app__nav__link" href="https://legacy.faceyourmanga.com" rel="noreferrer" target='_blank'>
				<RiHistoryFill />
				<div>FYM in History</div>
			</a>

			{!props.bMobile ? (
				!props.auth.check ? (
					<NavLink className="app__nav__link" to="/sign-in">
						<RiUserLine />
						<div>Login</div>
					</NavLink>
				) : (
					<React.Fragment>
						<NavLink className="app__nav__link app__nav__link--cart" to="/cart">
							<RiShoppingCartLine />
							<div>Cart</div>
							<small style={{ alignSelf: 'center' }}>({cartItems})</small>
						</NavLink>

						<NavLink className="app__nav__link app__nav__link__profile" to="/profile">
							<UserProfileImage className="app__nav__profile-image" user={props.auth.user!} />
							<div>{props.auth.user!.username ? '@' + props.auth.user!.username : null}</div>
						</NavLink>
					</React.Fragment>
				)
			) : null}
		</nav>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		auth: state.auth,
		bMobile: state.device.bMobile,
	}))(Nav)
)
