import { AvatarId, Currency, Price } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { RiShoppingCart2Fill } from 'react-icons/ri'
import { connect } from 'react-redux'
import { openModal } from '../../redux/app/actions'
import { IWithUser } from '../../types/interfaces'
import { IRootState } from '../../types/store'
import dispatchMessage from '../messages/dispatchMessage'
import { sortPrices } from '../utilities/pricelist'
import CartController from './CartController'

interface IAddToCartButton extends IWithUser {
	avatarId: AvatarId
	prices: Record<string, Price>
	currency: Currency
	loginRequiredModal: () => void
}

function AddToCartButton(props: IAddToCartButton) {
	const [open, setOpen] = React.useState(false)
	const ref = React.createRef<HTMLDivElement>()

	function addToCart(resolution: string) {
		if (props.user) {
			const added = CartController.add({
				avatar: props.avatarId,
				resolution: resolution,
			})

			dispatchMessage(added ? 'Avatar has been added to cart' : 'Avatar is already in the cart')
		} else {
			props.loginRequiredModal()
		}
		setOpen(false)
	}

	React.useEffect(() => {
		if (ref.current) {
			function hide(e: MouseEvent) {
				if (open && !ref.current?.contains(e.target as HTMLElement)) {
					setOpen(false)
				}
			}

			document.addEventListener('click', hide, { passive: true })

			return () => {
				document.removeEventListener('click', hide)
			}
		}
	}, [ref])

	return (
		<div className="addToCartButton" ref={ref}>
			<button className={`addToCartButton__button ${'addToCartButton__button--' + (open ? 'open' : 'close')}`} onClick={() => setOpen(!open)}>
				<div className="addToCartButton__button__icon">
					<RiShoppingCart2Fill />
				</div>
				<div className="addToCartButton__button__label">Add to cart</div>
			</button>
			{open && (
				<div className="addToCartButton__prices">
					{sortPrices(props.prices).map(price => (
						<div
							key={price.resolution}
							className="addToCartButton__prices__price"
							onClick={() => addToCart(price.resolution)}
						>
							{price.name.toUpperCase()} ({price.resolution}) - {price.price.toFixed(2)} {props.currency}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default React.memo(
	connect(
		(state: IRootState) => ({
			user: state.auth.user,
			prices: state.payinfo.prices,
			currency: state.payinfo.currency,
		}),
		dispatch => ({
			loginRequiredModal: () => dispatch(openModal('login-required')),
		})
	)(AddToCartButton)
)
