import * as React from 'react'
import Appear from '../Appear'

export interface ITabItemProps {
	title: string
	slug: string
	disabled?: boolean
	children: JSX.Element | Array<JSX.Element> | string
	icon?: JSX.Element
}

function TabItem(props: ITabItemProps) {
	return <div className="tab-item">{props.children}</div>
}

export default React.memo(TabItem)
