import React from 'react'

export function useClick(
	actionSimpleClick: (e?: React.MouseEvent) => void,
	actionDoubleClick: (e?: React.MouseEvent) => void,
	delay = 250
) {
	const [click, setClick] = React.useState(0)

	React.useEffect(() => {
		const timer = setTimeout(() => {
			// simple click
			if (click === 1) actionSimpleClick()
			setClick(0)
		}, delay)

		// the duration between this click and the previous one
		// is less than the value of delay = double-click
		if (click === 2) actionDoubleClick()

		return () => clearTimeout(timer)
	}, [click])

	return () => setClick(prev => prev + 1)
}
