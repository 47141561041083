import { Currency, Price } from '@faceyourmanga/fym-lib'
import { Store } from 'redux'

export const BIND_PAYINFO_DATA = 'BIND_PAYINFO_DATA'

export const bindPayinfoData = (data: IPayinfoState) => ({ type: BIND_PAYINFO_DATA, data })

export type PayinfoActionsType = {
	type: typeof BIND_PAYINFO_DATA
	data: IPayinfoState
}

export interface IPayinfoState {
	currency: Currency
	nationalities: Array<string>
	vats: { [key: string /*Nationalities*/]: number }
	prices: Record<string, Price>
}

const defaultpayinfoState: IPayinfoState = {
	currency: 'USD',
	nationalities: [],
	vats: {},
	prices: {},
}

const payinfoReducer = (state = defaultpayinfoState, action: PayinfoActionsType): IPayinfoState => {
	switch (action.type) {
		case BIND_PAYINFO_DATA:
			return { ...action.data }
	}

	return state
}

export { payinfoReducer }

export async function bindPayinfo(store: Store) {
	const endopoint = getenv('REACT_APP_SERVER_URL') + 'v1/payinfo'

	try {
		const request = await fetch(endopoint)
		const response = await request.json()

		const data = {
			currency: response.currency,
			nationalities: Object.keys(response.vats),
			vats: response.vats,
			prices: response.prices,
		}

		store.dispatch(bindPayinfoData(data))
	} catch (e) {
		console.error(e)
	}
}
