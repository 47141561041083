import { Auth, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'

const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

interface IDashboardChangePasswordState {
	old_password: string
	new_password: string
	repeat_password: string
}

function DashboardChangePassword({ email }: { email: string }) {
	const [sended, setSended] = React.useState<boolean>(false)
	async function sendEmail() {
		try {
			await Auth.sendPasswordResetLink(email)
			setSended(true)
		} catch (e) {}
	}

	return (
		<div className="dashboard__change-password">
			{sended ? (
				<React.Fragment>Email sent</React.Fragment>
			) : (
				<React.Fragment>
					<PromiseButton onClick={sendEmail} className="button button--gray button--rounded button--padded">
						Change password
					</PromiseButton>
					<div>
						<small>Send email to reset password</small>
					</div>
				</React.Fragment>
			)}
		</div>
	)
}

export default React.memo(DashboardChangePassword)
