import { Auth, BadRequest, Forbidden, Loked, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiAlertFill } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { IWithAuth } from '../../types/interfaces'
import { IRootState } from '../../types/store'
import { IValidationMessage } from '../../types/validation'
import Input from '../components/form/Input'
import dispatchMessage from '../messages/dispatchMessage'
import AuthBanner from './AuthBanner'
import AuthProviders from './AuthProviders'
import ValidationMessages from './ValidationMessages'

interface IAuthEmailSend {
	needSend: boolean
	sended: boolean
}

function SignIn(props: IWithAuth & { bMobile: boolean }) {
	const [logged, setLogged] = React.useState<boolean>(props.auth.check)

	const [state, setState] = React.useState<{ email: string; password: string }>({
		email: '',
		password: '',
	})

	const [attempts, setAttempts] = React.useState(0)

	const [confirm, setConfirm] = React.useState<IAuthEmailSend>({
		needSend: false,
		sended: false,
	})

	const [resetPassword, setResetPassword] = React.useState<IAuthEmailSend>({
		needSend: false,
		sended: false,
	})

	const [errors, setErrors] = React.useState<Array<IValidationMessage>>([])

	////////////

	React.useEffect(() => {
		if (props.auth.check) {
			dispatchMessage(
				'You are already logged in, log out of your profile page if you want to log in with another account'
			)
		}
	}, [])

	////////////

	async function sendConfirmEmail() {
		if (confirm.needSend) {
			try {
				await Auth.sendConfirmEmail(state.email)
				setConfirm({ needSend: true, sended: true })
			} catch (e) {
				setErrors([{ message: 'Send email to confirm account.' }])
			}
		}
	}

	async function sendResetEmail() {
		try {
			await Auth.sendPasswordResetLink(state.email)
			setResetPassword({ needSend: true, sended: true })
		} catch (e) {
			setResetPassword({ needSend: false, sended: false })
			setErrors([{ message: 'Send email to recover password failed' }])
			// if (e instanceof NotFound) {
			// }
		}
	}

	////////////

	function signin() {
		return new Promise<void>(resolve => {
			setAttempts(attempts + 1)
			setResetPassword({ ...resetPassword, needSend: false })
			setConfirm({ ...confirm, needSend: false })
			setErrors([])

			setTimeout(async () => {
				try {
					await Auth.signin(state.email, state.password)
					dispatchMessage('Login is successful')
					setLogged(true)
					resolve()
				} catch (e) {
					if (e instanceof Forbidden || e instanceof BadRequest) {
						setErrors([{ message: 'Invalid credentials' }])

						if (attempts >= 2) {
							setResetPassword({ needSend: true, sended: false })
						}
					} else if (e instanceof Loked) {
						// attesa validazione email
						// dare la possibilita' di chiamare sendConfirmEmail(email)
						setConfirm({ needSend: true, sended: false })
					} else {
						setErrors([{ message: 'Unexpected error, contact by email' }])
					}
					resolve()
				}
			})
		})
	}

	///////

	if (logged) {
		const sp = new URLSearchParams(window.location.search)
		const redirect = sp.has('from') ? sp.get('from')! : '/'

		return <Redirect to={redirect} />
	}

	const disableBtn = state.email.length === 0 || state.password.length < 4

	return (
		<section className="app__page signin">
			<section className="app__content">
				<div className="auth-container">
					<h1 className="signin__title">Login</h1>

					<div className="signin__container">
						<div className="signin__credentials">
							<Input
								label="Email"
								name="email"
								type="email"
								value={state.email}
								onChange={email => setState({ ...state, email })}
							/>

							<Input
								label="Password"
								name="password"
								type="password"
								value={state.password}
								onChange={password => setState({ ...state, password })}
							/>

							<PromiseButton
								className={`button button--green ${disableBtn ? 'button--disabled' : ''}`}
								onClick={signin}
							>
								Login
							</PromiseButton>
						</div>

						<ValidationMessages errors={errors} />

						{confirm.needSend && (
							<SignInWarning
								title="Confirmation required"
								message="this account has not been confirmed, check your mailbox or"
								action={sendConfirmEmail}
								actionLabel="Resend confirm email"
							/>
						)}
						{resetPassword.needSend && (
							<SignInWarning
								title="Forgot password?"
								message={`You want to send email to <b>${state.email}</b>?`}
								action={sendResetEmail}
								actionLabel="Yes"
							/>
						)}

						<AuthProviders />

						<div className="signin__register">
							<div>
								Don’t have an account?
								<Link to="/sign-up">Signup for free </Link>
							</div>
						</div>
					</div>
				</div>
				{!props.bMobile && <AuthBanner />}
			</section>
		</section>
	)
}

//////////

const SignInWarning = ({
	title,
	message,
	action,
	actionLabel,
}: {
	title: string
	message: string
	action: (e: React.MouseEvent) => Promise<any>
	actionLabel: string
}) => (
	<div className="signin__warning">
		<div className="signin__warning__title">
			<RiAlertFill /> {title}
		</div>
		<div className="signin__warning__message" dangerouslySetInnerHTML={{ __html: message }}></div>
		<PromiseButton className="button button--small" onClick={action}>
			{actionLabel}
		</PromiseButton>
	</div>
)

//////////

export default React.memo(
	connect((state: IRootState) => ({
		auth: state.auth,
		bMobile: state.device.bMobile,
	}))(SignIn)
)
