import React from 'react'
import { NavLink } from 'react-router-dom'
import Appear from '../../components/Appear'

interface IHomeCommunityProps {
	bMobile: boolean
}

function HomeCommunity(props: IHomeCommunityProps) {
	return (
		<section className="app__content">
			<div className="home-community">
				<div className="home-community__image">
					<img src="/assets/images/home/faceyourmanga-join-community.png" alt="Join community" />
				</div>

				<div className="home-community__message">
					<h1>Join the largest avatar community ever.</h1>
					<p>
						Become a member, sign up and join Faceyourmanga for free to create, edit, collect and share your stunning
						Avatars anytime, everywhere.
					</p>
				</div>
			</div>
		</section>
	)
}

export default React.memo(HomeCommunity)
