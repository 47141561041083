import { RESET_DEVICE_INFORMATION } from './actions'
import { IDeviceState } from '../../types/store'
import { DeviceActionsType } from './actions'

import { defaultDeviceState, GetDeviceInfo } from './defaultDeviceState'

const deviceReducer = (state = defaultDeviceState, action: DeviceActionsType): IDeviceState => {
	switch (action.type) {
		case RESET_DEVICE_INFORMATION:
			return {
				...state,
				...GetDeviceInfo(),
			}
	}

	return state
}

export default deviceReducer
