import * as React from 'react'
import { RiCloseLine, RiSearchLine } from 'react-icons/ri'
import { IInputProps } from './Input'

interface IInputSearchProps extends IInputProps {
	debounce?: number
}

function InputSearch(props: IInputSearchProps) {
	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		props.onChange(e.target.value)
	}

	return (
		<div className={`input input-search ${props.error ? 'input--error' : ''}`}>
			<div className="input-search__icon">
				{!props.value || props.value.length === 0 ? (
					<RiSearchLine />
				) : (
					<RiCloseLine onClick={() => props.onChange('')} />
				)}
			</div>

			<input name={props.name} className="input__input" type="text" value={props.value} onChange={onChange} />

			{props.label && (
				<span className={`input__label ${props.value && props.value.length ? 'input__label--minimized' : ''}`}>
					{props.label}
				</span>
			)}
		</div>
	)
}

export default React.memo(InputSearch)
