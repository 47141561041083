import { IPublicTransaction } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { dateToHuman } from '../../../../utilities/dateToHuman'
import DashboardTransactionProducts from './DashboardTransactionProducts'

import DashboardTransactionPurchase from './DashboardTransactionPurchase'
import DashboardTransactionDownload from './DashboardTransactionDownload'
import { Auth } from '@faceyourmanga/fym-shared'
import { createDownload } from '../../../../utilities/createDownload'

function DashboardTransactions(transaction: IPublicTransaction) {
	const [open, setOpen] = React.useState(false)

	function _open() {
		if (transaction.status === 'completed') setOpen(!open)
	}

	const bWithInvoice = !!transaction.info

	function downladInvoice() {
		fetch(getenv('REACT_APP_SERVER_URL') + 'v1/transaction/' + transaction.id + '/invoice', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
			},
		}).then(response => {
			response.blob().then(blob => {
				createDownload(`FACEYOURMANGA_invoice-${transaction.id}.pdf`, blob, 'application/zip')
			})
		})
	}

	return (
		<div className={`dashboard__transaction dashboard__transaction--${open ? 'open' : 'close'}`} key={transaction.id}>
			<div className="dashboard__transaction__row">
				<div className="dashboard__transaction__quantity">{transaction.purchases?.length || 0}</div>
				<div className="dashboard__transaction__products">
					<DashboardTransactionProducts transaction={transaction} />
				</div>
				<div className={`dashboard__transaction__date`}>{dateToHuman(transaction.created_at)}</div>
				<div className={`dashboard__transaction__total`}>
					{transaction.status === 'completed' ? transaction.totalPrice?.toFixed(2) + ' ' + transaction.currency : '-'}
				</div>
				<div>
					<div className={`dashboard__transaction__status dashboard__transaction__status--${transaction.status}`}></div>
				</div>
				{/* <div className={`dashboard__transaction__gateway`}>{transaction.gateway}</div> */}

				{transaction.status === 'completed' ? (
					<div onClick={_open}>{open ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}</div>
				) : (
					<div></div>
				)}
			</div>
			{open && transaction.status === 'completed' && (
				<div className="dashboard__transaction__purchases">
					<div className="dashboard__transaction__purchases__meta">
						<div className="dashboard__transaction__purchases__meta__left">
							<div>
								<b>PURCHASE INFO</b>
							</div>
							<div>
								<i>Method of payment:</i> {transaction.gateway.toUpperCase()}
							</div>
							<div>
								<i>Transaction ID:</i> {transaction.id}
							</div>
						</div>
						<div className="dashboard__transaction__purchases__meta__right">
							{transaction.status === 'completed' && transaction.purchases && transaction.purchases.length && (
								<React.Fragment>
									<DashboardTransactionDownload transaction={transaction} />
									{bWithInvoice && (
										<button className="button button--small button--secondary" onClick={downladInvoice}>
											DOWNLOAD INVOICE
										</button>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
					{(transaction.purchases || []).map(purchaseId => (
						<DashboardTransactionPurchase purchaseId={purchaseId} />
					))}
				</div>
			)}
		</div>
	)
}

export default React.memo(DashboardTransactions)
