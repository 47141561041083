import { Hashtag, HashtagStatusPaginated } from '@faceyourmanga/fym-lib'
import { Api, useDebounce } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiAddLine, RiCloseLine } from 'react-icons/ri'
import Input from './Input'

const HASHTAG_REGEX = /^[a-z0-9\-_]{2,30}$/

interface IInputHashtagProps {
	value: Array<Hashtag>
	onChange: (hashtags: Array<Hashtag>) => void
}

function InputHashtags(props: IInputHashtagProps) {
	const [inputValue, setInputValue] = React.useState<string>('')
	const [bValidInputHashtag, setValidInputHashtag] = React.useState<boolean>(false)
	const [autocomplete, setAutocomplete] = React.useState<Array<Hashtag>>([])

	function addHashtag() {
		if (inputValue.length && inputValue.match(HASHTAG_REGEX) !== null && !props.value.includes(inputValue)) {
			setAutocomplete([])
			props.onChange([...props.value, inputValue])
			setInputValue('')
		}
	}

	function addAutocompleteHashtag(hashtag: Hashtag) {
		setAutocomplete([])
		props.onChange([...props.value, hashtag])
		setInputValue('')
	}

	function deleteHashtag(hashtag: string) {
		if (props.value.includes(hashtag)) {
			const hashtags = [...props.value]
			hashtags.splice(hashtags.indexOf(hashtag), 1)
			props.onChange(hashtags)
		}
	}

	React.useEffect(() => {
		setValidInputHashtag(inputValue.length === 0 || inputValue.match(HASHTAG_REGEX) !== null)
	}, [inputValue])

	async function searchHashtag(hashtag: string) {
		if (hashtag.length > 0 && hashtag.match(HASHTAG_REGEX) !== null) {
			try {
				const results = await Api.get<HashtagStatusPaginated>(`v1/hashtags?search=${hashtag}&size=10`)
				setAutocomplete(results.embedded.map(result => result.name).filter(hashtag => !props.value.includes(hashtag)))
			} catch (e) {}
		} else {
			setAutocomplete([])
		}
	}

	const debounceSearchHashtag = useDebounce<string>(inputValue => {
		searchHashtag(inputValue)
	}, 100)

	React.useEffect(() => {
		debounceSearchHashtag(inputValue)
	}, [inputValue])

	return (
		<div className="input-hashtag">
			<div className="input-hashtag__input">
				<Input
					error={!bValidInputHashtag}
					type="text"
					value={inputValue}
					label="hashtag..."
					onChange={inputValue => setInputValue(inputValue.toLowerCase())}
					onEnter={addHashtag}
					className="input-hashtag__input__input"
				/>
				<div className="input-hashtag__input__add" onClick={addHashtag}>
					<RiAddLine className={bValidInputHashtag ? '' : 'icon--disabled'} />
				</div>
			</div>

			<div className="input-hashtag__message">
				Choose the right hashtag releated your avatar to let other user easly find it.
			</div>

			<div
				className={
					'input-hashtag__autocomplete input-hashtag__autocomplete--' + (autocomplete.length > 0 ? 'open' : 'close')
				}
			>
				{autocomplete.map(hastag => (
					<div
						className="input-hashtag__autocomplete__item"
						key={hastag}
						onClick={() => addAutocompleteHashtag(hastag)}
					>
						#{hastag}
					</div>
				))}
			</div>

			{props.value.length > 0 && (
				<div className="input-hashtag__hastags">
					{props.value.map(hashtag => (
						<div key={hashtag} className="input-hashtag__hastags__item">
							<span className="input-hashtag__hastags__item__name hashtag">
								<span>#</span>
								{hashtag}
								<RiCloseLine className="input-hashtag__hastags__item__remove" onClick={() => deleteHashtag(hashtag)} />
							</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default React.memo(InputHashtags)
