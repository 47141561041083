import * as React from 'react'

import { ITabItemProps } from './TabItem'

interface ITabProps {
	selected?: string
	onChange?: (slug: string, index: number) => void
	children: Array<React.ReactElement<ITabItemProps>>
}

function Tab(props: ITabProps) {
	const [selected, setSelected] = React.useState(
		props.selected ? props.children.findIndex(child => child.props.slug === props.selected) : 0
	)

	React.useEffect(() => {
		setSelected(props.selected ? props.children.findIndex(child => child.props.slug === props.selected) : 0)
	}, [props.selected])

	return (
		<div className="tab">
			<div className="tab__list">
				{props.children.map((child, index) => (
					<div
						key={child.props.slug}
						className={`tab__list__item tab__list__item--${index === selected ? 'active' : 'inactive'} ${
							props.children[index].props.disabled ? 'tab__list__item--disabled' : ''
						}`}
						onClick={() => {
							props.onChange ? props.onChange(child.props.slug, index) : setSelected(index)
						}}
					>
						{child.props.icon}
						<span>{child.props.title}</span>
					</div>
				))}
			</div>

			<div className="tab__section">
				{props.children.map((child, index) => (
					<div
						className={`tab__section__item tab__section__item--${index === selected ? 'active' : 'inactive'}`}
						key={child.props.title}
					>
						{child}
					</div>
				))}
			</div>
		</div>
	)
}

export default React.memo(Tab)
