import * as React from 'react'
import * as ReactDOM from 'react-dom'

const container = document.getElementById('confirm-root') as HTMLDivElement

interface ConfirmProps {
	onResult: (data: boolean) => void
	label: string
	ok: string
	no: string
}

const Confirm: React.FunctionComponent<ConfirmProps> = ({ onResult, label, ok, no }: ConfirmProps) => {
	const confirmRef = React.createRef<HTMLDivElement>()

	React.useEffect(() => {
		container.className = 'open'
		document.addEventListener('click', confirm_focus, { passive: true })

		return () => {
			container.className = ''
			document.removeEventListener('click', confirm_focus)
		}
	}, [])

	function confirm_focus() {
		if (confirmRef.current) {
			const container = confirmRef.current as HTMLDivElement

			container.classList.remove('confirm--focus')
			setTimeout(() => {
				container.classList.add('confirm--focus')
				setTimeout(() => {
					container.classList.remove('confirm--focus')
				}, 500)
			})
		}
	}

	return (
		<div ref={confirmRef} className="confirm">
			<div>
				<div className="confirm__label" dangerouslySetInnerHTML={{ __html: label }}></div>
				<div className="confirm__confirm_buttons">
					<button
						className="button button--save button--small confirm__button confirm__button--ok"
						onClick={() => onResult(true)}
					>
						{ok}
					</button>
					<button
						className="button button--gray button--save--stroke button--small confirm__button confirm__button--no"
						onClick={() => onResult(false)}
					>
						{no}
					</button>
				</div>
			</div>
		</div>
	)
}

const Empty = () => null

function ConfirmPromise(label: string, ok = 'YES', no = 'NO'): Promise<boolean> {
	return new Promise<boolean>(resolve => {
		function handleResult(data: boolean) {
			resolve(data)
			ReactDOM.render(<Empty />, container)
		}

		ReactDOM.render(<Confirm onResult={handleResult} label={label} ok={ok} no={no} />, container)
	})
}

export default ConfirmPromise
