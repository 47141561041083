import { IPublicTransaction, transactionStatus, TransactionStatus } from '@faceyourmanga/fym-lib'
import { PrivateApi } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import DashboardPaginator from '../DashboardPaginator'

import DashboardTransaction from './DashboardTransaction'

function getMessageByStatus(status: TransactionStatus): string {
	switch (status) {
		case 'created':
			return 'created'
		case 'completed':
			return 'completed'
		case 'pending':
			return 'pending'
		case 'refunded':
			return 'refund'
	}
}

function DashboardTransactions() {
	const [transactions, setTransactions] = React.useState<Array<IPublicTransaction>>([])

	React.useEffect(() => {
		PrivateApi.get<Array<IPublicTransaction>>('v1/me/transaction', {
			order: 'desc',
			orderBy: 'date',
		}).then(transactions => {
			setTransactions(transactions.reverse())
		})
	}, [])

	return (
		<section className="dashboard__transactions">
			<div className="dashboard__transactions__legend">
				{transactionStatus.map(status => (
					<div key={status} className="dashboard__transactions__legend__item">
						<div
							className={`dashboard__transactions__legend__item__bullet dashboard__transactions__legend__item__bullet--${status}`}
						></div>
						{getMessageByStatus(status)}
					</div>
				))}
			</div>

			{transactions.length > 0 ? (
				<React.Fragment>
					<div className="dashboard__transactions__head">
						<div>Qt.</div>
						<div>Products</div>
						<div>Date</div>
						<div>Total</div>
						<div>Status</div>
						<div></div>
					</div>
					<div className="dashboard__transactions__body">
						<DashboardPaginator items={transactions} forPage={6} withPages={true}>
							{item => <DashboardTransaction key={item.id} {...item} />}
						</DashboardPaginator>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div style={{ textAlign: 'center' }}>
						<i>Empty list</i>
					</div>
				</React.Fragment>
			)}
		</section>
	)
}

export default React.memo(DashboardTransactions)
