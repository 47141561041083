import * as React from 'react'

export interface IInputProps {
	value: string
	label?: string
	onChange: (value: string) => void
	onEnter?: () => void
	error?: boolean
	errorMessage?: string
	type?: 'text' | 'password' | 'email' | 'number'
	name?: string
	className?: string
	disabled?: boolean
}

function Input(props: IInputProps) {
	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		props.onChange(e.target.value)
	}

	function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		props.onEnter && e.key === 'Enter' && props.onEnter()
	}

	return (
		<div className={`input ${props.error ? 'input--error' : ''}`}>
			<input
				disabled={props.disabled}
				name={props.name}
				className={`input__input ${props.className}`}
				type={props.type || 'text'}
				value={props.value}
				onKeyDown={onKeyDown}
				onChange={onChange}
			/>
			{props.label && (
				<span className={`input__label ${props.value && props.value.length ? 'input__label--minimized' : ''}`}>
					{props.label}
				</span>
			)}
		</div>
	)
}

export default React.memo(Input)
