import * as React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router'
import { appIsOnHomepage, closeMenu } from '../redux/app/actions'
import { IPayinfoState } from '../redux/payinfo/reducers'
import { IRootState } from '../types/store'
import ConfirmEmail from './auth/email/ConfirmEmail'
import PasswordReset from './auth/email/PasswordReset'
import GDPR from './auth/GDPR'
import OAuth from './auth/OAuth'
import SignIn from './auth/SignIn'
import SignUp from './auth/SignUp'
import CartController from './cart/CartController'
import Avatar from './pages/avatar/Avatar'
import Cart from './pages/cart/Cart'
import Create from './pages/create/Create'
import Gallery from './pages/gallery/Gallery'
import Home from './pages/home/Home'
import Payment from './pages/payment/Payment'
import PrivacyCookiePolicy from './pages/privacy-cookie-policy'
import ProfileRouter from './pages/profile/ProfileRouter'
import TermsOfUse from './pages/terms-of-use'
import Username from './pages/username/Username'

interface IRouterProps extends RouteComponentProps {
	bMenuOpen: boolean
	closeMenu: () => void
	appIsOnHomepage: (bHomepage: boolean) => void
	payinfo: IPayinfoState
}

const root = document.getElementById('root') as HTMLDivElement

function Router(props: IRouterProps) {
	React.useEffect(() => {
		props.closeMenu()
		props.appIsOnHomepage(props.location.pathname === '/')
		root.scrollTo(0, 0)
	}, [props.location.pathname])

	React.useEffect(() => {
		if (props.payinfo && props.payinfo.prices && Object.keys(props.payinfo.prices).length > 0) {
			CartController.setPricelist(props.payinfo.prices)
		}
	}, [props.payinfo])

	return (
		<Switch location={props.location}>
			<Route exact path="/" component={Home} />

			{/* editor */}
			<Route exact path="/create" component={Create} />
			<Route exact path="/update/:avatarId" component={Create} />
			{/* end editor */}

			<Route exact path="/gallery" component={Gallery} />
			<Route exact path="/avatar/:avatarId" component={Avatar} />
			<Route exact path="/terms-of-use" component={TermsOfUse} />
			<Route exact path="/privacy-cookie-policy" component={PrivacyCookiePolicy} />
			<Route exact path="/@:username" component={Username} />

			{/* private */}
			<Route path="/profile" component={ProfileRouter} />
			<Route exact path="/cart" component={Cart} />
			<Route path="/payment/:transactionId" component={Payment} />
			{/* end private */}

			{/* Auth */}
			<Route exact path="/sign-in" component={SignIn} />
			<Route exact path="/sign-up" component={SignUp} />
			<Route exact path="/confirm-email" component={ConfirmEmail} />
			<Route exact path="/password-reset" component={PasswordReset} />
			<Route exact path="/logged-in" component={OAuth} />
			<Route exact path="/gdpr" component={GDPR} />
			{/* end Auth */}
		</Switch>
	)
	// return (
	// 	<TransitionGroup>
	// 		<CSSTransition key={props.location.key} classNames="app__route" timeout={400}>
	// 			{/* <CSSTransition classNames="app__route" timeout={200}>  */}

	// 		</CSSTransition>
	// 	</TransitionGroup>
	// )
}

export default React.memo(
	withRouter(
		connect(
			(state: IRootState) => ({
				bMenuOpen: state.app.bMenuOpen,
				payinfo: state.payinfo,
			}),
			dispatch => ({
				closeMenu: () => dispatch(closeMenu()),
				appIsOnHomepage: (bHomepage: boolean) => dispatch(appIsOnHomepage(bHomepage)),
			})
		)(Router)
	)
)
