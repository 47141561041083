import * as React from 'react'
import { Link } from 'react-router-dom'

function Hashtag({ value }: { value: string }) {
	return (
		<span className="hashtag">
			<Link to={`/gallery?s=%23${value}`}>
				<span>#</span>
				{value}
			</Link>
		</span>
	)
}

export default React.memo(Hashtag)
