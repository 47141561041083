import MobileDetect from 'mobile-detect'
import { IDeviceState } from '../../types/store'

////////////////////////

function GetDeviceInfo(): IDeviceState {
	const userAgent = window.navigator.userAgent
	const mobileDetect = new MobileDetect(userAgent)
	const bSafari = (userAgent.match(/safari/gi) && !userAgent.match(/chrome/gi)) === true
	const width = window.innerWidth
	const height = window.innerHeight
	const bMobile = (!!mobileDetect.mobile() || width < 360) === true

	return {
		bSafari,
		bMobile,
		orientation: width > height ? 'landscape' : 'portrait',
		width,
		height,
	}
}

////////////////////////

const defaultDeviceState: IDeviceState = GetDeviceInfo()

////////////////////////

export { defaultDeviceState, GetDeviceInfo }
