import {
	Auth,
	authReducer,
	hostsReducer,
	bindHosts,
	PrivateApi,
	UnavailableForLegalReasons,
} from '@faceyourmanga/fym-shared'

import { combineReducers, createStore } from 'redux'
import appReducer from './app/reducers'
import bindDevice from './device/bindDevice'
import deviceReducer from './device/reducers'
import { messageReducer } from './messages/reducers'
import { payinfoReducer, bindPayinfo } from './payinfo/reducers'

const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()

const rootReducer = combineReducers({
	auth: authReducer,
	hosts: hostsReducer,
	app: appReducer,
	device: deviceReducer,
	payinfo: payinfoReducer,
	messages: messageReducer,
})

const store = createStore(rootReducer, composeEnhancers)

bindDevice(store)
bindHosts(store)
bindPayinfo(store)

Auth.updated(() => {
	store.dispatch({ type: 'AUTH_UPDATE' })
})

PrivateApi.api.httpErrorInterceptor = e => {
	if (e instanceof UnavailableForLegalReasons) {
		store.dispatch({ type: 'UNAVAILABLE_GDPR', passed: false })
	}
}

export default store
