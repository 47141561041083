import * as React from 'react'

function TermsOfUse() {
	return (
		<section className="app__page app__page--static">
			<section className="app__content" style={{paddingTop: '6rem', paddingBottom: '6rem'}}>
				<h1 id="terms-of-use">TERMS OF USE</h1>
				<h2 id="service-description">SERVICE DESCRIPTION</h2>
				<p>
					<strong>Faceyourmanga®</strong> belongs to <strong>GOOD TIMES</strong> with its registeredoffice in Rome (RM)
					ITALY: The Website and everything related to it, it&#39;s run by GOOD TIMES or other people clearly mentioned
					(time by time).
				</p>
				<p>
					<strong>Faceyourmanga.com</strong> or <strong>Faceyourmanga</strong> , is a Social Application which allows to
					create a graphic representation of yourself: Avatar Manga or Mangatar. Beside The Site allows all the
					registered Users to be in contact among them, buying items and/or services connected with the
					Faceyourmanga&#39;s world and much more.
				</p>
				<p>
					This agreement contains the terms and conditions to use the site Faceyourmanga.com or from now on
					Faceyourmanga, and the access to the products and services available on it. These terms and conditions
					regulate User&#39;s responsibility and together with other elements, it regulates the GOOD TIMES &#39;s limits
					of responsibility
				</p>
				<p>
					Please read carefully this document before using the service. Once used any part of the Site or section of it
					User accepts the conditions and has to respect them.
					<strong>If you don&#39;t agree with these terms, please DO NOT USE THE SITE.</strong>
				</p>
				<h2 id="private-property-advice">PRIVATE PROPERTY ADVICE</h2>
				<p>
					User knows that all the content used or transmitted by Faceyourmanga or text, graphic, logo, icons, images,
					audio clip, video clip, digital download, data and software, as well as the choice or the disposition of such
					content and any other material on the Site belong exclusively to GOOD TIMES. Other people&#39;s trade mark or
					logos used on this Site are of their own property, and they may be our associated firms, or sponsorized by
					GOOD TIMES.
				</p>
				<h2 id="trade-mark">Trade Mark</h2>
				<p>
					<strong>Faceyourmanga®</strong> is a registred trade mark. The trade mark and anything related to it can&#39;t
					be used or associated to any other product or service which is not directly connected to Faceyourmanga or may
					cause confusion, or bring GOOD TIMES and Faceyourmanga into discredit. Other products and Companies mentioned
					in Faceyourmanga.com may be trade mark, registered by their owners.
				</p>
				<h2 id="license-and-access">LICENSE AND ACCESS</h2>
				<p>
					Faceyourmanga allows by a limited license the access and the personal use of the whole stuff on Faceyourmanga
					and the associated services; It doesn&#39;t allow to download but in &quot;page rendering&quot; or modify any
					part of it, without a written consent from GOOD TIMES. Such license doesn&#39;t include the sale or the
					commercial use of Faceyourmanga site and of its content, the derivative use of Faceyourmanga the download or
					the duplication of account information to be of other people advantage. Faceyourmanga site or part of it
					can&#39;t be reproduced, doubled, copied, sold, or used for commercial purpose without GOOD TIMES&#39;s
					permission.
				</p>
				<p>
					It isn&#39;t possible to divide the content of the Site or use any Frame technique including brands or other
					information as images, text, avatar, page layout, videos, software belonging to GOOD TIMES.
				</p>
				<p>
					User can&#39;t create hypertextual links, hyperlink, to any of the internal pages but for the main
					www.faceyourmanga.com address or to the Site content without a written consent of GOOD TIMES.
				</p>
				<p>
					<strong>Any unauthorized use will cause an immediate suspension of the license given by GOOD TIMES.</strong>
				</p>
				<p>
					User is free to reproduce, distribute, communicate or show to the public only the Avatar manga or Mangatar
					created through Faceyourmanga Site at the following conditions:
				</p>
				<ul>
					<li>Attribution: User must attribute the work in the manner specified by the author;</li>
					<li>
						Non Commercial: User can&#39;t use the work for direct or indirect commercial purpose. For indirect
						commercial purpose it&#39;s meant also an use of the work as an instrument for criticism to teaching,
						discussion, and/or news broadcasted (TV, cinema, newspapers) with a clear commercial purpose.
					</li>
					<li>No derivative works: User can&#39;t modify or transform this work, nor use it to make another one.</li>
				</ul>
				<h2 id="third-parties-39-content">THIRD PARTIES&#39; CONTENT</h2>
				<p>
					Some information and contents of Faceyourmanga.com could be supplied by licensees or suppliers. Such contents
					are under copyright and they may be registered trade mark. User accepts to look at third parties&#39; content
					only for personal reason and only if allowed by the owners. User accepts and agrees to not have any rights to
					download, memoraize, reproduce, modify, visualize exept for as said in the last point of the previous
					paragraph, change any third parties&#39; content being on Faceyourmanga without the owner&#39;s authotization.
					User declares and guarantees to keep GOOD TIMES, as well as the licensees and suppliers related out of any
					responsibilities due to others directly connected with their own account activity. GOOD TIMES has the right to
					send at any moment, at the e-mail address you provided, commercialproposals from Faceyourmanga.com, respecting
					personal data terms.
				</p>
				<h2 id="your-account">YOUR ACCOUNT</h2>
				<p>
					When using Faceyourmanga ite user must keep complete privacy on the access data (username and password) and to
					limit the access one&#39;s own computer; accepts to undertake the whole responsibility of all the activities
					made using one&#39;s own account.
				</p>
				<h2 id="admission">ADMISSION</h2>
				<p>
					GOOD TIMES allows the access to Faceyourmanga only to over eighteens. Under 18, but over 14 it&#39;s possible
					to use Faceyourmanga under parental care, or any other person accepting the Using procedures.
				</p>
				<h2 id="revision-comments-communication-and-other-contents">
					REVISION, COMMENTS, COMMUNICATION AND OTHER CONTENTS
				</h2>
				<p>
					User accepts to be responsible of all the material sent, created or published on Faceyourmanga.com, included
					without limits, any content of &quot;virtual form&quot; or other elements developed or loaded, Avatar&#39;s
					names, outlines, revision, comments, suggestions, chat, messages, board messages or other communication (any
					type of&quot; insert&quot;) and that the user himself and not GOOD TIMES , shoulder the responsibility of any
					content put, including trade marks and copyright. Loading or inserting material on Faceyourmanga.com, as for
					example on Profile,Forum, Messages, chat or other publishing text, review, comments, images, audio-files, or
					other audio-video content (User&#39;s content) User declares and guarantees that:
				</p>
				<ol>
					<li>He is the owner or assumes to have all rights on the inserted content, respecting using procedures;</li>
					<li>
						User&#39;s content respects such terms. User can&#39;t use any false e-mail, pretending to be another person
						or being.
					</li>
				</ol>
				<p>
					GOOD TIMES reserves the right ( but he is not obliged)to remove, to modify, or even to reclassify the
					user&#39;s content, as well as to regulate the insertion on Faceyourmanga.com, site and he isn&#39;t
					responsible for such omitted activity. If User posts or loads some content on Faceyourmanga.com, gives to GOOD
					TIMES and to Faceyourmanga a world right free, perpetual and irrevocable non exclusive license to reproduce,
					to modify, to adapt, to publish, to translate, to create, derivative works, to distribute, to execute and
					visualize publicly the contents inserted by User or any data carrier, both known and unknown or not yet
					created. User grants to GOOD TIMES and to other licensees, the right to use the name related to this content,
					when necessary. User accepts to keep GOOD TIMES, Faceyourmanga.com,, FACEYOURMANGA, associated companies and
					Board out of any legal procedures, complaints, royalties, rewards or damages caused by material created or
					supplied by themselves.
				</p>
				<h2 id="web-site-behaviour-rules">WEB SITE BEHAVIOUR RULES</h2>
				<p>
					When User&#39;s content are posted or the Site Faceyourmanga.com, is used you agree that you are not allowed
					to:
				</p>
				<ul>
					<li>Publish illegal harmful, threatening, illicit, nasty, defamatory, morbid, obscene, racist content</li>
					<li>Use obscene language, to publish clear, real or virtual sexual images, to use offensive language;</li>
					<li>
						Persecute, to bother, to menace, to defame, to abuse or in any case you can&#39;t violate user&#39;s or
						other being&#39;s privacy
					</li>
					<li>Send messages containing expression of hate or any other material extolling terrorism or violence;</li>
					<li>Publishing anything showing cruelty against animals;</li>
					<li>Involve or pointing out under 14 children or to perceive any actions meant to make use of children;</li>
					<li>Publish adult people stuff;</li>
					<li>
						Publish material which is harmful for children, showing children making sex or that may involve infantile
						pornography;
					</li>
					<li>Change one&#39;s own age pretending to be younger or viceversa;</li>
					<li>
						Send any copyright material or Registered TM without the explicit authorizationfrom the owners or in any
						case do not violate the intellectual property or any person&#39;s moral rights;
					</li>
					<li>
						Publish &quot;Off topic messages which may interrupt the discussion, or include offensive, coarse or
						advertising not authorized language;
					</li>
					<li>
						Convince other Users to supply access data to Faceyourmanga.com, as username, password or other information;
					</li>
					<li>
						Post or publish information including third parties&#39; personal data as real name, address, e-mail, phone
						number, employer or school;
					</li>
					<li>
						Collect usernames, nicknames, e-mail or other personal data provided by User self, from purposes different
						from the personal use or what Faceyourmanga.com, meant;
					</li>
					<li>
						Spread not authorized advertisement, advertising material, spam, chain letters, pyramidal schemes, mass
						mailing, or any mass convincement;
					</li>
					<li>Publish material that may provoke photosensible epilepsy PSE among which flashing lights content;</li>
					<li>
						Not to Be a member of Faceyourmanga.com,, part of our staff or other professionist working in the sector;
					</li>
					<li>Change or interfere with Faceyourmanga.com, using script, code, bot or other;</li>
					<li>
						Load or introduce any Spyware, Adware for other programmes dangerous for Faceyourmanga.com, site Code.
					</li>
				</ul>
				<p>
					This list of prohibitions provides only some examples and it is not to be considered complete and
					comphrehensive. GOOD TIMES reserves the right, with or without reason, with or without notice to interrupt the
					access to its own account. GOOD TIMES may report actions which could be illegal or considered so to the Police
					authority. When it&#39;s required GOOD TIMES will cooperate with Police or other State authority for any
					investigation. User knows and accepts that using Faceyourmanga.com, web site incurs in offensive or unpleasant
					contents. Access and use of Faceyourmanga.com, is on one&#39;s own risk and GOOD TIMES is not responsible for
					insulting, harmful material sent by other users or third parties: User is conscious that relation and
					interactions with other website users are exclusively among themseives. GOOD TIMES is not responsible for
					damages or loss of any type caused by interactions or trade relations and it&#39;s not obliged to take part in
					any dispute caused by them.{' '}
					<strong>
						User accepts that his or any other&#39;s User&#39;s content expresses the author&#39;s opinion; GOOD TIMES
						is not responsible for it.
					</strong>
				</p>
				<h2 id="non-authorized-activities">NON AUTHORIZED ACTIVITIES</h2>
				<p>
					User accepts to keep GOOD TIMES, Faceyourmanga.com,, FACEYOURMANGA, or associated Companies, managers, staff,
					agents, licensees, trade partners, out of any responsibilities for damages, expenses, including legal fares,
					coming from any third parties&#39; requirements, for any behaviour against these general conditions,
					additional terms, copyright, trade mark right or other intellectual property right, including the moral ones
					related with the use of his own account.
				</p>
				<h2 id="sale-conditions">SALE CONDITIONS</h2>
				<p>
					All order effected through Faceyourmanga.com, will be considered as a purchase proposal of the selected item.
					The acceptance of any proposal is clearly subordinate and limited to the acceptance and respect of these
					conditions and extra applicable conditions. GOOD TIMES can accept your proposal both by e-mail confirmation or
					delivering the requested material or even through web pages communication. The products and their prices may
					change, up notice on the web page. GOOD TIMES is not responsible for printing errors. User engages himself to
					pay all the expenses and commission for subscription for the use or purchasing of products fro the
					Faceyourmanga.com, website. Taxes or other expenses are not included in the price, they will be added on the
					invoice and can be check out when you complete the purchase. Extra taxes may be applied for services supplied
					by third parties, as for example, taxes on electronic payment process. If you don&#39;t pay on time or if GOOD
					TIMES on the account of Faceyourmanga.com, can&#39;t debit the cost on your credit card, or any other method
					of payment (ex. paypal), GOOD TIMES reserves the right to suspend or interrupt the access to Faceyourmanga.com
					website. User agrees that GOOD TIMES issues invoices for costs applicable to any activity linked to
					Faceyourmanga.com website and that the same one are charged on his credit card, Paypal account or other method
					User indicated. If you question these expenses GOOD TIMES may transfer all the details of the transaction to
					others in order to solve the dispute. Once the purchase is completed, if User cancels or removes his account
					he won&#39;t receive any refund. If User has a debit with GOOD TIMES on his Faceyoumanga.com, he accepts GOOD
					TIMES may charge the sum not paid on his credit card or use any other method of payment and then issue an
					invoice. When you buy products or services on Faceyoumanga.com by credit card or electronic transaction, the
					purchases are not refundable. GOOD TIMES isn&#39;t obliged to give any refund (cash or stocks) for any items
					which is not as you expected or is removed or changed by the producers or by GOOD TIMES for any specific
					reasons. GOOD TIMES will not be responsible if the delivery of items sold on the Site will be delayed or not
					delivered for causes beyond GOOD TIMES &#39;s behaviour, or beyond someone&#39;s control, actions, or omission
					of other people or other causes as fire, tempest, flood, earthquake, accident, enemies, actions, wars,
					rebellion, telecommunication service bankruptcy, public utility service, internet interruption, insurrection,
					riots, invasions, strikes. The virtual product purchased will be available for your personal use as soon as it
					is possible. If you have any problem on the invoice or any question please contact{' '}
					<a href="mailto:payment@faceyourmanga.com">payment@faceyourmanga.com</a>.
				</p>
				<h2 id="credits-virtual-currency">CREDITS / VIRTUAL CURRENCY</h2>
				<p>
					Faceyoumanga.com may include a virtual currency- so called CREDITS – that may be acquired by means of money
					and may be used exchanging them with services and products available on Faceyoumanga.com in the way and terms
					established by GOOD TIMES. GOOD TIMES may apply tariffs for the right to use CREDITI and may distribute free
					CREDITS at its will. CREDITS are not refundable with real money or money value.GOOD TIMES has the right to
					manage, to regulate, to control, to modify, and /or eliminate such CREDITS at its better convenience. In such
					cases GOOD TIMES hasn&#39;t any responsibility toward User. User accepts to not create , modify, alter, use or
					employ CREDITS in any way different from the direct transactions made on Faceyoumanga.com. GOOD TIMES may
					agree the free exchange of some or all or none of its CREDITS with third parties or other Users, in any case
					GOOD TIMES doesn&#39;t take any responsibility for these operations. It&#39;s absolutely forbidden to resell
					or to distribute CREDITS without a written permission by GOOD TIMES.
				</p>
				<h2 id="other-activities">OTHER ACTIVITIES</h2>
				<p>
					GOOD TIMES can use Providers or Others to offer specified products, contents or services accessible through
					Faceyoumanga.com. The same one may provide associated Companies&#39; website with link. GOOD TIMES is not
					responsible for what another companies offer through their websites. Please read carefully their statements on
					Privacy and Using Terms.
				</p>
				<h2 id="warranty-exclusion-and-limits-of-responsibility">WARRANTY EXCLUSION AND LIMITS OF RESPONSIBILITY</h2>
				<p>
					GOOD TIMES doesn&#39;t provide any declaration or warranty of any type, implicit or explicit , for the
					functioning of Faceyoumanga.com but the cases differently specified. User accepts that using Faceyoumanga.com
					website is at his own risk. GOOD TIMES doesn&#39;t guarantee that Faceyoumanga.com website, information,
					content, material, products, including software or services available through Faceyoumanga.com website, their
					server, e-mail are free from virus or other damaging components.
				</p>
				<h2 id="enforceability-law-and-place-of-jurisdiction">ENFORCEABILITY LAW AND PLACE OF JURISDICTION</h2>
				<p>
					This document is regulated and interpreted according the Italia law. For any disputes about the meaning, the
					application and execution of this document the Competent Court is the Law Court in Rome.
				</p>
				<h2 id="website-policy-alteration-and-divisibility">WEBSITE POLICY; ALTERATION AND DIVISIBILITY</h2>
				<p>
					GOOD TIMES reserves the right to make changes to Faceyoumanga.com, to its policy and Using Terms as on Privacy
					terms at any moment. Continued use of Faceyoumanga.com has to be considered as an acceptation of the change
					itself. If any part of this document should be considered not valid, null, or for any reasons not applicable,
					such part won&#39;t be considered , while the rest of the terms will be regularly applied.
				</p>
				<h2 id="notice-and-procedures-to-denounce-violations-of-copyrights">
					NOTICE AND PROCEDURES TO DENOUNCE VIOLATIONS OF COPYRIGHTS
				</h2>
				<p>
					GOOD TIMES is respectful of intellectual properties of all works. If you think that your work has been copied
					against copyrights please contact us at{' '}
					<a href="mailto:copyright@faceyourmanga.com">copyright@faceyourmanga.com</a>
				</p>
			</section>
		</section>
	)
}

export default TermsOfUse
