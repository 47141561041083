import { IPublicAvatarWithUsername } from '@faceyourmanga/fym-lib/dist'
import React from 'react'
import AvatarPaginator from '../../components/AvatarPaginator'
import AvatarPreview from '../../components/AvatarPreview/AvatarPreview'

function UsernameLiked(props: { likeds: Array<IPublicAvatarWithUsername> }) {
	return props.likeds.length > 0 ? (
		<div className="username__liked">
			<h2>Favourites</h2>
			<AvatarPaginator items={props.likeds} forPage={6}>
				{avatar => (
					<AvatarPreview
						key={'rel-' + avatar.id}
						id={avatar.id}
						name={avatar.name}
						thumb={avatar.thumb}
						likes={avatar.likes}
						owner={avatar.username}
						withLike
						withOwner
					/>
				)}
			</AvatarPaginator>
		</div>
	) : null
}

export default React.memo(UsernameLiked)
