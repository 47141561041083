import { AvatarId, IPublicAvatar, IPublicUser, ThumbId, UserId } from '@faceyourmanga/fym-lib'
import { Api } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../types/store'

const DEFAULT_IMAGE = '/assets/images/profile.svg'

const userThumbs: Record<UserId, Record<AvatarId, ThumbId>> = {}

interface IUserProfileProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	user: IPublicUser
	avatarUrl: string
}

function UserProfileImage({ user, avatarUrl, className, ...other }: IUserProfileProps) {
	const [thumb, setThumb] = React.useState<string | undefined>(undefined)

	React.useEffect(() => {
		async function loadThumb(avatarId: AvatarId | undefined): Promise<string> {
			if (avatarId) {
				try {
					const avatar = await Api.get<IPublicAvatar>(`v1/avatars/${avatarId}`)
					return avatarUrl + '/' + avatar.thumb
				} catch (e) {
					return DEFAULT_IMAGE
				}
			}

			return DEFAULT_IMAGE
		}

		if (avatarUrl && avatarUrl.length > 0) {
			const key = user.thumb || ''
			if (userThumbs[user.id] && userThumbs[user.id][key]) {
				setThumb(userThumbs[user.id][key])
			} else {
				loadThumb(user.thumb).then(thumb => {
					if (userThumbs[user.id]) {
						userThumbs[user.id][key] = thumb
					} else {
						userThumbs[user.id] = { [key]: thumb }
					}
					setThumb(thumb)
				})
			}
		}
	}, [user.id, user.thumb, avatarUrl])

	return (
		<div className={(className || '') + ' user-image'}>
			<img src={thumb} alt={'FaceYourManga | ' + (user.username ? '@' + user.username : 'User')} />
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		avatarUrl: state.hosts.avatars,
	}))(UserProfileImage)
)
