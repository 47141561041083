import { IPublicTransaction, TransactionId } from '@faceyourmanga/fym-lib'
import { Authenticated, PrivateApi } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { Redirect, RouteChildrenProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import CartController from '../../cart/CartController'
import LoadingIcon from '../../components/LoadingIcon'

function Payment({ transactionId }: { transactionId: TransactionId }) {
	const [redirect, setRedirect] = React.useState<boolean>(false)
	const [transaction, setTransaction] = React.useState<IPublicTransaction | null>(null)
	// const [transaction, setTransaction] = React.useState<IPublicTransaction | null>({
	// 	country: 'Italy',
	// 	created_at: Date.now(),
	// 	currency: 'EUR',
	// 	gateway: 'paypal',
	// 	status: 'pending',
	// 	updated_at: Date.now(),
	// 	id: '',
	// 	vatPercentage: 20,
	// 	user: 'asdasdsa',
	// })

	React.useEffect(() => {
		CartController.flush()
		window.setTimeout(() => {
			CartController.flush()
		}, 300)

		let tid = 0

		async function watchTransaction() {
			try {
				const _transaction = await PrivateApi.get<IPublicTransaction>(`v1/transaction/${transactionId}`)
				setTransaction(_transaction)
				if (_transaction.status === 'pending') {
					tid = window.setTimeout(() => watchTransaction(), 3000)
				} else if (_transaction.status === 'completed') {
				}
			} catch (e) {
				console.log([e])
				CartController.flush()
				setRedirect(true)
			}
		}

		watchTransaction()

		return () => {
			clearTimeout(tid)
		}
	}, [])

	return redirect ? (
		<Redirect to="/" />
	) : transaction ? (
		<section className="payment">
			<section className="app__content">
				{/* <h1>Thanks for your purchase!</h1> */}
				<h1>Thanks for your purchase!</h1>
				<p>
					current status: <b>{transaction.status}</b>
				</p>
				<div style={{ fontSize: '1.1rem' }}>
					{transaction.status === 'pending' ? (
						<div>
							<p>
								We're verifying the transaction (usually takes max 1-8 minutes) when the status will change to{' '}
								<b>completed</b>, you'll be ready to download your avatars in hi-res, or go to the{' '}
								<Link to="/profile/purchases">your dashboard</Link>
							</p>
							<LoadingIcon />
						</div>
					) : transaction.status === 'completed' ? (
						<div>
							<p>
								Go to the <Link to="/profile/purchases">dashboard</Link> and download your purchases
							</p>
						</div>
					) : null}
				</div>
			</section>
		</section>
	) : null
}

interface IPaymentProps extends RouteChildrenProps<{ transactionId: TransactionId }> {}

export default React.memo(
	withRouter((props: IPaymentProps) => (
		<Authenticated Fallback={<Redirect to="/" />}>
			<Payment transactionId={props.match!.params.transactionId} />
		</Authenticated>
	))
)
