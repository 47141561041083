import * as React from 'react'
import { IPublicPurchase, PurchaseId } from '@faceyourmanga/fym-lib'
import { Auth, PrivateApi, PromiseButton } from '@faceyourmanga/fym-shared'
import { blobToBase64 } from '../../../../utilities/blobToBase64'
import { createDownload } from '../../../../utilities/createDownload'
import { connect } from 'react-redux'
import { IRootState } from '../../../../../types/store'
import { IPayinfoState } from '../../../../../redux/payinfo/reducers'
import privateImageToBlob from '../../../../utilities/privateImageToBlob'

function DashboardTransactionsPurchase({ purchaseId, payinfo }: { purchaseId: PurchaseId; payinfo: IPayinfoState }) {
	const [purchase, setPurchase] = React.useState<IPublicPurchase | null>(null)
	const [purchaseImageUrl, setPurchaseImageUrl] = React.useState<string | null>(null)

	React.useEffect(() => {
		PrivateApi.get<IPublicPurchase>('v1/me/purchase/' + purchaseId).then(purchase => {
			setPurchase(purchase)
		})

		privateImageToBlob(getenv('REACT_APP_SERVER_URL') + 'v1/me/purchase/' + purchaseId + '/thumb').then(
			setPurchaseImageUrl
		)
	}, [])

	async function downloadPurchase() {
		fetch(getenv('REACT_APP_SERVER_URL') + 'v1/me/purchase/' + purchaseId + '/download', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
			},
		}).then(response => {
			response.blob().then(blob => {
				createDownload('FaceYourManga-purchase.jpg', blob, 'image/jpeg')
			})
		})
	}

	return purchase && payinfo ? (
		<div className="dashboard__purchase">
			<div className="dashboard__purchase__content">
				<div className="dashboard__purchase__image">
					<img
						src={
							purchaseImageUrl
								? purchaseImageUrl
								: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC'
						}
						alt="FaceYourManga purchase"
					/>

					<div
						className="dashboard__purchase__image__size"
						style={{
							background: purchase.resolution in payinfo.prices ? payinfo.prices[purchase.resolution].color : '#ddd',
						}}
					>
						{purchase.resolution in payinfo.prices ? payinfo.prices[purchase.resolution].name : purchase.resolution}
					</div>
				</div>

				<b className="dashboard__purchase__resolution">{purchase.resolution}</b>
				<PromiseButton className="button button--secondary--stroke button--small " onClick={downloadPurchase}>
					Download
				</PromiseButton>
			</div>
		</div>
	) : null
}

export default React.memo(
	connect((state: IRootState) => ({
		payinfo: state.payinfo,
	}))(DashboardTransactionsPurchase)
)
