import * as React from 'react'
import { RiLockFill } from 'react-icons/ri'
import { IValidationMessage } from '../../types/validation'

interface IValidationMessagesProps {
	errors: Array<IValidationMessage>
}

function ValidationMessages(props: IValidationMessagesProps) {
	if (!props.errors || props.errors.length <= 0) {
		return null
	}

	return (
		<div className="validation-messages">
			<div className="validation-messages__label">
				<RiLockFill />
				The request was not successful
			</div>

			<ul className="validation-messages__list">
				{props.errors.map((error, index) => (
					<li className="validation-messages__list__item" key={index}>
						{error.dataPath && error.dataPath === '.password' ? (
							<React.Fragment>
								The password must be at least 8 characters, including characters and numbers
							</React.Fragment>
						) : (
							<React.Fragment>
								{error.dataPath && <b style={{ marginRight: '0.5rem' }}>{error.dataPath.split('.').pop()}:</b>}
								{error.message}
							</React.Fragment>
						)}
					</li>
				))}
			</ul>
		</div>
	)
}

export default ValidationMessages
