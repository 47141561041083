import { TOpenModal } from '../../types/interfaces'
/*
 * action types
 */

export const OPEN_MENU = 'OPEN_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const APP_IS_ON_HOMEPAGE = 'APP_IS_ON_HOMEPAGE'
export const OPEN_MODAL = 'OPEN_MODAL'
/*
 * action creators
 */

export const openMenu = () => ({ type: OPEN_MENU })

export const closeMenu = () => ({ type: CLOSE_MENU })

export const toggleMenu = () => ({ type: TOGGLE_MENU })

export const openModal = (modal: TOpenModal | undefined, data?: any) => ({ type: OPEN_MODAL, modal, data })

export const appIsOnHomepage = (bHomepage: Boolean) => ({ type: APP_IS_ON_HOMEPAGE, bHomepage })

export type AppActionsType =
	| {
			type: typeof OPEN_MENU
	  }
	| {
			type: typeof CLOSE_MENU
	  }
	| {
			type: typeof TOGGLE_MENU
	  }
	| {
			type: typeof APP_IS_ON_HOMEPAGE
			bHomepage: boolean
	  }
	| {
			type: typeof OPEN_MODAL
			modal: TOpenModal
			data: any
	  }
