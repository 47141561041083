import { Nationalities } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'
import { CountryCodes } from 'validate-vat-ts'

function SelectEuropeanNationality(props: {
	value?: Nationalities
	onChange: (value: Nationalities) => void
	countries: string[]
}) {
	function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const n = e.target.value

		if (isValid(n)) {
			props.onChange(n as Nationalities)
		}
	}

	function isValid(nationality?: string) {
		return (
			typeof nationality !== 'undefined' &&
			nationality.length > 0 &&
			props.countries.includes(nationality as Nationalities)
		)
	}

	return (
		<select
			className={`select select--${isValid(props.value) ? 'valid' : 'invalid'}`}
			value={props.value}
			name="nationality"
			onChange={onChange}
		>
			<option disabled>Nationality</option>
			{Object.keys(CountryCodes).map(nation => (
				<option key={nation} value={nation}>
					{nation}
				</option>
			))}
		</select>
	)
}

export default React.memo(SelectEuropeanNationality)
