import { AvatarId, IUser } from '@faceyourmanga/fym-lib'
import React from 'react'
import { RiEditFill } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../../../types/store'
import { useClick } from '../../hooks/useClick'
import AvatarLike, { toggleAvatarLike } from './AvatarPreviewLikes'

interface IAvatarPreviewProps {
	user: IUser | null
	id: AvatarId
	thumb: string
	likes?: number
	name?: string
	owner?: string
	avatarHost: string
	size?: string
	withOwner?: boolean
	withLike?: boolean
	withName?: boolean
	cliccable?: boolean
	isPrivate?: boolean
	color?: string
}

function AvatarPreview(props: IAvatarPreviewProps) {
	const [redirect, setRedirect] = React.useState(false)

	function onClick() {
		if (props.cliccable !== false) {
			setRedirect(true)
		}
	}
	function onDoubleClick() {
		if (props.withLike && props.user) {
			toggleAvatarLike(props.id, props.user)
		}
	}

	const handleClick = useClick(onClick, onDoubleClick, 200)

	const ref = React.createRef<HTMLAnchorElement>()

	React.useEffect(() => {
		if (ref && ref.current) {
			ref.current.click()
		}
	}, [ref])

	return (
		<div className="avatar-preview" style={{ width: props.size, height: props.size }}>
			{redirect && (
				<Link
					style={{ display: 'none' }}
					to={props.isPrivate ? `/profile/avatar/${props.id}` : `/avatar/${props.id}`}
					innerRef={ref}
				/>
			)}
			<div
				className={`avatar-preview__image ${
					props.withOwner || props.withLike || props.withName ? 'avatar-preview__image--rounded' : ''
				}
				${props.cliccable === false ? '' : 'avatar-preview__image--cliccable'}`}
				onClick={handleClick}
			>
				<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC" />
				<img
					style={{ position: 'absolute' }}
					src={props.avatarHost + '/' + props.thumb}
					alt={`FaceYourManga | Avatar | ${props.name}`}
				/>
			</div>
			{(props.withOwner || props.withLike) && (
				<div className="avatar-preview__info">
					<div className="avatar-preview__likes">
						{props.withLike && <AvatarLike user={props.user} likes={props.likes} avatarId={props.id} />}
					</div>
					<div className="avatar-preview__update">
						{props.user && props.user.username === props.owner && (
							<Link to={`/profile/avatar/${props.id}`}>
								<RiEditFill />
							</Link>
						)}
					</div>
				</div>
			)}

			{props.withName !== false && <div className="avatar-preview__name">{props.name}</div>}
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		user: state.auth.user,
		avatarHost: state.hosts.avatars,
	}))(AvatarPreview)
)
