import { AvatarId, IDrawable, IPersonalAvatar } from '@faceyourmanga/fym-lib'
import { Auth, ISharedAuthState, PrivateApi, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiEyeOffLine } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../../types/store'
import AdsS from '../ads/AdsS'
import AddToCartButton from '../cart/AddToCartButton'
import Input from '../components/form/Input'
import InputHashtags from '../components/form/InputHashtags'

interface ISaveAvatarModalProps {
	avatarId?: AvatarId | null
	drawable: IDrawable | null
	auth: ISharedAuthState
	close: () => void
}

function SaveAvatarModal(props: ISaveAvatarModalProps) {
	const [avatar, setAvatar] = React.useState<Partial<IPersonalAvatar>>({
		drawable: props.drawable!,
		hashtags: [],
		private: false,
	})

	const [errors, setErrors] = React.useState<string | null>(null)
	const [createdId, setCreatedId] = React.useState<AvatarId | null>(null)

	async function save() {
		try {
			const avatarId = await PrivateApi.post<AvatarId>(`v1/me/avatars`, avatar)

			Auth.setUser({ ...props.auth.user, avatars: [...props.auth.user!.avatars, avatarId] })

			setCreatedId(avatarId)
		} catch (e) {
			setErrors('Something went wrong, please try again later')
		}
	}

	return props.auth.user ? (
		<div className="save-avatar-modal">
			<div className="save-avatar-modal__ads">
				<AdsS slot={'9961632180'} />
			</div>
			<div className="save-avatar-modal__container">
				{createdId ? (
					<React.Fragment>
						<h1 className="save-avatar-modal__title">Avatar created!</h1>
						<div className="save-avatar-modal__row save-avatar-modal__row--center">
							Add in your cart <br />
							<AddToCartButton avatarId={createdId} />
						</div>
						<div className="save-avatar-modal__row  save-avatar-modal__row--center">
							or update it in{' '}
							<Link onClick={props.close} to={`/profile/avatar/${createdId}`}>
								your dashboard
							</Link>
						</div>
						<div className="save-avatar-modal__row">
							<button className="button button--small button--padded" onClick={props.close}>
								Close
							</button>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<h1 className="save-avatar-modal__title">Save your Avatar</h1>
						<div className="save-avatar-modal__row">
							<Input label="Name" value={avatar.name || ''} onChange={name => setAvatar({ ...avatar, name })} />
						</div>
						<div className="save-avatar-modal__row">
							<InputHashtags value={avatar.hashtags!} onChange={hashtags => setAvatar({ ...avatar, hashtags })} />
						</div>
						<div className="save-avatar-modal__row">
							<input
								type="checkbox"
								id="save-avatar-modal__visibility"
								checked={avatar.private}
								onChange={e => setAvatar({ ...avatar, private: !avatar.private })}
							/>
							<label htmlFor="save-avatar-modal__visibility">
								<RiEyeOffLine /> Set as Private
							</label>
						</div>
						<div className="save-avatar-modal__row">
							<PromiseButton
								className={`button button--save button--padded button--green button--small ${
									!avatar.name || avatar.name.length < 3 ? 'button--disabled' : ''
								}`}
								onClick={save}
							>
								Save
							</PromiseButton>
						</div>
						{errors && (
							<div className="save-avatar-modal__row">
								<div className="save-avatar-modal__error">{errors}</div>
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		</div>
	) : null
}

export default React.memo(
	connect((state: IRootState) => ({
		auth: state.auth,
	}))(SaveAvatarModal)
)
