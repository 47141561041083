import { IPurchesable, IUser } from '@faceyourmanga/fym-lib'
import { Api, Authenticated, BadRequest, PromiseButton, UnprocessableEntity } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { IPayinfoState } from '../../../redux/payinfo/reducers'
import { IRootState } from '../../../types/store'
import CartController from '../../cart/CartController'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'
import BillingInfo from './BillingInfo'
import CartEmpty from './CartEmpty'
import CartItem from './CartItem'
import CartPrices from './CartPrices'
import useCart from './useCart'

interface ICartProps {
	user: IUser | null
	payinfo: IPayinfoState
	avatarUrl: string
}

function Cart(props: ICartProps) {
	const { valid, items, billingInfo, setBillingInfo, bUseBilling, setbUseBillingInfo } = useCart(props.user!)

	async function buy() {
		try {
			const redirectUrl = await Api.post<string>('v1/buy', {
				gateway: 'paypal',
				items: CartController.getItems(),
				info: bUseBilling ? billingInfo : undefined,
			})

			window.location.href = redirectUrl
		} catch (e) {
			if (e instanceof UnprocessableEntity) {
				// check items are valid to draw
			} else if (e instanceof BadRequest) {
				// check VAT info
			}

			Alert(
				<div>
					<h2>An error as accured</h2>
					<div>
						Try to reload page or send email to{' '}
						<a className="user-link" href="mailto:info@faceyourmanga.com">
							info@faceyourmanga.com
						</a>
					</div>
				</div>
			)
		}
	}

	function getSmallestResolution() {
		const resolutionToNumber = (resolution: string) =>
			resolution
				.split('x')
				.map(t => parseInt(t))
				.reduce((acc, current) => acc * current, 1)

		return Object.keys(props.payinfo.prices).sort((a, b) => resolutionToNumber(a) - resolutionToNumber(b))[0]
	}

	if (props.user === null) return <Redirect to="/" />

	const availableFreePurchase = props.user.freePurchases - items.filter(item => item.useFreePurchase).length

	return items.length === 0 ? (
		<CartEmpty />
	) : (
		<section className="app__page">
			<section className="app__content">
				<div className="cart">
					<h1 className="cart__title">Cart</h1>
					{props.user.freePurchases > 0 ? (
						<div className="cart__free-purchase">
							You have <span className="cart__free-purchase__count">{props.user.freePurchases}</span> Credit/Discount
							<div className="cart__free-purchase__small">The oﬀer is valid only for some products</div>
						</div>
					) : null}

					<div className="cartItem cart__head">
						<div className="cartItem__col1">Products</div>
						<div className="cartItem__col2">Credit / Discount</div>
						<div className="cartItem__col3">Price</div>
					</div>
					<div className="cart__body">
						{items.map((item, index) => (
							<CartItem
								key={index}
								item={item}
								avatarUrl={props.avatarUrl}
								currency={props.payinfo.currency}
								prices={props.payinfo.prices}
								availableFreePurchase={availableFreePurchase}
							/>
						))}
					</div>
				</div>

				<div>
					<div className="cart__button-invoice">
						<button
							className={` button-toggle ${bUseBilling ? '' : 'button-toggle--active'}`}
							onClick={() => setbUseBillingInfo(!bUseBilling)}
						>
							{bUseBilling ? "I DON'T NEED INVOICE" : 'I NEED INVOICE'}
						</button>
					</div>
					{bUseBilling && <BillingInfo value={billingInfo} onChange={setBillingInfo} />}
				</div>

				<div className="cart__pay">
					<CartPrices items={items as Array<IPurchesable>} valid={valid} payinfo={props.payinfo} user={props.user} />

					<div className="cart__pay__button">
						<PromiseButton disabled={!valid} className={'button button--big button--green'} onClick={buy}>
							Pay with {CartController.getGateway()}
						</PromiseButton>
					</div>
				</div>
			</section>
		</section>
	)
}

function CartWrapper(props: ICartProps) {
	return (
		<Authenticated Fallback={<Redirect to="/" />} Loading={<Loading />}>
			<Cart {...props} />
		</Authenticated>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		user: state.auth.user,
		payinfo: state.payinfo,
		avatarUrl: state.hosts.avatars,
	}))(CartWrapper)
)
