import { useSimplePaginator } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiArrowDropLeftLine, RiArrowDropRightLine } from 'react-icons/ri'

interface IDashboardPaginatorProps {
	items: Array<any>
	forPage?: number
	children: (item: any, index: number) => JSX.Element
	fill?: boolean
	fixedHeight?: boolean
	withPages?: boolean
}

function DashboardPaginator(props: IDashboardPaginatorProps) {
	const paginator = useSimplePaginator(props.items, props.forPage)
	const paginatorRef = React.createRef<HTMLDivElement>()

	if (props.fill) {
		const forPage = props.forPage || 2
		const list = paginator.list
		if (list.length < forPage) {
			for (let i = list.length; i < forPage; i++) {
				list[i] = undefined
			}
		}
	}

	React.useEffect(() => {
		if (props.fixedHeight) {
			setTimeout(() => {
				if (paginatorRef && paginatorRef.current) {
					paginatorRef.current.style.height = paginatorRef.current.offsetHeight + 'px'
				}
			}, 500)
		}
	}, [paginatorRef])

	return (
		<div className="dashboard__paginator">
			<div ref={paginatorRef} className="dashboard__paginator__list">
				{paginator.list.map(props.children)}
			</div>
			<div className="dashboard__paginator__actions">
				<div
					className={
						`dashboard__paginator__actions__arrow dashboard__paginator__actions__arrow--` +
						(paginator.bFirst ? 'disabled' : 'enabled')
					}
					onClick={paginator.prev}
				>
					<RiArrowDropLeftLine />
				</div>

				<div
					className={
						`dashboard__paginator__actions__arrow dashboard__paginator__actions__arrow--` +
						(paginator.bLast ? 'disabled' : 'enabled')
					}
					onClick={paginator.next}
				>
					<RiArrowDropRightLine />
				</div>

				{props.withPages ? (
					<select
						className="dashboard__paginator__actions__pages"
						value={paginator.currentPage}
						onChange={e => paginator.go(parseInt(e.target.value))}
					>
						{new Array(paginator.pages).fill(0).map((page, index) => (
							<option key={index} value={index + 1}>
								{index + 1}
							</option>
						))}
					</select>
				) : null}
			</div>
		</div>
	)
}

export default React.memo(DashboardPaginator)
