import React from 'react'
import { Link } from 'react-router-dom'

function CartEmpty() {
	return (
		<section className="app__page">
			<section className="app__content">
				<h1>Your Cart is empty</h1>
				<p>
					Add <Link to="/profile/avatars">your avatars</Link> to your cart or choose from{' '}
					<Link to="/gallery">FYM World</Link> the avatars you like the most and add them to your cart.
				</p>
			</section>
		</section>
	)
}
export default React.memo(CartEmpty)
