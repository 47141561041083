import { Api } from '@faceyourmanga/fym-shared'
import { IPublicAvatar, IPublicUser, Username as TUsername } from '@faceyourmanga/fym-lib'
import React from 'react'
import { RouteChildrenProps, withRouter } from 'react-router'
import Loading from '../../components/Loading'
import UserProfileImage from '../../components/UserProfileImage'
import UsernameCreateds from './UsernameCreateds'
import UsernameLiked from './UsernameLiked'
import AdsH from '../../ads/AdsH'

interface IUsernameProps extends RouteChildrenProps<{ username: string }> {}

interface IPublicUserWithUsername extends IPublicUser {
	username: TUsername
}

function Username(props: IUsernameProps) {
	const [user, setUser] = React.useState<IPublicUserWithUsername | null>(null)
	const [redirect, setRedirect] = React.useState(false)

	const [createds, setCreateds] = React.useState<Array<IPublicAvatar>>([])
	const [likeds, setLikeds] = React.useState<Array<IPublicAvatar>>([])

	React.useEffect(() => {
		Api.get<IPublicUserWithUsername>(`v1/users/@${props.match?.params.username}`)
			.then(user => {
				setUser(user)

				Api.get<Array<IPublicAvatar>>(`v1/users/${user.id}/avatars`).then(createds => {
					setCreateds(createds)

					const createdsIds = createds.map(e => e.id)
					Api.get<Array<IPublicAvatar>>(`v1/users/${user.id}/liked`).then(likeds => {
						setLikeds(likeds.filter(l => !createdsIds.includes(l.id)))
					})
				})
			})
			.catch(setRedirect)
	}, [props.match?.params.username])

	if (redirect) {
		return (
			<section className="app__page username username--not-found">
				<section className="app__content">
					<h1>
						User <b>@{props.match?.params.username}</b> not found
					</h1>
					<p>
						<span className="username__go-back" onClick={() => props.history.goBack()}>
							Go back
						</span>
					</p>
				</section>
			</section>
		)
	}

	if (!user) {
		return <Loading />
	}

	return (
		<section className=" username">
			<section className="app__content">
				<header className="username__head">
					<div className="username__head__image">
						<UserProfileImage user={user} />
					</div>
					<div className="username__head__info">
						{user.nickname && <h2 className="username__head__info__nickname">{user.nickname}</h2>}
						<div className="username__head__info__username">@{user.username}</div>
					</div>
				</header>

				<UsernameCreateds createds={createds} />

				<div className="username__bottom-ads">
					<AdsH slot={'4685594081'} />
				</div>

				<UsernameLiked likeds={likeds} />
			</section>
		</section>
	)
}

export default React.memo(withRouter(Username))
