import React from 'react'
import useAvatarCount from '../hooks/useAvatarCount'

function AuthBanner() {
	const { millions } = useAvatarCount()

	return (
		<div className="auth-banner">
			<h1 className="auth-banner__title">
				Over {millions} millions
				<br />
				of avatar created!
			</h1>
			<h3 className="auth-banner__subtitle">Join the largest avatar community ever.</h3>

			<img className="auth-banner__image" src="assets/images/slide.png" alt="" />
		</div>
	)
}

export default React.memo(AuthBanner)
