const MONTHS = [
	'Jenuary',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const two = (n: number): string => (n < 10 ? '0' + n : '' + n)

export function dateToHuman(date: number, withTime: boolean = false): string {
	const parsed = new Date(date)

	return withTime
		? `${two(parsed.getDate())} ${MONTHS[parsed.getMonth()]} ${parsed.getFullYear()} at ${two(parsed.getHours())}:${two(
				parsed.getMinutes()
		  )}`
		: `${two(parsed.getDate())} ${MONTHS[parsed.getMonth()]} ${parsed.getFullYear()}`
}
