import * as React from 'react'

function PrivacyCookiePolicy() {
	return (
		<section className="app__page app__page--static">
			<section className="app__content" style={{paddingTop: '6rem', paddingBottom: '6rem'}}>
				<h1 id="privacy">PRIVACY</h1>
				<p>
					This document aims to describe how the &quot;faceyourmanga.com&quot; (the site), belonging to GOOD
					TIMES(&quot;Good Times&quot;) works regarding user&#39;s personal data processing, consulting &quot; the
					site&quot; and of the services that through the site are available, as through events, exhibitions,
					initiatives. Within this statement describes how &#39;Good Times&#39; collect, register, and use personal data
					referring to User. At the same time describes the choices the user can do regarding as to the manner and aims
					of personal data processing from GOOD TIMES The informative report, as for art. 13 D. Lgs 196/2003- Code
					regarding the protection of personal data- is meant for those who connect to the institutional site
					www.faceyourmanga.com. The informative report takes into account also the Raccomandazione n. 2/2991 that
					European boards have adopted for the protection of personal data and to find some minimum rules to collect
					personal data on line. The report regards only the site www.faceyourmanga.com. and not any other websites user
					may consult by means of other link.
				</p>
				<h2 id="-quot-holder-quot-of-treatment">&quot;Holder&quot; of treatment</h2>
				<p>
					Following the consultation of this Site data of identified or identifiable people may be treated.The
					&quot;owner&quot; of the data treatment is GOOD TIMES, located in via di Monte Verde 2/G - 00152, Roma
					(Italia)
				</p>
				<h2 id="place-of-data-treatment">Place of Data Treatment</h2>
				<p>
					The treatments connected with web service of this site take place at the official site of the company and are
					handled only by specialized staff in charge of the treatment, or other temporary maintenance staff.
				</p>
				<p>
					No data deriving from the web service is communicated or spread to third parties, unless it is strictly
					necessary in order to sell commercialized products to users, through our site ( ex. delivery of an order). Any
					way. GOOD TIMES doesn&#39;t guarantee nor it is responsible for the use of user&#39;s personal data from other
					companies.
				</p>
				<p>
					The personal data provided by clients who will send request of access to our services on line, to our
					community, or where it is possible, meant for the purchase of our products/services and are used only
					instrumentally for these aims. GOOD TIMES will use with that aim the data put by the client, treating them by
					computer or with means suitable to guarantee the privacy as D.P.R. n. 318/99 with controlled access and
					limited to authorized subjects. All data will be preserved on software at the official site of GOOD TIMES as
					for legal regulations time.
				</p>
				<h2 id="types-of-data-treated">Types of data treated</h2>
				<p>
					Surfing elements Computing system and software procedures for the functioning of this website, acquire during
					their normal use some personal data whose transmission is implicit in the use of Internet communication
					protocol.
				</p>
				<p>
					They are information which have not been collected to be associated to identified person concerned, but for
					their nature itself could allow the identification of users through information data processing, done by
					others. In this category of data there are the address IP or the dominion computer&#39;s names used by users
					who connect to the site, URI (Uniform Resource Identifier)Address of the required resources, time of request,
					method used to make the request to the server, the size of the file had as an answer, numerical code showing
					the state of the answer given by the server ( well done, error) and other parameter connected to the operative
					system and user&#39;s computing environment. These data are used just to have anonymous statistical
					information on the use of the site and to check the correct functioning and they are erased just after the
					elaboration. The data could be used to check out the responsibility , in case of computer offence against the
					site. Except in that case, at moment the information on web contact last only seven days.
				</p>
				<p>Data voluntary provided by User to handle the purchase order.</p>
				<p>
					Any optional, explicit and voluntary enter of personal data , to the address indicated on this site, in order
					to enter to specific services, or to use e-mail, implies that TO UP acquires the sender&#39;s address or any
					other personal data which will be treated exclusively to reply the request or to supply services.
				</p>
				<p>
					The enter of data to fulfil an order from the client, implies the acquisition of sender&#39;s address, name,
					surname, e-mail address or other personal data entered on line, and they will be used just to complete the
					order and send it. In case data are not supplied it might be impossible to fulfil the order.
				</p>
				<h2 id="cookies">COOKIES</h2>
				<p>
					Cookies consist of portions of code installed in the browser that assist the Owner in providing the service
					according to the purposes described. Some of the purposes for which the Cookies are installed may also require
					the User&#39;s consent.
				</p>
				<h2 id="technical-cookies-and-cookies-serving-aggregated-statistical-purposes">
					Technical Cookies and Cookies serving aggregated statistical purposes
				</h2>
				<p>
					<strong>Activity strictly necessary for the functioning of the service</strong>
					This Application uses Cookies to save the User&#39;s session and to carry out other activities that are
					strictly necessary for the operation of the same, for example in relation to the distribution of traffic.
				</p>
				<p>
					<strong>Activity regarding the saving of preferences, optimization, and statistics</strong>
					This Application uses Cookies to save browsing preferences and to optimize the User&#39;s browsing experience.
					Among these Cookies are, for example, those to set the language and the currency or for the management of
					first party statistics employed directly by the Owner of the site.
				</p>
				<h2 id="other-types-of-cookies-or-third-party-tools-that-might-use-them">
					Other types of Cookies or third-party tools that might use them
				</h2>
				<p>
					Some of the services listed below collect statistics in aggregated form and may not require the consent of the
					User or may be managed directly by the Owner - depending on how they are described - without the help of third
					parties. If any third party operated services are listed among the tools below, these may be used to track
					Users&#39; browsing habits – in addition to the information specified herein and without the Owner&#39;s
					knowledge. Please refer to the privacy policy of the listed services for detailed information.
				</p>
				<p>
					<strong>ANALYTICS</strong>
					The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to
					keep track of User behavior. GOOGLE ANALYTICS (GOOGLE) Google Analytics is a web analysis service provided by
					Google Inc. (&quot;Google&quot;). Google utilizes the Data collected to track and examine the use of this
					Application, to prepare reports on its activities and share them with other Google services. Google may use
					the Data collected to contextualize and personalize the ads of its own advertising network. Personal Data
					collected: Cookie and Usage data. Place of processing : USA –{' '}
					<a href="https://policies.google.com/privacy?hl=en">Privacy Policy</a> -{' '}
					<a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>
				</p>
				<p>
					<strong>ADSENSE</strong>
					We publish Google Adsense interest-based advertisements on our website. These are tailored by Google to
					reflect your interests. To determine your interests, Google will track your behaviour on our website and on
					other websites across the web using the DART cookie. You can view, delete or add interest categories
					associated with your browser using Google&#39;s Ads Preference Manager, available at:
					<a href="http://www.google.com/ads/preferences/">http://www.google.com/ads/preferences/</a>. You can opt-out
					of the Adsense partner network cookie at:
					<a href="http://www.google.com/privacy/ads/">http://www.google.com/privacy/ads/</a>or using the NAI&#39;s
					(Network Advertising Initiative&#39;s) multi-cookie opt-out mechanism at:
					<a href="http://www.networkadvertising.org/managing/opt_out.asp">
						http://www.networkadvertising.org/managing/opt_out.asp
					</a>
					. However, these opt-out mechanisms use cookies, and if you clear the cookies from your browser your opt-out
					will not be maintained. To ensure that an opt-out is maintained in respect of a particular browser, you should
					use the Google browser plug-in available at:
					<a href="http://www.google.com/ads/preferences/plugin">http://www.google.com/ads/preferences/plugin</a>.
				</p>
				<p>
					<strong>INTERACTION WITH EXTERNAL SOCIAL NETWORKS AND PLATFORMS</strong>
					These services allow interaction with social networks or other external platforms directly from the
					Application&#39;s pages. The interaction and information obtained by this Application are always subject to
					the User&#39;s privacy settings for each social network. If a service enabling interaction with social
					networks is installed it may still collect traffic data for the pages where the service is installed, even
					when Users do not use it.
				</p>
				<p>
					<strong>Facebook Like button and social widgets</strong> (Facebook, Inc.) The Facebook Like button and social
					widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc. Personal
					Data collected: Cookie and Usage data. Place of processing : USA –{' '}
					<a href="https://www.facebook.com/privacy/explanation">Privacy Policy</a>
				</p>
				<p>
					<strong>Twitter Tweet button and social widgets</strong> (Twitter) The Twitter Tweet button and social widgets
					are services allowing interaction with the Twitter social network provided by Twitter Inc. Personal Data
					collected: Cookie and Usage data. Place of processing : USA –{' '}
					<a href="https://twitter.com/it/privacy">Privacy Policy</a>
				</p>
				<h2 id="how-can-i-manage-the-installation-of-cookies-">How can I manage the installation of Cookies?</h2>
				<p>
					In addition to what is specified in this document, the User can manage preferences for Cookies directly from
					within their own browser and prevent – for example – third parties from installing them. Through the browser
					preferences, it is also possible to delete Cookies installed in the past, including the Cookies that might
					possibly have saved the consent for the installation of Cookies by this website. It is important to note that
					by disabling all Cookies, the functioning of this site may be compromised. Users can find information about
					how to manage Cookies in their browser at the following addresses:{' '}
					<a href="https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies">Google Chrome</a>,{' '}
					<a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
						Mozilla Firefox
					</a>
					, <a href="https://support.apple.com/kb/PH19214?viewlocale=en_US&amp;locale=en_US">Apple Safari</a> and{' '}
					<a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">Microsoft Edge</a>.
				</p>
				<p>
					In the case of services provided by third parties, Users can exercise their right to withdraw from the
					tracking activity by utilizing the information provided in the third party&#39;s privacy policy, by clicking
					the opt-out link – if provided – or by contacting the third party.
				</p>
				<p>
					Notwithstanding the above, the Owner informs that Users may take advantage of:{' '}
					<a href="http://www.youronlinechoices.com/">Your Online Choices</a>. This service allows Users to select their
					tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of this
					resource in addition to the information provided in this document.
				</p>
				<h2 id="data-controller-and-owner">Data Controller and Owner</h2>
				<p>
					GOOD TIMES - Via di Monte Verde 2/G - 00152 Roma (RM) ITALY,{' '}
					<a href="mailto:info@faceyourmanga.com">info@faceyourmanga.com</a>
				</p>
				<p>
					Since the installation of third party Cookies and other tracking systems through the services used within this
					Application can not be technically controlled by the Owner, any specific references to Cookies and tracking
					systems installed by third parties are to be considered indicative. In order to obtain complete information,
					consult the privacy policy for the respective third party services listed in this document.
				</p>
				<p>
					Given the objective complexity linked to the identification of technologies based on Cookies and their very
					close integration with the operation of the web, Users are encouraged to contact the Owner should they wish to
					receive any further information on the use of Cookies themselves and any possible use of them - for example,
					by a third party - carried out through this site.
				</p>
				<h2 id="definitions-and-legal-references">Definitions and legal references</h2>
				<p>
					<strong>Personal Data (or Data)</strong>
					Any information regarding a natural person, a legal person, an institution or an association, which is, or can
					be, identified, even indirectly, by reference to any other information, including a personal identification
					number.
				</p>
				<p>
					<strong>Usage Data</strong>
					Information collected automatically from this Application (or third party services employed in this
					Application), which can include: the IP addresses or domain names of the computers utilized by the Users who
					use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method
					utilized to submit the request to the server, the size of the file received in response, the numerical code
					indicating the status of the server&#39;s answer (successful outcome, error, etc.), the country of origin, the
					features of the browser and the operating system utilized by the User, the various time details per visit
					(e.g., the time spent on each page within the Application) and the details about the path followed within the
					Application with special reference to the sequence of pages visited, and other parameters about the device
					operating system and/or the User&#39;s IT environment.
				</p>
				<p>
					<strong>User</strong>
					The individual using this Application, which must coincide with or be authorized by the Data Subject, to whom
					the Personal Data refer.
				</p>
				<p>
					<strong>Data Subject</strong>
					The legal or natural person to whom the Personal Data refers.
				</p>
				<p>
					<strong>Data Processor (or Data Supervisor)</strong>
					The natural person, legal person, public administration or any other body, association or organization
					authorized by the Data Controller to process the Personal Data in compliance with this privacy policy.
				</p>
				<p>
					<strong>Data Controller (or Owner)</strong>
					The natural person, legal person, public administration or any other body, association or organization with
					the right, also jointly with another Data Controller, to make decisions regarding the purposes, and the
					methods of processing of Personal Data and the means used, including the security measures concerning the
					operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this
					Application.
				</p>
				<p>
					<strong>This Application</strong>
					The hardware or software tool by which the Personal Data of the User is collected.
				</p>
				<p>
					<strong>Cookie</strong>
					Small piece of data stored in the User&#39;s device.
				</p>
				<p>
					<strong>Legal information</strong>
					Notice to European Users: this privacy statement has been prepared in fulfillment of the obligations under
					Art. 10 of EC Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive
					2009/136/EC, on the subject of Cookies. This privacy policy relates solely to this Application.
				</p>
			</section>
		</section>
	)
}

export default PrivacyCookiePolicy
