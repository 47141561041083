import { nationalities, Nationalities } from '@faceyourmanga/fym-lib'
import * as React from 'react'

function SelectNationality(props: { value?: Nationalities; onChange: (value: Nationalities) => void }) {
	function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const n = e.target.value

		if (isValid(n)) {
			props.onChange(n as Nationalities)
		}
	}

	function isValid(nationality?: string) {
		return (
			typeof nationality !== 'undefined' &&
			nationality.length > 0 &&
			nationalities.includes(nationality as Nationalities)
		)
	}

	return (
		<select
			className={`select select--${isValid(props.value) ? 'valid' : 'invalid'}`}
			value={props.value}
			name="nationality"
			onChange={onChange}
		>
			<option disabled>Nationality</option>
			{nationalities.map(nation => (
				<option key={nation} value={nation}>
					{nation}
				</option>
			))}
		</select>
	)
}

export default React.memo(SelectNationality)
