import { AvatarPagination, IPublicAvatar } from '@faceyourmanga/fym-lib'
import { useServerPaginator } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../../../types/store'
import AdsH from '../../ads/AdsH'
import AdsS from '../../ads/AdsS'
import AvatarPreview from '../../components/AvatarPreview/AvatarPreview'

interface IHomeGalleryProps {
	avatarUrl: string
	bMobile: boolean
}

function HomeGallery(props: IHomeGalleryProps) {
	const size = props.bMobile ? 6 : 15

	const paginator = useServerPaginator<IPublicAvatar | undefined, AvatarPagination>('v1/avatars', {
		page: 1,
		size: size,
		sortBy: 'likes',
		order: 'DESC',
	})

	const items = paginator.getItems()

	if (items.length === 0) return null

	if (items.length < size) {
		for (let i = items.length; i < size; i++) {
			items[i] = undefined
		}
	}

	return (
		<section className="app__content">
			<div className="home-gallery">
				<div className="home-gallery__message">
					<h1>Popular from FYM World</h1>
				</div>

				<div className="home-gallery__container">
					<div className="home-gallery__avatars">
						<div className="home-gallery__avatars__list">
							{paginator.getItems().map((avatar, index) => {
								return avatar === undefined ? (
									<div className="home-gallery__avatars__list__item" key={index}></div>
								) : (
									<div key={avatar.id} className="home-gallery__avatars__list__item">
										<AvatarPreview id={avatar.id} name={avatar.name} thumb={avatar.thumb} withName={false} />
									</div>
								)
							})}
						</div>
						<div className="home-gallery__avatars__footer">
							<div className="home-gallery__avatars__arrows">
								<div
									className={`home-gallery__avatars__arrows__arrow home-gallery__avatars__arrows__arrow--left home-gallery__avatars__arrows__arrow--${
										paginator.hasPrev ? 'active' : 'inactive'
									}`}
									onClick={paginator.prev}
								>
									<RiArrowLeftSLine />
								</div>
								<div
									className={`home-gallery__avatars__arrows__arrow home-gallery__avatars__arrows__arrow--right home-gallery__avatars__arrows__arrow--${
										paginator.hasNext && paginator.getPage() <= 3 ? 'active' : 'inactive'
									}`}
									onClick={paginator.next}
								>
									<RiArrowRightSLine />
								</div>
							</div>
							<small className="home-gallery__avatars__link">
								<Link to="/gallery">EXPLORE THE GALLERY &gt;</Link>
							</small>
						</div>
					</div>

					<div className="home-gallery__ads">
						<AdsH slot={'7762387409'} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		avatarUrl: state.hosts.avatars,
	}))(HomeGallery)
)
