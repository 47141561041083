import * as React from 'react'

import { Api, Auth, ISharedAuthState, PromiseButton } from '@faceyourmanga/fym-shared'
import { connect } from 'react-redux'
import { IRootState } from '../../types/store'
import store from '../../redux/root'
import { nationalities, Nationalities, passedGDPRDate } from '@faceyourmanga/fym-lib'
import { Redirect, withRouter } from 'react-router'
import ConfirmPromise from '../components/Confirm'
import AdsH from '../ads/AdsH'

import SelectNationality from '../components/form/SelectNationality'
import SelectBirth from '../components/form/SelectDate'
import { Link, RouteChildrenProps } from 'react-router-dom'
import dispatchMessage from '../messages/dispatchMessage'

interface IGDPRState {
	birth?: string
	nationality?: Nationalities
}

function GDPRComponent(props: { auth: ISharedAuthState } & RouteChildrenProps) {
	const [state, setState] = React.useState<IGDPRState>({
		birth: undefined,
		nationality: undefined,
	})
	const [bValid, setValid] = React.useState<boolean>(false)
	const [bRedirect, setRedirect] = React.useState<boolean>(false)

	function isValidGDPR(dateStr?: string, nationality?: string): boolean {
		if (typeof dateStr === 'undefined' || typeof nationality === 'undefined') return false

		const date = Date.parse(dateStr)

		return !isNaN(date) && passedGDPRDate(new Date(date)) && nationalities.includes(nationality as Nationalities)
	}

	React.useEffect(() => {
		setValid(isValidGDPR(state.birth, state.nationality))
	}, [state])

	async function send() {
		if (bValid) {
			try {
				const gdpr = {
					birth: Date.parse(state.birth!),
					nationality: state.nationality,
				}
				await Api.post(`auth/gdpr`, gdpr)
				await Auth.verifyToken()
				dispatchMessage('Registration completed.')
				props.history.push('/')
				store.dispatch({ type: 'UNAVAILABLE_GDPR', passed: true })
				setRedirect(true)
			} catch (e) {
				console.log(e)
			}
		}
	}

	if (bRedirect) {
		return <Redirect to="/" />
	}

	async function deleteAccount() {
		if (await ConfirmPromise('Are you sure?')) {
			await Api.post(`auth/delete-account`)
			store.dispatch({ type: 'UNAVAILABLE_GDPR', passed: true })
			setRedirect(true)
		}
	}

	async function logout() {
		setRedirect(true)
		setTimeout(() => Auth.signout())
	}

	return (
		<section className="gdpr">
			<section className="app__content">
				<AdsH slot={'9861102986'} />

				<h1>Accept Our Terms</h1>

				<div className="gdpr__container">
					<div className="gdpr__text">
						Faceyourmanga collects and uses personal data in accordance with our{' '}
						<Link to="/privacy-cookie-policy">Privacy Policy</Link>.<br />
						By creating an account, you agree to our <Link to="/terms-of-use">Terms and Conditions</Link>
					</div>

					<div className="gdpr__form">
						<div className="gdpr__form__select-birth">
							<SelectBirth value={state.birth} onChange={birth => setState({ ...state, birth })} />
						</div>
						<div className="gdpr__form__select-nationality">
							<SelectNationality value={state.nationality} onChange={n => setState({ ...state, nationality: n })} />
						</div>

						<PromiseButton className="button button--green gdpr__form__button" disabled={!bValid} onClick={send}>
							Complete registration
						</PromiseButton>
					</div>
				</div>
				<div className="gdpr__other-actions">
					<div>If you are under 16 years old</div>
				</div>

				<div className="gdpr__other-actions">
					<button className="button button--yellow" onClick={deleteAccount}>
						Delete account
					</button>
					<button className="button" onClick={logout}>
						Logout
					</button>
				</div>
			</section>
		</section>
	)
}

export default React.memo(
	withRouter(
		connect((state: IRootState) => ({
			auth: state.auth,
		}))(GDPRComponent)
	)
)
