import * as React from 'react'
import { connect } from 'react-redux'

import Modal from './components/Modal'
import { TOpenModal } from '../types/interfaces'
import { IRootState } from '../types/store'
import { openModal } from '../redux/app/actions'
import SaveAvatarModal from './modals/SaveAvatarModal'
import LoginRequiredModal from './modals/LoginRequiredModal'

interface IModalsProps {
	modal?: TOpenModal
	modal_props: any
	closeModal: () => void
}

function Modals(props: IModalsProps) {
	function getModalComponent(modalName: TOpenModal) {
		switch (modalName) {
			case 'save-avatar':
				return (
					<SaveAvatarModal
						close={props.closeModal}
						drawable={props.modal_props.drawable}
						avatarId={props.modal_props.avatarId}
					/>
				)
			case 'login-required':
				return <LoginRequiredModal />
		}
	}

	return props.modal ? (
		<Modal open={true} close={props.closeModal}>
			{getModalComponent(props.modal)}
		</Modal>
	) : null
}

export default React.memo(
	connect(
		(state: IRootState) => ({
			modal: state.app.opened_modal,
			modal_props: state.app.opened_modal_props,
		}),
		dispatch => ({
			closeModal: () => dispatch(openModal(undefined)),
		})
	)(Modals)
)
