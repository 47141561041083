import FYMEditor from '@faceyourmanga/fym-editor'
import { AvatarId, IDrawable, IPersonalAvatar } from '@faceyourmanga/fym-lib'
import { Auth, AuthVerified, ISharedAuthState, PrivateApi } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { connect } from 'react-redux'
import { Prompt, Redirect, RouteChildrenProps } from 'react-router-dom'
import { openModal } from '../../../redux/app/actions'
import { IRootState } from '../../../types/store'
import AdsH from '../../ads/AdsH'
import AdsV from '../../ads/AdsV'
import ConfirmPromise from '../../components/Confirm'
import Loading from '../../components/Loading'
import dispatchMessage from '../../messages/dispatchMessage'

interface ICreateProps extends RouteChildrenProps<{ avatarId: string }> {
	auth: ISharedAuthState
	saveAvatarModal: (drawable: IDrawable | null, avatarId?: AvatarId) => void
	loginRequiredModal: () => void
	device: string // 'mobile' | 'desktop'
}

function Create(props: ICreateProps) {
	const token = props.auth.check ? Auth.getAuthenticationAccessToken().getAccessToken() : undefined

	const [mode, setMode] = React.useState<'create' | 'update'>(
		typeof props.match?.params.avatarId !== 'undefined' ? 'update' : 'create'
	)
	const [changed, setChanged] = React.useState<boolean>(false)
	const [firstChange, setFirstChange] = React.useState<boolean>(true)
	const [initialData, setInitialData] = React.useState<string>('')
	const [personalAvatar, setPersonalAvatar] = React.useState<IPersonalAvatar | null>(null)
	const [lastAction, setLastAction] = React.useState<'random' | 'restart' | null>(null)
	const [redirect, setRedirect] = React.useState<boolean>(false)

	React.useEffect(() => {
		const newMode = typeof props.match?.params.avatarId !== 'undefined' ? 'update' : 'create'
		if (newMode === 'update') {
			PrivateApi.get<IPersonalAvatar>(`v1/me/avatars/${props.match!.params.avatarId}`).then(avatar => {
				setPersonalAvatar(avatar)
				setInitialData(JSON.stringify(avatar.drawable))
				setMode('update')
			})
		} else {
			setPersonalAvatar(null)
			setInitialData('')
			setMode('create')
		}
	}, [props.match?.params.avatarId])

	React.useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = ''
		}
	}, [])

	async function onSave(e: IDrawable | null) {
		if (props.auth.user) {
			if (mode === 'update' && personalAvatar) {
				if (await ConfirmPromise('Are you sure you want to apply the changes?')) {
					PrivateApi.put<IPersonalAvatar>(`v1/me/avatars/${personalAvatar.id}`, {
						...personalAvatar,
						drawable: e,
					}).then(avatar => {
						dispatchMessage('Avatar updated!')
						setInitialData(JSON.stringify(e))
						setRedirect(true)
					})
				}
			} else {
				props.saveAvatarModal(e, props.match?.params.avatarId)
			}
		} else {
			props.loginRequiredModal()
		}
	}

	function onChange(drawable: string, action: string) {
		if (firstChange) {
			setFirstChange(false)
		} else if (!changed) {
			setChanged(true)
		}

		if (action !== 'RANDOM' && action !== 'RESTART') {
			setLastAction(null)
		}
	}

	async function onRandom(): Promise<boolean> {
		if (lastAction !== 'random') {
			const result = await ConfirmPromise('Are you sure?<br />All changes will be lost', 'Yes', 'No')
			if (result === false) return false
			setLastAction('random')
		}
		return true
	}

	async function onRestart(): Promise<boolean> {
		const result = await ConfirmPromise(
			mode === 'update'
				? 'Are you sure you want to go back to the initial state before the changes?'
				: 'Are you sure?<br />All changes will be lost',
			'Yes',
			'No'
		)
		if (result === false) return false

		if (mode === 'update') {
			setInitialData(JSON.stringify({ ...JSON.parse(initialData), tid: Math.random() }))
			setLastAction('restart')
			return false
		}

		setLastAction('restart')
		return true
	}

	return <h1>This section will be back online soon</h1>;

	// if (redirect) {
	// 	return <Redirect to={`/profile/avatar/${props.match!.params.avatarId}`} />
	// }

	// return (
	// 	<section className="editor ">
	// 		<section className="app__content">
	// 			{props.device === 'desktop' && <AdsH slot={'3439999011'} />}

	// 			<div className="editor__wrapper">
	// 				<div className="editor__wrapper__container">
	// 					<FYMEditor
	// 						enabledStorage={mode === 'create'}
	// 						mode={mode}
	// 						authToken={token ? token : undefined}
	// 						drawable={initialData}
	// 						onChange={onChange}
	// 						display={props.device as 'mobile' | 'desktop'}
	// 						onSave={onSave}
	// 						onRandom={onRandom}
	// 						onRestart={onRestart}
	// 					/>
	// 				</div>
	// 				<div className="editor__wrapper__ads">
	// 					{props.device === 'desktop' ? <AdsV slot={'8855977223'} /> : <AdsH height={80} slot={'9585013929'} />}
	// 				</div>
	// 			</div>

	// 			{props.device === 'desktop' && <AdsH slot={'6996100642'} />}
	// 		</section>
	// 	</section>
	// )
}

function CreateWrapper(props: ICreateProps) {
	return (
		<AuthVerified Loading={<Loading />}>
			<Create {...props} />
		</AuthVerified>
	)
}

export default React.memo(
	connect(
		(state: IRootState) => ({
			auth: state.auth,
			device: state.device.bMobile ? 'mobile' : 'desktop',
		}),
		dispatch => ({
			saveAvatarModal: (drawable: IDrawable | null, avatarId?: AvatarId) =>
				dispatch(openModal('save-avatar', { drawable, avatarId })),
			loginRequiredModal: () => dispatch(openModal('login-required')),
		})
	)(CreateWrapper)
)
