import { IAppState } from '../../types/store'
import { OPEN_MENU, CLOSE_MENU, TOGGLE_MENU, APP_IS_ON_HOMEPAGE, AppActionsType, OPEN_MODAL } from './actions'

import { defaultAppState } from './appDefaultState'

const appReducer = (state = defaultAppState, action: AppActionsType): IAppState => {
	switch (action.type) {
		case TOGGLE_MENU:
			return { ...state, bMenuOpen: !state.bMenuOpen }

		case CLOSE_MENU:
			return { ...state, bMenuOpen: false }

		case OPEN_MENU:
			return { ...state, bMenuOpen: true }

		case APP_IS_ON_HOMEPAGE:
			return { ...state, bHomepage: action.bHomepage }

		case OPEN_MODAL:
			return { ...state, opened_modal: action.modal, opened_modal_props: action.data }
	}

	return state
}

export default appReducer
