import * as React from 'react'

export interface ITextareaProps {
	value: string
	label?: string
	onChange: (value: string) => void
	onEnter?: () => void
	error?: boolean
	errorMessage?: string
	type?: 'text' | 'password' | 'email'
	name?: string
	className?: string
}

function Textarea(props: ITextareaProps) {
	function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		props.onChange(e.target.value)
	}

	function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
		props.onEnter && e.key === 'Enter' && props.onEnter()
	}

	return (
		<div className={`textarea ${props.error ? 'textarea--error' : ''}`}>
			<textarea
				rows={3}
				name={props.name}
				className={`textarea__textarea ${props.className}`}
				value={props.value}
				onKeyDown={onKeyDown}
				onChange={onChange}
			/>
			{props.label && (
				<span className={`textarea__label ${props.value && props.value.length ? 'textarea__label--minimized' : ''}`}>
					{props.label}
				</span>
			)}
		</div>
	)
}

export default React.memo(Textarea)
