import { IPublicAvatar, IPublicUser } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { RiHeart3Fill } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Events } from '@faceyourmanga/fym-shared'
import { IRootState } from '../../../types/store'

import AddToCartButton from '../../cart/AddToCartButton'
import { getHumanLiked } from '../../components/AvatarPreview/AvatarPreviewLikes'
import Hashtag from '../../components/Hashtag'

import UserProfileImage from '../../components/UserProfileImage'
import { shareOnFacebook, shareOnTwitter } from '../../utilities/shareAvatar'
import AvatarLike from './AvatarLike'

interface IAvatarMetaProps {
	avatar: IPublicAvatar
	owner: IPublicUser | undefined
	bMobile: boolean
}

function AvatarMeta({ avatar, owner, bMobile }: IAvatarMetaProps) {
	const [likes, setLikes] = React.useState(avatar.likes)

	function onCustomLike(liked: number){
		setLikes(avatar.likes + liked)
	}

	return (
		<div className="avatar__meta">
			<div className="avatar__meta__left">
				{owner && owner.username && (
					<div className="avatar__meta__left__author">
						<Link to={`/@${owner.username}`}>
							<UserProfileImage user={owner} />
							<span className="avatar__meta__left__author__username">@{owner.username}</span>
						</Link>
					</div>
				)}

				<div className="avatar__meta__left__like">
					<RiHeart3Fill />
					<span>{getHumanLiked(likes)}</span>
				</div>

				<div className="avatar__meta__left__hashtags">
					{avatar.hashtags.map(h => (
						<Hashtag key={h} value={h} />
					))}
				</div>
			</div>
			<div className="avatar__meta__right">
				<div className="avatar__meta__right__like">
					<div className="avatar__meta__right__button">
						<AvatarLike avatarId={avatar.id} onChange={onCustomLike} />
					</div>
					<span>Like</span>
				</div>

				<div className="avatar__meta__right__share">
					<span className="avatar__meta__right__button" onClick={() => shareOnFacebook(avatar)}>
						<img src="/assets/images/facebook-logo-black.svg" alt="Share on Facebook" />
					</span>
					<span className="avatar__meta__right__button" onClick={() => shareOnTwitter(avatar)}>
						<img src="/assets/images/twitter-logo-black.svg" alt="Share on Twitter" />
					</span>
					<span>Share</span>
				</div>

				{!bMobile && (
					<div className="avatar__meta__right__cart">
						<AddToCartButton avatarId={avatar.id} />
					</div>
				)}
			</div>

			{bMobile && (
				<div className="avatar__meta__cart">
					<AddToCartButton avatarId={avatar.id} />
				</div>
			)}
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		bMobile: state.device.bMobile
	}))(AvatarMeta)
)
