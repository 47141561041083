import { IUser } from '@faceyourmanga/fym-lib'
import { Auth, PrivateApi, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiEyeOffLine } from 'react-icons/ri'
import ConfirmPromise from '../../../../components/Confirm'
import Input from '../../../../components/form/Input'
import { checkUsernameExists, isValidUsername } from '../../../../utilities/checkUsernameExists'

function DashboardChangeUsername({ user }: { user: IUser }) {
	const [username, setUsername] = React.useState<string>(user.username || '')
	const [error, setError] = React.useState<string | undefined>(undefined)

	React.useEffect(() => {
		if (username.length >= 3 && user.username !== username) {
			if (!isValidUsername(username)) {
				return setError('Username not valid')
			} else {
				checkUsernameExists(username).then(v => {
					setError(v ? 'Username exist' : undefined)
				})
			}
		}
	}, [username])

	async function changeUsername() {
		if (
			await ConfirmPromise(
				user.username
					? `Sure you want to change the username from "${user.username.toLowerCase()}" to "${username.toLowerCase()}"?`
					: `Sure you want to change the username to "${username.toLowerCase()}"?`
			)
		) {
			try {
				await PrivateApi.put<boolean>(`v1/me/username`, username.toLowerCase())

				Auth.setUser({ ...user, username })
			} catch (e) {
				return setError('An error occurred')
			}
		}
	}

	async function deleteUsername() {
		if (await ConfirmPromise('If you continue, users will not be able to search and view your profile page'))
			try {
				await PrivateApi.delete<boolean>(`v1/me/username`)

				setUsername('')
				setError(undefined)
				Auth.setUser({ ...user, username: undefined })
			} catch (e) {
				return setError('An error occurred')
			}
	}

	const bError = typeof error !== 'undefined'
	const disabled = username.length < 3 || username === user.username || bError

	return (
		<div className="dashboard__change-username">
			<Input error={bError} label="Username" value={username} onChange={u => setUsername(u.toLowerCase())} />

			<PromiseButton
				className={`button ${
					disabled ? 'button--disabled' : ''
				} button--save button--green button--rounded button--small`}
				onClick={changeUsername}
			>
				CHANGE
			</PromiseButton>

			{error && <div className="dashboard__change-username__error">{error}</div>}

			{user.username && (
				<div>
					<div className="dashboard__change-username__remove">
						<div className="dashboard__change-username__remove__title">
							<RiEyeOffLine />
							Set my profile page as private

						</div>
						<div>
							<small>
							If checked users won’t be able to search and see your profile page (not recommended)
							</small>
						</div>
						<br />
						<PromiseButton className="button button--small" onClick={deleteUsername}>
							Set As Private
						</PromiseButton>
					</div>
				</div>
			)}
		</div>
	)
}

export default React.memo(DashboardChangeUsername)
