import { Auth, BadRequest, Conflict, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { nationalities, Nationalities, passedGDPRDate } from '@faceyourmanga/fym-lib'

import { IValidationMessage } from '../../types/validation'
import { IWithAuth } from '../../types/interfaces'
import { IRootState } from '../../types/store'

import Input from '../components/form/Input'
import SelectNationality from '../components/form/SelectNationality'
import SelectBirth from '../components/form/SelectDate'

import ValidationMessages from './ValidationMessages'
import { checkUsernameExists, isValidUsername } from '../utilities/checkUsernameExists'
import AuthProviders from './AuthProviders'
import AuthBanner from './AuthBanner'
import dispatchMessage from '../messages/dispatchMessage'
import { Link } from 'react-router-dom'

interface ISignUpState {
	email: string
	password: string
	repeat_password: string
	username?: string
	nickname?: string
	birth?: string
	nationality?: Nationalities
}

function SignUp(props: IWithAuth & { bMobile: boolean }) {
	const [created, setCreated] = React.useState<boolean>(false)
	const [bUsernameExists, setUsernameExists] = React.useState<boolean>(false)
	const [bUsernameValid, setUsernameValid] = React.useState<boolean>(true)

	const [state, setState] = React.useState<ISignUpState>({
		email: '',
		password: '',
		repeat_password: '',
		username: undefined,
		nickname: undefined,
		nationality: undefined,
		birth: undefined,
	})

	const [errors, setErrors] = React.useState<Array<IValidationMessage>>([])

	async function signup() {
		try {
			await Auth.signup(state.email, state.password, { ...state, birth: new Date(state.birth || Date.now()).getTime() })
			// restituire il messaggio di validare email
			setCreated(true)
		} catch (e) {
			if (e instanceof BadRequest) {
				setErrors(e.data.validation)
			} else if (e instanceof Conflict) {
				// email gia esistente
				setErrors([{ message: e.data!.message }])
			} else {
				setErrors([{ message: 'Unexpected error' }])
			}
		}
	}

	React.useEffect(() => {
		if (typeof state.username === 'undefined' || state.username.length === 0) {
			setUsernameValid(true)
			setUsernameExists(false)
		} else {
			if (isValidUsername(state.username)) {
				setUsernameValid(true)
				checkUsernameExists(state.username).then(setUsernameExists)
			} else {
				setUsernameValid(false)
			}
		}
	}, [state.username])

	React.useEffect(() => {
		if (props.auth.check) {
			dispatchMessage(
				'You are already logged in, log out of your profile page if you want to log in with another account'
			)
		}
	}, [])

	if (props.auth.check) {
		return <Redirect to="/" />
	}

	if (created) {
		return <UserCreated email={state.email} />
	}

	function isValidGDPR(dateStr?: string, nationality?: string): boolean {
		if (typeof dateStr === 'undefined' || typeof nationality === 'undefined') return false

		const date = Date.parse(dateStr)

		return !isNaN(date) && passedGDPRDate(new Date(date)) && nationalities.includes(nationality as Nationalities)
	}

	const disableBtn =
		state.email.length === 0 ||
		state.password.length < 4 ||
		state.password !== state.repeat_password ||
		state.birth === undefined ||
		state.nationality === undefined ||
		!isValidGDPR(state.birth, state.nationality)

	return (
		<section className="app__page signup">
			<section className="app__content">
				<div className="auth-container">
					<h1 className="signup__title">Sign up</h1>

					<div className="signup__container">
						<div className="signup__container__required-fields">
							<small>* required fields</small>
						</div>
						<div className="signup__credentials">
							<Input
								label="Email*"
								name="email"
								type="email"
								value={state.email}
								onChange={email => setState({ ...state, email })}
							/>
							<Input
								label="Password*"
								name="password"
								type="password"
								value={state.password}
								onChange={password => setState({ ...state, password })}
							/>
							<Input
								error={state.repeat_password !== state.password}
								label="Repeat password*"
								name="repeat_password"
								type="password"
								value={state.repeat_password}
								onChange={repeat_password => setState({ ...state, repeat_password })}
							/>

							<div>
								<Input
									label="Your name"
									value={state.nickname || ''}
									onChange={nickname => setState({ ...state, nickname })}
								/>
							</div>

							<div>
								<Input
									error={!bUsernameValid || bUsernameExists}
									label="Username"
									value={state.username || ''}
									onChange={username => setState({ ...state, username })}
								/>
								{bUsernameExists && <div>username exists</div>}
							</div>
							<div>
								<small>Date of birth*</small>
								<br />
								<small>You must be at least 16 years old</small>
								<div>
									<SelectBirth value={state.birth} onChange={birth => setState({ ...state, birth })} />
								</div>
							</div>
							<div>
								<small>Nationality*</small>
								<div>
									<SelectNationality value={state.nationality} onChange={n => setState({ ...state, nationality: n })} />
								</div>
							</div>
							<PromiseButton className={`button ${disableBtn ? 'button--disabled' : ''}`} onClick={signup}>
								Sign Up
							</PromiseButton>

							<AuthProviders />
						</div>

						<div className="signin__register">
							<div>
								Already have an account?
								<Link to="/sign-in">Login</Link>
							</div>
						</div>

						<ValidationMessages errors={errors} />
					</div>
				</div>
			</section>

			{!props.bMobile && <AuthBanner />}
		</section>
	)
}

function UserCreated({ email }: { email: string }) {
	return (
		<section className="signup app__page">
			<section className="app__content">
				<h1 className="signup__title">Creation successful</h1>

				<div className="signup__container">
					<div>
						An email will be sent to <b>{email}</b>.
					</div>
					<div>Confirm it and start using FaceYourManga!</div>
				</div>
			</section>
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		auth: state.auth,
		bMobile: state.device.bMobile,
	}))(SignUp)
)
