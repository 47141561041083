import * as React from 'react'
import { RiSettings2Line, RiShoppingCart2Fill } from 'react-icons/ri'
import { MdViewCarousel } from 'react-icons/md'
import { connect } from 'react-redux'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import { IWithExistentUser } from '../../../../types/interfaces'
import { IRootState } from '../../../../types/store'
import Tab from '../../../components/tab/Tab'
import TabItem from '../../../components/tab/TabItem'
import DashboardAvatars from './Avatars/DashboardAvatars'
import DashboardProfile from './DashboardProfile'
import DashboardTransactions from './Transactions/DashboardTransactions'
import DashboardUserSettings from './UserSettings/DashboardUserSettings'
import AdsH from '../../../ads/AdsH'

interface IDashboardProps extends RouteChildrenProps<{ tabName: string }>, IWithExistentUser {}

function Dashboard(props: IDashboardProps) {
	let history = useHistory()

	function onTabChange(e: string) {
		history.push('/profile/' + e)
	}

	return (
		<section className="dashboard">
			<section className="app__content">
				<section className="dashboard__profile">
					<DashboardProfile user={props.user} />

					<Tab selected={props.match?.params.tabName} onChange={onTabChange}>
						<TabItem slug="avatars" title="Avatars" icon={<MdViewCarousel />}>
							<DashboardAvatars user={props.user} />
						</TabItem>
						<TabItem slug="purchases" title="Purchases" icon={<RiShoppingCart2Fill />}>
							<DashboardTransactions />
						</TabItem>
						<TabItem slug="settings" title="Settings" icon={<RiSettings2Line />}>
							<DashboardUserSettings user={props.user} />
						</TabItem>
					</Tab>
				</section>

				<AdsH slot={'4685594081'} />
			</section>
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		user: state.auth.user!,
	}))(Dashboard)
)
