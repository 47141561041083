import { Price } from '@faceyourmanga/fym-lib/dist'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../../../types/store'
import { sortPrices } from '../../utilities/pricelist'

interface IHomePricingsProps {
	bMobile: boolean
	currency: string
	prices: Record<string, Price>
}

function HomePricings(props: IHomePricingsProps) {
	const prices = sortPrices(props.prices)

	return (
		<div className="home-avatars-pricings">
			<div className="home-avatars-pricings__message">
				<div className="app__content">
					<h1>Unleash your creativity, a size for any needs</h1>
					<div>
						Give life to your most amazing creations. From video to high resolution printing, choose the size that best
						suits your needs!
					</div>
				</div>
			</div>
			<div className="app__content ">
				<div className="home-avatars-pricings__prices">
					{prices.map(price => (
						<div key={price.resolution} className="home-avatars-pricings__prices__price ">
							<div className="home-price" style={{ background: price.color }}>
								<div className="home-price__name" style={{ color: price.color }}>
									{price.name}
								</div>
								<div className="home-price__price">
									<div className="home-price__price__currency">{props.currency}</div>
									<div className="home-price__price__int">{price.price.toFixed(2).split('.')[0]}</div>
									<div className="home-price__price__decimal">.{price.price.toFixed(2).split('.')[1]}</div>
								</div>
								<div className="home-price__description">
									<div className="home-price__description__item home-price__description__item--two">
										<div>- {price.description}</div>
									</div>
									<div className="home-price__description__item">- No Watermark</div>
									<div className="home-price__description__item">- File Size: {price.resolution} px</div>
									<div className="home-price__description__item">- Personal Use License</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="home-avatars-pricings__buttons">
				<Link to="/sign-up">
					<button className="button button--secondary--stroke button--rounded">
						Sign up for free and buy your artwork
					</button>
				</Link>
				<div>
					Already have an account? <Link to="/sign-in">Login</Link>
				</div>
			</div>
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		prices: state.payinfo.prices,
		currency: state.payinfo.currency,
	}))(HomePricings)
)
