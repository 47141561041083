import { AvatarId, IUser } from '@faceyourmanga/fym-lib'
import { Auth, Events, PrivateApi } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiHeart3Fill, RiHeart3Line } from 'react-icons/ri'

interface IAvatarPreviewLikesProps {
	user: IUser | null
	avatarId: AvatarId
	likes?: number
}

function AvatarPreviewLikes(props: IAvatarPreviewLikesProps) {
	const [liked, setLiked] = React.useState<boolean>(props.user ? props.user.likes.includes(props.avatarId) : false)
	const [likes, setLikes] = React.useState<number | undefined>(props.likes)
	const [animated, setAnimated] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (props.user) {
			const l = props.user.likes.includes(props.avatarId)
			if (l !== liked) {
				setAnimated(true)
				setTimeout(() => {
					setAnimated(false)
				}, 500)
				setLiked(l)
			}
		}
	}, [props.user, props.avatarId, props.user?.likes])

	React.useEffect(() => {
		function onLike(e: any) {
			if (e.avatarId === props.avatarId) {
				setLikes(e.likes)
			}
		}

		Events.attach('onLike', onLike)

		return () => {
			Events.detach('onLike', onLike)
		}
	}, [props.avatarId])

	if (typeof props.user === 'undefined') {
		return (
			<div className={`avatar-preview__likes avatar-preview__likes--${liked ? 'liked' : 'unliked'}`}>
				{typeof likes !== 'undefined' && <span className="avatar-preview__likes__counter">{getHumanLiked(likes)}</span>}
				<RiHeart3Line />
			</div>
		)
	}

	async function toggleLike(e: React.MouseEvent) {
		e.preventDefault()

		if (props.user) {
			await toggleAvatarLike(props.avatarId, props.user)
		}
	}

	return (
		<div
			className={`avatar-preview__likes avatar-preview__likes--cliccable avatar-preview__likes--${
				liked ? 'liked' : 'unliked'
			} ${animated ? 'avatar-preview__likes--animated' : ''}`}
			onClick={toggleLike}
		>
			{liked ? <RiHeart3Fill /> : <RiHeart3Line />}
			{typeof likes !== 'undefined' && <span className="avatar-preview__likes__counter">{getHumanLiked(likes)}</span>}
		</div>
	)
}

export function getHumanLiked(likes: number): string {
	if (likes < 1000) return likes + ''

	if (likes < 1000000) return Math.round(likes / 100) / 10 + 'K'

	return Math.round(likes / 100000) / 10 + 'M'
}

export default React.memo(AvatarPreviewLikes)

export async function toggleAvatarLike(avatarId: AvatarId, user: IUser) {
	try {
		const newLikeState = !user.likes.includes(avatarId)
		const newLikes = await PrivateApi.put<number>(`v1/avatars/${avatarId}/like`, newLikeState)
		const updated_user = { ...user } as IUser

		if (newLikeState) {
			if (!updated_user.likes.includes(avatarId)) {
				updated_user.likes.push(avatarId)
			}
		} else {
			if (updated_user.likes.includes(avatarId)) {
				updated_user.likes.splice(updated_user.likes.indexOf(avatarId), 1)
			}
		}

		Auth.setUser(updated_user)
		Events.dispatch('onLike', { avatarId: avatarId, likes: newLikes })
	} catch (e) {
	}
}
