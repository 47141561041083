import { Auth } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { Redirect } from 'react-router'

function ConfirmEmail() {
	const confirmCode = new URLSearchParams(window.location.search).get('confirm')

	const [confirmed, setConfirmed] = React.useState<boolean>(false)
	const [redirect, setRedirect] = React.useState<boolean>(false)
	const [error, setError] = React.useState<boolean>(false)

	async function sendConfirm(code: string) {
		try {
			await Auth.confirmEmail(code)
			setConfirmed(true)

			setTimeout(() => {
				setRedirect(true)
			}, 3000)
		} catch (e) {
			setError(true)
		}
	}

	React.useEffect(() => {
		if (confirmCode) {
			sendConfirm(confirmCode)
		}
	}, [confirmCode])

	if (error) {
		return (
			<section className="app__page auth-emails__confirm-email">
				<section className="app__content">
					<h1 className="auth-emails__confirm-email__error">An error has occurred, please try again later</h1>
				</section>
			</section>
		)
	}
	if (redirect) {
		return <Redirect to="/sign-in" />
	}

	if (confirmed) {
		return (
			<section className="app__page auth-emails__confirm-email">
				<section className="app__content">
					<h1 className="auth-emails__confirm-email__success">
						Email confirmed , you will be redirected to login shortly
					</h1>
				</section>
			</section>
		)
	}

	if (confirmCode)
		return (
			<section className="auth-emails__confirm-email">
				<section className="app__content">
					<h1>Waiting</h1>
				</section>
			</section>
		)
	else return null
}

export default ConfirmEmail
