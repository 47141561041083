import React from 'react'
import { Api } from '@faceyourmanga/fym-shared'

function useAvatarCount(): { millions: number; total: number } {
	const [avatarsCount, setAvatarsCount] = React.useState(0)

	React.useEffect(() => {
		Api.get<number>('v1/avatars/count').then(setAvatarsCount)
		// const itv = setInterval(async () => {
		// 	setAvatarsCount(await Api.get<number>('v1/avatars/count'))
		// }, 10000)

		// return () => {
		// 	clearInterval(itv)
		// }
	}, [])

	const count = 14380881 + avatarsCount

	const m = count / 1000000
	const millions = Math.floor(m * 10) / 10

	return {
		millions,
		total: count,
	}
}

export default useAvatarCount
