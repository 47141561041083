import { AvatarId } from '@faceyourmanga/fym-lib'
import { Events } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiHeart3Fill, RiHeart3Line } from 'react-icons/ri'
import { connect } from 'react-redux'
import { openModal } from '../../../redux/app/actions'
import { IWithUser } from '../../../types/interfaces'
import { IRootState } from '../../../types/store'
import { toggleAvatarLike } from '../../components/AvatarPreview/AvatarPreviewLikes'

interface IAvatarLikeProps extends IWithUser {
	avatarId: AvatarId
	onChange?: (liked: number) => void
	loginRequiredModal: () => void
}

function AvatarLike(props: IAvatarLikeProps) {
	const [initialLiked] = React.useState<number>(props.user ? props.user.likes.includes(props.avatarId) ? 1 : 0 : 0)
	const [liked, setLiked] = React.useState<boolean>(props.user ? props.user.likes.includes(props.avatarId) : false)

	React.useEffect(() => {
		if (props.user) {
			setLiked(props.user.likes.includes(props.avatarId))
		}
	}, [props.user, props.avatarId, props.user?.likes])

	React.useEffect(() => {
		props.user && props.onChange && props.onChange(!initialLiked && liked ? 1 : initialLiked && !liked ? -1 : 0)
	}, [liked])

	function onClick() {
		if (!props.user) {
			props.loginRequiredModal()
		} else {
			toggleAvatarLike(props.avatarId, props.user)
		}
	}

	return (
		<div
			className={`avatar__container__actions__likes avatar__container__actions__likes--cliccable avatar__container__actions__likes--${
				liked ? 'liked' : 'unliked'
			}`}
			onClick={onClick}
		>
			<span className="avatar__container__actions__likes__icon">{liked ? <RiHeart3Fill /> : <RiHeart3Line />}</span>
		</div>
	)
}

export default React.memo(
	connect(
		(state: IRootState) => ({
			user: state.auth.user,
		}),
		dispatch => ({
			loginRequiredModal: () => dispatch(openModal('login-required')),
		})
	)(AvatarLike)
)
