import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../../../types/store'
import AdsH from '../../ads/AdsH'
import Appear from '../../components/Appear'
import HomeAvatarsCreated from './HomeAvatarsCreated'
import HomeCommunity from './HomeCommunity'
import HomeGallery from './HomeGallery'
import HomePricings from './HomePricings'
import HomeSlide from './HomeSlide'

interface IHomeProps {
	bMobile: boolean
	logged: boolean
}
function Home(props: IHomeProps) {
	return (
		<section className="home">
			<HomeSlide bMobile={props.bMobile} logged={props.logged} />

			<div className="app__content home-adv">
				<AdsH slot={'9861102986'} />
			</div>

			<Appear duration={1000} delay={300}>
				<HomeCommunity bMobile={props.bMobile} />
			</Appear>

			<Appear duration={1000} delay={300}>
				<div className="home-get-started">
					<div className="app__content">
						<div className="home-get-started__message">
							<h2>Get started now!</h2>
							<div>Create your account today, it's free!</div>
						</div>
						<div className="home-get-started__button">
							<Link to="/sign-up">
								<button className="button">Signup for free</button>
							</Link>

							<div>
								<small>
									Already have an account? <Link to="/sign-in">Login</Link>
								</small>
							</div>
						</div>
					</div>
				</div>
			</Appear>

			<Appear duration={1000} delay={300}>
				<HomeGallery bMobile={props.bMobile} />
			</Appear>

			<Appear duration={1000} delay={300}>
				<section className="home-mick">
					<section className="app__content">
						<div className="home-mick__image">
							<img src="assets/images/home/mick-jagger.png" alt="" />
						</div>
						<div className="home-mick__message">
							<h1>
								Turn your avatar in
								<br />a work of art.
							</h1>

							<div>
								Thanks to a massive and powerful graphic library,
								<br />
								you'll be able to create any character you want
								<br />
								transforming your creations in a work of art.
							</div>
							<div className="home-mick__message__button">
								<Link to="/create" className="button button--rounded">
									START CREATING
								</Link>
							</div>
						</div>
					</section>
				</section>
			</Appear>

			<Appear duration={1000} delay={300}>
				<HomeAvatarsCreated />
			</Appear>

			<Appear duration={1000} delay={300}>
				<HomePricings bMobile={props.bMobile} />
			</Appear>
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		logged: state.auth.check,
		bMobile: state.device.bMobile,
	}))(Home)
)
