import * as React from 'react'

import LoadingIcon from './LoadingIcon'

function Loading() {
	return (
		<div className="loading">
			<LoadingIcon />
		</div>
	)
}

export default React.memo(Loading)
