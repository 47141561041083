import * as React from 'react'
import { connect } from 'react-redux'

import { IPersonalAvatar, IPublicAvatarWithUsername, IUser } from '@faceyourmanga/fym-lib'

import { IRootState } from '../../../../../types/store'
import { PrivateApi, useSimplePaginator } from '@faceyourmanga/fym-shared'
import AvatarPreview from '../../../../components/AvatarPreview/AvatarPreview'

import { RiEdit2Fill, RiEyeOffLine, RiHeart3Fill } from 'react-icons/ri'

function DashboardAvatars(props: { avatarUrl: string; user: IUser; bMobile: boolean }) {
	const [selected, setSelected] = React.useState<'created' | 'likes' | 'privated'>('created')

	const [created, setCreated] = React.useState<Array<IPersonalAvatar>>([])
	const [privated, setPrivated] = React.useState<Array<IPersonalAvatar>>([])

	React.useEffect(() => {
		PrivateApi.get<Array<IPersonalAvatar>>('v1/me/avatars').then(avatars => {
			avatars = avatars.sort((a, b) => b.created_at - a.created_at)

			setCreated(avatars.filter(a => !a.private))
			setPrivated(avatars.filter(a => a.private))
		})
	}, [])

	const [liked, setLiked] = React.useState<Array<IPublicAvatarWithUsername>>([])

	React.useEffect(() => {
		PrivateApi.get<Array<IPublicAvatarWithUsername>>(`v1/users/${props.user.id}/liked`).then(avatars => {
			setLiked(avatars.reverse())
		})
	}, [])

	React.useEffect(() => {
		const paginators = [createdPaginator, privatedPaginator, likedPaginator]

		paginators.forEach(p => {
			p.go(0)
		})
	}, [selected])

	const createdPaginator = useSimplePaginator(created, 12)
	const privatedPaginator = useSimplePaginator(privated, 12)
	const likedPaginator = useSimplePaginator(liked, 12)

	function next() {
		switch (selected) {
			case 'created':
				return createdPaginator.appendNext()
			case 'privated':
				return privatedPaginator.appendNext()
			case 'likes':
				return likedPaginator.appendNext()
		}
	}

	return (
		<section className="dashboard__avatars">
			<div className="dashboard__avatars__tab">
				<div
					className={
						'dashboard__avatars__tab__item dashboard__avatars__tab__item--' +
						(selected === 'created' ? 'active' : 'inactive')
					}
					onClick={() => setSelected('created')}
				>
					<RiEdit2Fill />
					<div>Created</div>
				</div>
				<div
					className={
						'dashboard__avatars__tab__item dashboard__avatars__tab__item--' +
						(selected === 'likes' ? 'active' : 'inactive')
					}
					onClick={() => setSelected('likes')}
				>
					<RiHeart3Fill />
					<div>Favourites</div>
				</div>
				<div
					className={
						'dashboard__avatars__tab__item dashboard__avatars__tab__item--' +
						(selected === 'privated' ? 'active' : 'inactive')
					}
					onClick={() => setSelected('privated')}
				>
					<RiEyeOffLine />
					<div>Private</div>
				</div>
			</div>

			{selected === 'created' && (
				<div className="dashboard__avatars__list dashboard__paginator__list">
					{createdPaginator.list.length > 0 ? (
						createdPaginator.list.map((avatar, index) => (
							<div className="dashboard__avatars__list__avatar" key={'created_' + avatar.id}>
								<AvatarPreview
									id={avatar.id}
									name={avatar.name}
									thumb={avatar.thumb}
									likes={avatar.likes}
									withLike={true}
									isPrivate={true}
								/>
							</div>
						))
					) : (
						<div style={{ textAlign: 'center' }}>
							<i>Empty list</i>
						</div>
					)}
				</div>
			)}

			{selected === 'privated' && (
				<div className="dashboard__avatars__list dashboard__paginator__list">
					{privatedPaginator.list.length > 0 ? (
						privatedPaginator.list.map((avatar, index) => (
							<div className="dashboard__avatars__list__avatar" key={'privated_' + avatar.id}>
								<AvatarPreview
									id={avatar.id}
									name={avatar.name}
									thumb={avatar.thumb}
									likes={avatar.likes}
									withLike={true}
									isPrivate={true}
								/>
							</div>
						))
					) : (
						<div style={{ textAlign: 'center' }}>
							<i>Empty list</i>
						</div>
					)}
				</div>
			)}

			{selected === 'likes' && (
				<div className="dashboard__avatars__liked-list dashboard__paginator__list">
					{likedPaginator.list.length > 0 ? (
						likedPaginator.list.map((liked, index) => (
							<div className="dashboard__avatars__liked-list__avatar" key={'liked_' + liked.id}>
								<AvatarPreview
									id={liked.id}
									name={liked.name}
									thumb={liked.thumb}
									owner={liked.username}
									likes={liked.likes}
									withLike={true}
								/>
							</div>
						))
					) : (
						<div style={{ textAlign: 'center' }}>
							<i>Empty list</i>
						</div>
					)}
				</div>
			)}

			{(selected === 'created'
				? createdPaginator.bNeeded
				: selected === 'privated'
				? privatedPaginator.bNeeded
				: likedPaginator.bNeeded) && (
				<div className="dashboard__avatars__load-more">
					<button
						className={`button button--secondary--stroke button--rounded ${
							(
								selected === 'created'
									? createdPaginator.bLast
									: selected === 'privated'
									? privatedPaginator.bLast
									: likedPaginator.bLast
							)
								? 'button--disabled'
								: ''
						}`}
						onClick={next}
					>
						Load more
					</button>
				</div>
			)}
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		bMobile: state.device.bMobile,
		avatarUrl: state.hosts.avatars,
	}))(DashboardAvatars)
)
