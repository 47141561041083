import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import { Authenticated } from '@faceyourmanga/fym-shared'

import { IWithAuth } from '../../../types/interfaces'
import Dashboard from './Dashboard/Dashboard'
import PersonalAvatar from './Dashboard/Avatars/PersonalAvatar'
import Loading from '../../components/Loading'

function ProfileRouter(props: IWithAuth) {
	return (
		<Authenticated Fallback={<Redirect to="/" />} Loading={<Loading />}>
			<Switch>
				<Route exact path="/profile/avatar/:avatarId" component={PersonalAvatar} />
				<Route exact path="/profile/:tabName?" component={Dashboard} />
			</Switch>
		</Authenticated>
	)
}

export default React.memo(ProfileRouter)
