import { AvatarId } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteChildrenProps } from 'react-router'
import { IRootState } from '../../../types/store'
import AdsH from '../../ads/AdsH'
import Loading from '../../components/Loading'
import AvatarMeta from './AvatarMeta'
import AvatarOwnerAvatars from './AvatarOwnerAvatars'
import AvatarRelateds from './AvatarRelateds'
import useAvatar from './useAvatar'

interface IAvatarProps extends RouteChildrenProps<{ avatarId: AvatarId }> {
	avatarUrl: string
}

function Avatar(props: IAvatarProps) {
	const { redirect, avatar, owner, ownerAvatars, relateds } = useAvatar(props.match!.params.avatarId)

	// Se l'username non viene settato non puoi vedere gli avatar che ha creato (da specifiche, per chi non vuole essere raggiunto)

	if (redirect) {
		return <Redirect to="/gallery" />
	}

	if (!avatar) {
		return <Loading />
	}

	return (
		<section className="avatar">
			<section className="app__content">
				<AdsH slot={'4725160523'} />

				<h1 className="avatar__title">{avatar.name}</h1>

				<div className="avatar__container">
					<div className="avatar__container__image">
						<img src={props.avatarUrl + '/' + avatar.thumb} alt="" />
					</div>
					<div className="avatar__container__message">
						<div className="avatar__container__message__message">
							<div>{avatar.message || '...'}</div>
						</div>

						<AdsH slot={'7475408951'} />
					</div>
				</div>

				<AvatarMeta avatar={avatar} owner={owner} />

				<AvatarRelateds relateds={relateds} />

				<div className="avatar__bottom__ads">
					<AdsH slot={'4685594081'} />
				</div>

				<AvatarOwnerAvatars owner={owner} ownerAvatars={ownerAvatars} />
			</section>
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		avatarUrl: state.hosts.avatars,
	}))(Avatar)
)
