import { Price } from '@faceyourmanga/fym-lib'
import { Storage } from '@faceyourmanga/fym-shared/dist/extension/storage'
import { ITempCartItem } from './CartTypes'

const CART_STORAGE_KEY = '345e98rt7uhy7fgasdv'

class CartController {
	private gateway: string = 'paypal'
	private items: Array<ITempCartItem> = []
	private tmpItems: Array<ITempCartItem> | null = null
	private pricelist: Record<string, Price> = {}

	private changeCallback: { [key: string]: Function } = {}

	constructor() {
		const data = Storage.get(CART_STORAGE_KEY, { gateway: 'paypal', items: [] })

		if (data) {
			this.gateway = data.gateway || 'paypal'
			this.tmpItems = data.items || null
		} else {
			this.gateway = 'paypal'
			this.tmpItems = null
		}
		this.items = []
	}

	//////

	public setPricelist(pricelist: Record<string, Price>): void {
		this.pricelist = pricelist

		if (this.tmpItems) {
			this.items = this.tmpItems.filter(
				item => typeof item.resolution === 'undefined' || this.pricelist.hasOwnProperty(item.resolution)
			)
			this.tmpItems = null
		}

		Object.values(this.changeCallback).forEach(c => c())
	}

	//////

	public getGateway(): string {
		return this.gateway
	}

	public getItems(): Array<ITempCartItem> {
		return this.items
	}

	public isValid(): boolean {
		if (this.items.length <= 0 || this.pricelist === null) return false

		for (let i = 0, len = this.items.length; i < len; i++) {
			const cartItem = this.items[i]
			if (!cartItem.avatar || !cartItem.resolution || !this.pricelist.hasOwnProperty(cartItem.resolution)) {
				return false
			}
		}
		return true
	}

	//////

	public onChange(callback: Function): string {
		const key = 'c-' + Object.keys(this.changeCallback).length
		this.changeCallback[key] = callback
		callback()
		return key
	}

	public unbind(callbackKey: string) {
		if (this.changeCallback[callbackKey]) {
			delete this.changeCallback[callbackKey]
		}
	}

	//////

	public add(item: ITempCartItem): boolean {
		let added = false
		if (!this.exist(item)) {
			this.items.push(item)
			added = true
		} else {
			this.items[this.index(item)] = item
			added = false
		}
		this.applyChanges()

		return added
	}

	public remove(item: ITempCartItem) {
		if (this.exist(item)) {
			this.items.splice(this.index(item), 1)

			this.applyChanges()
		}
	}

	public update(prevItem: ITempCartItem, newItem: ITempCartItem) {
		const index = this.index(prevItem)
		if (index >= 0) {
			this.items[index] = newItem

			this.applyChanges()
		}
	}

	public flush() {
		this.gateway = 'paypal'
		this.items = []
		this.applyChanges()
	}

	//////

	public exist(item: ITempCartItem): boolean {
		return this.index(item) >= 0
	}

	public index(item: ITempCartItem): number {
		for (let i = 0, len = this.items.length; i < len; i++) {
			if (this.items[i].avatar === item.avatar && this.items[i].resolution === item.resolution) {
				return i
			}
		}

		return -1
	}

	private applyChanges() {
		Storage.set(CART_STORAGE_KEY, {
			gateway: this.gateway,
			items: this.items,
		})

		Object.values(this.changeCallback).forEach(c => c())
	}
}

export default new CartController()
