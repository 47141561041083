import { IPublicTransaction } from '@faceyourmanga/fym-lib'
import { Auth } from '@faceyourmanga/fym-shared'
import JSZip from 'jszip'
import React from 'react'
import LoadingIcon from '../../../../components/LoadingIcon'
import { createDownload } from '../../../../utilities/createDownload'

interface IDashboardTransactionDownloadProps {
	transaction: IPublicTransaction
}

function DashboardTransactionDownload({ transaction }: IDashboardTransactionDownloadProps) {
	const [loading, setLoading] = React.useState<boolean>(false)

	function download() {
		if (transaction.status === 'completed' && transaction.purchases && transaction.purchases.length > 0) {
			setLoading(true)
			const zip = new JSZip()
			const promises: Array<Promise<Blob>> = []

			transaction.purchases.forEach(purchase => {
				promises.push(
					new Promise(resolve => {
						fetch(getenv('REACT_APP_SERVER_URL') + 'v1/me/purchase/' + purchase + '/download', {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
							},
						}).then(response => {
							response.blob().then(blob => {
								resolve(blob)
							})
						})
					})
				)
			})

			Promise.all(promises).then(images => {
				images.forEach((image, index) => {
					const filename = index + 1 + '__' + transaction.id + '.jpeg'
					zip.file(filename, image)
				})

				zip.generateAsync({ type: 'blob' }).then(function (content) {
					setLoading(false)
					createDownload('download.zip', content, 'application/zip')
				})
			})
		}
	}

	return transaction.status === 'completed' && transaction.purchases && transaction.purchases.length > 0 ? (
		<button className="button button--secondary--stroke button--small" onClick={download}>
			<span style={{ marginRight: '0.5rem' }}>Download all files (ZIP)</span>
			{loading && <LoadingIcon size="1rem" weight={2} color="#293282" />}
		</button>
	) : null
}

export default React.memo(DashboardTransactionDownload)
