import { AvatarId, IPublicAvatar } from '@faceyourmanga/fym-lib'
import store from '../../redux/root'

const SERVER_URL = getenv('REACT_APP_SERVER_URL')
const FACEBOOK_APP_ID = getenv('REACT_APP_FACEBOOK_CLIENT_ID')

export function shareOnFacebook(avatar: IPublicAvatar) {
	const apiUrl = (SERVER_URL.replace(/\/$/, '') + '/share/avatar/' + avatar.id)

	const shareUrl = `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=popup&href=${apiUrl}&redirect_uri=${window.location.origin}`

	window.open(
		shareUrl,
		'FaceYourManga Share Avatar',
		`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300`
	)
}

export function shareOnTwitter(avatar: IPublicAvatar) {
	const apiUrl = (SERVER_URL.replace(/\/$/, '') + '/share/avatar/' + avatar.id)

	const shareUrl = `https://twitter.com/intent/tweet?text=`
	// const avatarUrl = store.getState().hosts.avatars
	// const avatarEndpoint = `${window.location.origin}/avatar/${avatar.id}`
	const text = `Hi! I created this avatar using @faceyourmanga!\n\n${apiUrl}`

	const endpoint = shareUrl + encodeURI(text)

	window.open(
		endpoint.trim(),
		'FaceYourManga Share Avatar',
		`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300`
	)
}
