import { Price } from '@faceyourmanga/fym-lib/dist'

export function resolutionToNumber(resolution: string) {
	return resolution
		.split('x')
		.map(t => parseInt(t))
		.reduce((acc, current) => acc * current, 1)
}

export function sortPrices(prices: Record<string, Price>): Array<Price & { resolution: string }> {
	return Object.keys(prices)
		.sort((a, b) => resolutionToNumber(a) - resolutionToNumber(b))
		.map(resolution => ({
			resolution,
			...prices[resolution],
		}))
}
