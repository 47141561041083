import { BillingInfo, IUser, Nationalities, countryNameMap } from '@faceyourmanga/fym-lib'
import { Auth } from '@faceyourmanga/fym-shared'
import React from 'react'
import { connect } from 'react-redux'
import { CountryCodes } from 'validate-vat-ts'
import { IRootState } from '../../../types/store'
import Input from '../../components/form/Input'
import SelectEuropeanNationality from '../../components/form/SelectEuropeanNationality'

//https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html
const vatEuropeanRegex = new RegExp(
	`^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$`
)

//@ts-ignore
const EurpopeanCountryCodes = Object.values(countryNameMap).filter(c => Object.values(CountryCodes).includes(c))
//@ts-ignore
const EurpopeanCountries = Object.keys(countryNameMap).filter(c => EurpopeanCountryCodes.includes(countryNameMap[c]))
//@ts-ignore
const EurpopeanCountriesMap = EurpopeanCountries.reduce((acc, c) => ({ ...acc, [c]: countryNameMap[c] }), {})

const ZIPRegex = new RegExp(`^[0-9]{5}(?:-[0-9]{4})?$`)

interface IBillingInfoProps {
	user: IUser | null
	value: Partial<BillingInfo>
	onChange: (b: Partial<BillingInfo>) => void
}

export async function isValidVat(country: Nationalities, vat: string): Promise<boolean> {
	return new Promise(resolve => {
		fetch(getenv('REACT_APP_SERVER_URL') + 'validate-vat', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
			},
			// @ts-ignore
			body: JSON.stringify({ country: EurpopeanCountriesMap[country], vat }),
		})
			.then(response => response.json())
			.then(status => {
				resolve(status === 'true')
			})
			.catch(err => {
				resolve(false)
			})
	})
}

export function isValidZIP(zip: string) {
	return ZIPRegex.test(zip)
}

function BillingInfoForm(props: IBillingInfoProps) {
	const [bValidVat, setValidVat] = React.useState(false)

	React.useEffect(() => {
		if (props.value.country && props.value.vat) {
			isValidVat(props.value.country, props.value.vat).then(b => setValidVat(b))
		}
	}, [props.value.country, props.value.vat])

	return (
		<div className="cart__billingInfo">
			<h2 className="cart__billingInfo__title">Billing Info</h2>
			<div className="cart__billingInfo__subtitle">All fields are required</div>
			<div className="cart__billingInfo__data">
				<Input
					type="text"
					label="Name"
					value={props.value.name || ''}
					onChange={name => props.onChange({ ...props.value, name })}
					error={typeof props.value.name !== 'undefined' && props.value.name.length < 1}
				/>
				<Input
					type="text"
					label="Surname"
					value={props.value.surname || ''}
					onChange={surname => props.onChange({ ...props.value, surname })}
					error={typeof props.value.surname !== 'undefined' && props.value.surname.length < 1}
				/>
				<Input
					type="text"
					label="Business Name"
					value={props.value.businessName || ''}
					onChange={businessName => props.onChange({ ...props.value, businessName })}
					error={typeof props.value.businessName !== 'undefined' && props.value.businessName.length < 1}
				/>

				<SelectEuropeanNationality
					countries={EurpopeanCountries}
					value={props.value.country}
					onChange={country => props.onChange({ ...props.value, country: country })}
				/>

				<Input
					disabled={props.value.country === null}
					type="text"
					label="Business Vat"
					error={!bValidVat}
					value={props.value.vat || ''}
					onChange={vat => props.onChange({ ...props.value, vat })}
				/>

				<Input
					type="text"
					label="City"
					value={props.value.city || ''}
					error={typeof props.value.city !== 'undefined' && props.value.city.length < 1}
					onChange={city => props.onChange({ ...props.value, city })}
				/>

				<Input
					type="text"
					label="ZIP"
					value={props.value.zip || ''}
					error={typeof props.value.zip !== 'undefined' && !isValidZIP(props.value.zip)}
					onChange={zip => props.onChange({ ...props.value, zip })}
				/>

				<Input
					type="text"
					label="Street"
					value={props.value.street || ''}
					error={typeof props.value.street !== 'undefined' && props.value.street.length < 1}
					onChange={street => props.onChange({ ...props.value, street })}
				/>
			</div>
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		user: state.auth.user,
	}))(BillingInfoForm)
)
