import * as React from 'react'
import { AvatarPagination, IPublicAvatarWithUsername } from '@faceyourmanga/fym-lib'

import { connect } from 'react-redux'
import { IRootState } from '../../../types/store'
import GalleryFilters from './GalleryFilters'
import { useServerPaginator, useDebounce } from '@faceyourmanga/fym-shared'
import GalleryAvatar from './GalleryAvatar'
import { RouteChildrenProps, withRouter } from 'react-router'
import AdsH from '../../ads/AdsH'

const root = document.getElementById('root') as HTMLDivElement

interface IGalleryProps extends RouteChildrenProps {
	bMobile: boolean
}

function Gallery(props: IGalleryProps) {
	const loadmore = React.useRef<HTMLDivElement>(null)
	const urlParams = new URLSearchParams(props.location.search)
	const search = urlParams.get('s') || ''

	const [filters, setFilters] = React.useState<AvatarPagination>({
		search: search,
		sortBy: 'recent',
		order: 'DESC',
		withUsername: true,
		page: 1,
		size: props.bMobile ? 20 : 12,
	})

	const paginator = useServerPaginator<IPublicAvatarWithUsername, AvatarPagination>('v1/avatars', filters)

	function applyFilters(newFilters: AvatarPagination) {
		let query = { ...newFilters }

		if (newFilters.search && newFilters.search[0] === '#') {
			query.search = newFilters.search.substr(1)
			query.searchBy = 'hashtag'
		} else {
			query.search = newFilters.search
			query.searchBy = 'name'
		}
		if (query.search && query.search.length === 0) {
			delete query.search
		}

		paginator.query(query)
	}

	const debounceChangeFilter = useDebounce<AvatarPagination>(newFilters => {
		applyFilters(newFilters)
	}, 100)

	React.useEffect(() => {
		debounceChangeFilter(filters)
	}, [filters.search])

	React.useEffect(() => {
		applyFilters(filters)
	}, [filters.sortBy])

	function next() {
		paginator.appendNext()

		// setTimeout(() => {
		// 	if (loadmore.current) {
		// 		root.scrollTo(0, loadmore.current.offsetTop)
		// 	}
		// }, 100)
	}

	return (
		<section className="gallery">
			<section className="app__content">
				<AdsH slot={'4725160523'} />

				<GalleryFilters filters={filters} onChange={setFilters} clear={paginator.clear} />

				<section className="gallery__content">
					{paginator.getItems().map(avatar => (
						<GalleryAvatar key={avatar.id} {...avatar} />
					))}
				</section>

				<div className="gallery__load-more" ref={loadmore}>
					<button
						className={`button button--secondary--stroke button--rounded ${
							!paginator.hasNext ? 'button--disabled' : ''
						}`}
						onClick={next}
					>
						Load more
					</button>
				</div>

				<AdsH slot={'4685594081'} />
			</section>
		</section>
	)
}

export default React.memo(
	withRouter(
		connect((state: IRootState) => ({
			bMobile: state.device.bMobile,
		}))(Gallery)
	)
)
