import { AvatarId, IPublicAvatarWithUsername, IPublicUser } from '@faceyourmanga/fym-lib'
import { Api } from '@faceyourmanga/fym-shared'
import * as React from 'react'

type AvatarState = {
	redirect: boolean
	avatar: IPublicAvatarWithUsername | undefined
	owner: IPublicUser | undefined
	ownerAvatars: Array<IPublicAvatarWithUsername>
	relateds: Array<IPublicAvatarWithUsername>
}

function useAvatar(avatarId: AvatarId): AvatarState {
	const [redirect, setRedirect] = React.useState<boolean>(false)
	const [avatar, setAvatar] = React.useState<IPublicAvatarWithUsername>()
	const [owner, setOwner] = React.useState<IPublicUser>()
	const [ownerAvatars, setOwnerAvatars] = React.useState<Array<IPublicAvatarWithUsername>>([])
	const [relateds, setRelateds] = React.useState<Array<IPublicAvatarWithUsername>>([])

	async function loadState(avatarId: AvatarId) {
		if (avatarId) {
			try {
				const avatar = await Api.get<IPublicAvatarWithUsername>(`v1/avatars/${avatarId}`)
				setAvatar(avatar)

				try {
					const owner = await Api.get<IPublicUser>(`v1/users/${avatar.user}`)
					setOwner(owner)

					try {
						let relateds = await Api.get<Array<IPublicAvatarWithUsername>>(`v1/avatars/${avatar.id}/relateds`)
						const uniques: Array<IPublicAvatarWithUsername> = []
						relateds.forEach(r => {
							if (r.id !== avatar.id && !uniques.find(u => u.id === r.id)) uniques.push(r)
						})
						relateds = uniques
						setRelateds(relateds)

						if (owner.username) {
							try {
								const ownerAvatars = await Api.get<Array<IPublicAvatarWithUsername>>(`v1/users/${avatar.user}/avatars`)
								const relatedsIds = relateds.map(a => a.id)
								setOwnerAvatars(ownerAvatars.filter(a => a.id !== avatar.id && !relatedsIds.includes(a.id)))
							} catch (e) {}
						}
					} catch (e) {}
				} catch (e) {}
			} catch (e) {
				setRedirect(true)
			}
		}
	}

	React.useEffect(() => {
		loadState(avatarId)
	}, [avatarId])

	return {
		redirect,
		avatar,
		owner,
		ownerAvatars,
		relateds,
	}
}

export default useAvatar
