import { basePrice, IPurchesable, IUser, realPrice, totalPrice } from '@faceyourmanga/fym-lib'
import React from 'react'
import { IPayinfoState } from '../../../redux/payinfo/reducers'
import Loading from '../../components/Loading'

interface ICartPricesProps {
	user: IUser
	payinfo: IPayinfoState
	valid: boolean
	items: Array<IPurchesable>
}

function CartPrices(props: ICartPricesProps) {
	if (
		!props.payinfo ||
		!props.payinfo.prices ||
		Object.keys(props.payinfo.prices).length === 0 ||
		!props.items ||
		props.items.length === 0
	) {
		return <Loading />
	}

	const discount =
		basePrice(props.user.nationality!, props.payinfo, props.items) -
		realPrice(props.user.nationality!, props.payinfo, props.items)

	const price = realPrice(props.user.nationality!, props.payinfo, props.items).toFixed(2)

	return (
		<div className="cart__pay__prices">
			<div className="cart__pay__prices__small">SUBTOTAL</div>
			<div className="cart__pay__prices__small">
				{price} {props.payinfo.currency}
			</div>

			<div className="cart__pay__prices__small">VAT {props.payinfo.vats[props.user!.nationality!]}%</div>
			<div className="cart__pay__prices__small">
				{(
					totalPrice(props.user!.nationality!, props.payinfo, props.items) -
					realPrice(props.user.nationality!, props.payinfo, props.items)
				).toFixed(2)}{' '}
				{props.payinfo.currency}
			</div>

			<div>
				<b>TOTAL</b>
			</div>
			<div>
				<b>
					{totalPrice(props.user!.nationality!, props.payinfo, props.items).toFixed(2)} {props.payinfo.currency}
				</b>
			</div>
		</div>
	)
}

export default React.memo(CartPrices)
