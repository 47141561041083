import { Api } from '@faceyourmanga/fym-shared'

const USERNAME_REGEX = /^[a-zA-Z0-9]{4,16}$/

export function isValidUsername(username: string): boolean {
	if (username.match(USERNAME_REGEX) === null) {
		return false
	}
	return true
}

export async function checkUsernameExists(username: string): Promise<boolean> {
	try {
		return await Api.get<boolean>(`v1/users/@${username}/exists`)
	} catch (e) {
		return true
	}
}
