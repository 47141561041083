import * as React from 'react'

import { AvatarPagination, Hashtag, HashtagStatusPaginated } from '@faceyourmanga/fym-lib'
import { RiHeart3Fill, RiStarSFill, RiTimeFill } from 'react-icons/ri'
import InputSearch from '../../components/form/InputSearch'

import { Api } from '@faceyourmanga/fym-shared'

interface IGalleryFilter {
	filters: AvatarPagination
	onChange: (filters: AvatarPagination) => void
	clear: () => void
}

function GalleryFilters(props: IGalleryFilter) {
	const [hashtags, setHashtags] = React.useState<Array<Hashtag>>([])

	React.useEffect(() => {
		Api.get<HashtagStatusPaginated>(`v1/hashtags?search=&size=10&order=DESC`).then(result => {
			setHashtags(result.embedded.map(hashtagStatus => hashtagStatus.name))
		})
	}, [])

	return (
		<header className="gallery__filters">
			<div className="gallery__filters__sortBy">
				<div
					className={`gallery__filters__sortBy__item ${
						props.filters.sortBy === 'recent' ? 'gallery__filters__sortBy__item--active' : ''
					}`}
					onClick={() => {
						props.clear()
						props.onChange({ ...props.filters, sortBy: 'recent' })
					}}
				>
					<RiTimeFill /> <span>Recent</span>
				</div>
				<div
					className={`gallery__filters__sortBy__item ${
						props.filters.sortBy === 'recent-likes' ? 'gallery__filters__sortBy__item--active' : ''
					}`}
					onClick={() => {
						props.clear()
						props.onChange({ ...props.filters, sortBy: 'recent-likes' })
					}}
				>
					<RiStarSFill /> <span>Popular</span>
				</div>
				<div
					className={`gallery__filters__sortBy__item ${
						props.filters.sortBy === 'likes' ? 'gallery__filters__sortBy__item--active' : ''
					}`}
					onClick={() => {
						props.clear()
						props.onChange({ ...props.filters, sortBy: 'likes' })
					}}
				>
					<RiHeart3Fill /> <span>Most liked</span>
				</div>
			</div>
			<div className="gallery__filters__search">
				<div className="gallery__filters__search__input">
					<InputSearch
						debounce={1000}
						label="Search by name or hashtag"
						value={props.filters.search!}
						onChange={search => props.onChange({ ...props.filters, search })}
					/>
				</div>

				<div className="gallery__filters__search__hashtags">
					{hashtags
						.filter(h => '#' + h !== props.filters.search)
						.map(hashtag => (
							<small
								className="gallery__filters__search__hashtags__hashtag hashtag"
								key={hashtag}
								onClick={() => props.onChange({ ...props.filters, search: '#' + hashtag })}
							>
								<span>#</span>
								{hashtag}
							</small>
						))}
				</div>
			</div>
		</header>
	)
}

export default React.memo(GalleryFilters)
