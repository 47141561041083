import React from 'react'
import { RiAlertFill } from 'react-icons/ri'
import LoadingIcon from './components/LoadingIcon'

function ServerDown({ display }: { display: string }) {
	return (
		<main className={`app app--${display}`}>
			<section className="server-down">
				<section className="server-down__wrapper">
					<img className="server-down__logo" src="/assets/images/logo.svg" alt="faceyourmanga logo" />

					<div className="server-down__container">
						<div className="server-down__loading">
							<LoadingIcon size="10vh" />
						</div>
						<div>
							<h1 className="server-down__title">
								<RiAlertFill />
								<span>Server down for maintenance</span>
							</h1>
							<p className="server-down__message">
								App will be made available as soon as the works are finished.
								<br />
								<b>We apologize for the inconvenience.</b>
							</p>
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default React.memo(ServerDown)
