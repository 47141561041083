import { IPublicAvatarWithUsername } from '@faceyourmanga/fym-lib'
import React from 'react'
import AvatarPreview from '../../components/AvatarPreview/AvatarPreview'
import AvatarPaginator from '../../components/AvatarPaginator'

function AvatarRelateds({ relateds }: { relateds: Array<IPublicAvatarWithUsername> }) {
	return relateds.length > 0 ? (
		<div className="avatar__relateds">
			<h2 className="avatar__relateds__title">Related</h2>
			<AvatarPaginator items={relateds} forPage={6}>
				{related => (
					<AvatarPreview
						key={'rel-' + related.id}
						id={related.id}
						name={related.name}
						thumb={related.thumb}
						likes={related.likes}
						owner={related.username}
						withLike
						withOwner
					/>
				)}
			</AvatarPaginator>
		</div>
	) : null
}

export default React.memo(AvatarRelateds)
