import { IPublicAvatarWithUsername } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import AvatarPreview from '../../components/AvatarPreview/AvatarPreview'

// RiPencilRuler2Line, RiShareLine
function GalleryAvatar({ id, name, thumb, username, likes }: IPublicAvatarWithUsername) {
	return (
		<article className="gallery__content__avatar gallery-avatar">
			<section className="gallery-avatar__body">
				<AvatarPreview id={id} name={name} owner={username} thumb={thumb} likes={likes} withLike withOwner />
			</section>
		</article>
	)
}

export default React.memo(GalleryAvatar)
