import { IPublicAvatar, IPublicAvatarWithUsername, IPublicUser, Username } from '@faceyourmanga/fym-lib'
import { Api } from '@faceyourmanga/fym-shared'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../../../types/store'
import AvatarPreview from '../../components/AvatarPreview/AvatarPreview'
import UserProfileImage from '../../components/UserProfileImage'
import AvatarPaginator from '../../components/AvatarPaginator'

function AvatarOwnerAvatars({
	owner,
	ownerAvatars,
}: {
	ownerAvatars: Array<IPublicAvatarWithUsername>
	owner: IPublicUser | undefined
}) {
	const avatars = ownerAvatars.reverse().slice(0, 10)

	return owner && owner.username && ownerAvatars.length > 0 ? (
		<div className="avatar__owner">
			<h2 className="avatar__owner__title">
				Others by
				<Link className="avatar__owner__link" to={`/@${owner.username}`}>
					<UserProfileImage user={owner} />@{owner.username}
				</Link>
			</h2>

			<AvatarPaginator items={avatars} forPage={6}>
				{avatar => (
					<AvatarPreview
						key={'ow-' + avatar.id}
						id={avatar.id}
						name={avatar.name}
						thumb={avatar.thumb}
						likes={avatar.likes}
						owner={owner.username}
						withLike
						withOwner
					/>
				)}
			</AvatarPaginator>
		</div>
	) : null
}

export default React.memo(
	connect((state: IRootState) => ({
		avatarUrl: state.hosts.avatars,
	}))(AvatarOwnerAvatars)
)
