import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IRootState } from '../types/store'

function Footer() {
	return (
		<footer className="app__footer">
			<div className="app__content">
				<div className="app__foooter__content">
					<dl>
						<dt>
							<img className="app__footer__logo" src="/assets/images/logo.svg" alt="faceyourmanga logo" />
						</dt>
						<dd>Copyright © 2008 - 2021 All Rights Reserved</dd>
						<dd>PI. 16357401005</dd>
					</dl>
				</div>

				<div className="app__foooter__content">
					<dl>
						<dt>Follow us</dt>
						<dd className="app__footer__socials">
							<a href="https://www.facebook.com/faceyourmangaworld" target="_blank">
								<svg xmlns="http://www.w3.org/2000/svg" overflow="visible" viewBox="0 0 1024 1024">
									<path d="M968.538 511.671c0-252.8-204.372-457.687-456.538-457.687S55.462 258.871 55.462 511.671c0 228.486 166.922 417.818 385.204 452.145V643.97H324.748v-132.3h115.918V410.837c0-114.69 68.124-178.069 172.45-178.069 49.934 0 102.186 8.94 102.186 8.94V354.34H657.7c-56.71 0-74.366 35.31-74.366 71.514v85.816h126.618l-20.241 132.3H583.334v319.845c218.282-34.327 385.204-223.659 385.204-452.145z" />
								</svg>
							</a>
							<a href="https://www.instagram.com/faceyourmanga/" target="_blank">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path d="M186.7 256c0-38.3 31-69.3 69.3-69.3s69.3 31 69.3 69.3-31 69.3-69.3 69.3-69.3-31-69.3-69.3m-37.5 0c0 59 47.8 106.8 106.8 106.8S362.8 315 362.8 256 315 149.2 256 149.2 149.2 197 149.2 256m193.5-111.1c0 13.8 11.2 25 25 25s25-11.2 25-25-11.2-25-25-25-25 11.2-25 25M171.9 425.3c-13.2-.1-26.3-2.5-38.7-7.1-9-3.3-17.2-8.7-23.9-15.6-6.9-6.7-12.3-14.9-15.6-23.9-4.6-12.4-7-25.5-7.1-38.7-1-22-1.2-28.6-1.2-84.1s0-62.1 1.2-84.1c.1-13.2 2.5-26.3 7.1-38.7 3.3-9 8.7-17.2 15.6-23.9 6.7-6.9 14.9-12.3 23.9-15.6 12.4-4.6 25.5-7 38.7-7.1 22-1 28.6-1.2 84.1-1.2s62.1 0 84.1 1.2c13.2.1 26.3 2.5 38.7 7.1 9 3.3 17.2 8.7 23.9 15.6 6.9 6.7 12.3 14.9 15.6 23.9 4.6 12.4 7 25.5 7.1 38.7 1 22 1.2 28.4 1.2 84.1s0 62.1-1.2 84.1c-.1 13.2-2.5 26.3-7.1 38.7-7 18.1-21.4 32.5-39.5 39.5-12.4 4.6-25.5 7-38.7 7.1-22 1-28.4 1.2-84.1 1.2s-62.1.1-84.1-1.2m-1.7-376.1c-17.2.4-34.3 3.6-50.4 9.7-14 5.1-26.6 13.2-37.1 23.8-10.6 10.4-18.7 23.1-23.8 37.1-6.1 16.1-9.3 33.2-9.7 50.4C48 192.4 48 199.5 48 256s0 63.6 1.2 85.8c.4 17.2 3.6 34.3 9.7 50.4 5.1 13.9 13.2 26.6 23.7 37.1 10.4 10.6 23 18.7 36.9 23.9 16.1 6.1 33.2 9.3 50.4 9.7 22.4 1 29.5 1 86 1s63.6 0 85.8-1.2c17.2-.4 34.3-3.6 50.4-9.7 27.9-10.8 50-32.9 60.8-60.8 6.1-16.1 9.3-33.2 9.7-50.4 1.2-22.2 1.2-29.3 1.2-85.8s0-63.6-1.2-85.8c-.4-17.2-3.6-34.3-9.7-50.4-5.1-13.9-13.2-26.6-23.7-37.1S406.1 64.1 392.1 59c-16.1-6.1-33.2-9.3-50.4-9.7C319.6 48 312.5 48 256 48s-63.6 0-85.8 1.2" />
								</svg>
							</a>
							<a href="https://twitter.com/faceyourmanga" target="_blank">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
									<path d="M125.7 362.6c150.9 0 233.5-125.1 233.5-233.5 0-3.5 0-7-.2-10.6 16-11.5 30-26 41-42.5-14.7 6.6-30.6 10.9-47.2 13 17-10.1 29.9-26.2 36.2-45.4-15.8 9.4-33.4 16.2-52.2 19.8-15-16-36.3-25.9-59.8-25.9-45.3 0-82.1 36.8-82.1 82.1 0 6.4.8 12.6 2.1 18.7-68.2-3.4-128.7-36.2-169.1-85.8-7 12.2-11 26.2-11 41.3 0 28.5 14.6 53.6 36.5 68.3-13.4-.5-26.1-4.2-37.1-10.2v1.1c0 39.7 28.3 73 65.8 80.5-6.9 1.9-14.1 2.9-21.6 2.9-5.3 0-10.4-.5-15.4-1.4 10.4 32.6 40.8 56.3 76.7 57-28.4 21.9-63.8 35.1-102.2 35.1-6.6 0-13.1-.3-19.5-1.1 36.2 23 79.4 36.6 125.6 36.6" />
								</svg>
							</a>
						</dd>
					</dl>
				</div>

				<div className="app__foooter__content">
					<nav className="app__footer__nav">
						<dl>
							<dt>Resources</dt>
							<dd>
								<Link to="/terms-of-use">Terms</Link>
							</dd>
							<dd>
								<Link to="/privacy-cookie-policy">Privacy Policy</Link>
							</dd>
							<dd>
								<a href="https://legacy.faceyourmanga.com" rel="noreferrer" target='_blank'>
									FYM in History
								</a>
							</dd>
						</dl>
					</nav>
				</div>
			</div>
		</footer>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		bMobile: state.device.bMobile,
	}))(Footer)
)
