import { AvatarId, IPersonalAvatar } from '@faceyourmanga/fym-lib'
import { Auth, PrivateApi, PromiseButton, UnprocessableEntity } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { RiArrowLeftLine, RiDeleteBin7Line, RiEdit2Fill, RiEyeOffLine } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Redirect, RouteChildrenProps } from 'react-router'
import { Link } from 'react-router-dom'
import { IWithExistentUser } from '../../../../../types/interfaces'
import { IRootState } from '../../../../../types/store'
import AdsH from '../../../../ads/AdsH'
import AddToCartButton from '../../../../cart/AddToCartButton'
import AvatarPreview from '../../../../components/AvatarPreview/AvatarPreview'
import ConfirmPromise from '../../../../components/Confirm'
import Input from '../../../../components/form/Input'
import InputHashtags from '../../../../components/form/InputHashtags'
import Textarea from '../../../../components/form/Textarea'
import Loading from '../../../../components/Loading'
import dispatchMessage from '../../../../messages/dispatchMessage'
import DashboardPaginator from '../DashboardPaginator'

const MESSAGE_LENGTH = 100
interface IUpdateAvatarProps extends IWithExistentUser, RouteChildrenProps<{ avatarId: AvatarId }> {
	avatarUrl: string
}

function UpdateAvatar(props: IUpdateAvatarProps) {
	const [redirect, setRedirect] = React.useState<boolean>(false)
	const [avatar, setAvatar] = React.useState<IPersonalAvatar>()
	const [avatars, setAvatars] = React.useState<Array<IPersonalAvatar>>([])
	const [needEdit, setNeedEdit] = React.useState<boolean>(false)

	React.useEffect(() => {
		PrivateApi.get<Array<IPersonalAvatar>>('v1/me/avatars').then(avatars => {
			setAvatars(avatars.sort((a, b) => b.created_at - a.created_at))
		})
	}, [])

	React.useEffect(() => {
		if (props.match && props.match.params.avatarId && props.user.avatars.includes(props.match.params.avatarId)) {
			PrivateApi.get<IPersonalAvatar>(`v1/me/avatars/${props.match.params.avatarId}`)
				.then(avatar => {
					setAvatar(avatar)

					PrivateApi.get<boolean>(`v1/avatars/${avatar.id}/valid`).then(v => setNeedEdit(!v))
				})
				.catch(() => setRedirect(true))
		} else {
			setRedirect(true)
		}
	}, [props.match, props.user])

	async function setProfileImage() {
		try {
			await PrivateApi.put<IPersonalAvatar>(`v1/me/thumb`, props.match!.params.avatarId)

			Auth.setUser({ ...props.user, thumb: props.match!.params.avatarId })

			dispatchMessage('Profile image update!')
		} catch (e) {
			if (e instanceof UnprocessableEntity) {
				dispatchMessage('You cannot set a private image as a profile picture')
			}
		}
	}

	async function toggleVisibility() {
		if (avatar) {
			try {
				const _private = !avatar.private

				let c = true
				if (props.user.thumb === avatar.id) {
					c = await ConfirmPromise('If you continue your profile picture will be removed')
				}

				if (c) {
					await PrivateApi.put<IPersonalAvatar>(`v1/me/avatars/${props.match!.params.avatarId}`, {
						...avatar,
						private: _private,
					})

					setAvatar({ ...avatar, private: _private })

					if (_private && props.user.thumb === avatar.id) {
						Auth.setUser({ ...props.user, thumb: undefined })
					}

					dispatchMessage(`The avatar is now ${_private ? 'private' : 'public'}`)
				}
			} catch (e) {
				dispatchMessage('An error has occurred. Try later')
			}
		}
	}

	async function deleteAvatar() {
		if (avatar) {
			if (
				await ConfirmPromise(
					avatar.id !== props.user.thumb
						? 'Sure you want to continue'
						: 'Are you using this avatar as your profile picture, do you want to delete it?'
				)
			) {
				try {
					await PrivateApi.delete(`v1/me/avatars/${props.match!.params.avatarId}`)

					const avatars = [...props.user.avatars]

					avatars.splice(avatars.indexOf(props.match!.params.avatarId), 1)

					Auth.setUser({
						...props.user,
						avatars: avatars,
						thumb: avatar.id === props.user.thumb ? undefined : props.user.thumb,
					})

					setRedirect(true)

					dispatchMessage('The avatar has been deleted')
				} catch (e) {
					console.log([e])
					dispatchMessage('Cannot delete avatar')
				}
			}
		}
	}

	async function update() {
		try {
			const result = await PrivateApi.put<IPersonalAvatar>(`v1/me/avatars/${props.match!.params.avatarId}`, avatar)
			dispatchMessage('The avatar has been updated')
		} catch (e) {
			dispatchMessage('An error has occurred. Try later')
		}
	}

	if (!avatar) {
		return <Loading />
	}

	if (redirect) {
		return <Redirect to="/profile/avatars" />
	}

	return (
		<section className="personal-avatar ">
			<section className="app__content personal-avatar__content">
				<AdsH slot={'8848200373'} />

				<div className="personal-avatar__back">
					<Link to="/profile/avatars">
						<RiArrowLeftLine />
					</Link>
					<span>Edit</span>
				</div>

				<div className="personal-avatar__image-wrapper">
					<div className="personal-avatar__image-wrapper__image">
						<img src={props.avatarUrl + '/' + avatar.thumb} alt="FaceYourManga | Personal Avatar" />

						{!avatar.private && (
							<button
								onClick={setProfileImage}
								className="button button--small personal-avatar__image-wrapper__image__button"
							>
								Set as profile image
							</button>
						)}
					</div>
					<Link
						className="button button--secondary button--rounded button--padded"
						to={`/update/${props.match?.params.avatarId}`}
					>
						<RiEdit2Fill /> Edit
					</Link>

					<div>
						<div className="personal-avatar__image-wrapper__delete" onClick={deleteAvatar}>
							<RiDeleteBin7Line />
							Delete Avatar
						</div>
					</div>
				</div>

				<div className="personal-avatar__meta">
					{needEdit && (
						<div className="personal-avatar__meta__row personal-avatar__meta__row">
							<div className="personal-avatar__edit-message">
								<b>The FYM Engine has been updated and this avatar needs to be edited to update or purchase it</b>
							</div>
						</div>
					)}
					<div className="personal-avatar__meta__row">
						<Input label="Name" value={avatar.name || ''} onChange={name => setAvatar({ ...avatar, name })} />
					</div>
					<div className="personal-avatar__meta__row">
						<Textarea
							label={!!(avatar.message && avatar.message.length > MESSAGE_LENGTH) ? 'Message too long' : 'Message'}
							error={!!(avatar.message && avatar.message.length > MESSAGE_LENGTH)}
							value={avatar.message || ''}
							onChange={message => setAvatar({ ...avatar, message })}
						/>
					</div>
					<div className="personal-avatar__meta__row">
						<InputHashtags value={avatar.hashtags} onChange={hashtags => setAvatar({ ...avatar, hashtags })} />
					</div>
					<div className="personal-avatar__meta__buttons">
						<div className="personal-avatar__meta__buttons__visibility">
							<input type="checkbox" checked={avatar.private} onChange={toggleVisibility} />
							<span onClick={toggleVisibility}>
								<RiEyeOffLine /> Set as Private
							</span>
							{/* {!avatar.private && <Link to={`/avatar/${avatar.id}`}>view public link</Link>} */}
						</div>
						<PromiseButton
							onClick={update}
							className="button--save button button--green button--rounded button--padded"
						>
							SAVE
						</PromiseButton>

						<AddToCartButton avatarId={avatar.id} />
					</div>
				</div>
			</section>

			<section className="app__content personal-avatar__createds">
				<section className="dashboard__avatars">
					<h2 className="dashboard__avatars__title">Created</h2>
					<div className="dashboard__avatars__list">
						{avatars.length > 0 ? (
							<DashboardPaginator fixedHeight items={avatars} forPage={6} fill>
								{(avatar, index) =>
									avatar ? (
										<div className="dashboard__avatars__list__avatar" key={avatar.id}>
											<AvatarPreview
												id={avatar.id}
												name={avatar.name}
												thumb={avatar.thumb}
												likes={avatar.likes}
												withLike
												isPrivate
											/>
										</div>
									) : (
										<div className="dashboard__avatars__list__avatar" key={index}>
											<div className="avatar-preview">
												<img className="avatar-preview__image" src="/assets/images/px.png" alt="" />
											</div>
										</div>
									)
								}
							</DashboardPaginator>
						) : (
							<div>no avatar created</div>
						)}
					</div>
				</section>
				<AdsH slot={'4685594081'} />
			</section>
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		avatarUrl: state.hosts.avatars,
		user: state.auth.user!,
	}))(UpdateAvatar)
)
