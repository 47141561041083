/*
 * action types
 */

export const RESET_DEVICE_INFORMATION = 'RESET_DEVICE_INFORMATION'

/*
 * action creators
 */

export const resetDeviceInformation = () => ({ type: RESET_DEVICE_INFORMATION })

export type DeviceActionsType = {
	type: typeof RESET_DEVICE_INFORMATION
}
