import { BillingInfo, IUser } from '@faceyourmanga/fym-lib'
import React from 'react'
import CartController from '../../cart/CartController'
import { ITempCartItem } from '../../cart/CartTypes'
import { isValidVat, isValidZIP } from './BillingInfo'

function useCart(user: IUser): {
	valid: boolean
	items: Array<ITempCartItem>
	billingInfo: Partial<BillingInfo>
	setBillingInfo: (bi: Partial<BillingInfo>) => void
	bUseBilling: boolean
	setbUseBillingInfo: (b: boolean) => void
} {
	const [bUseBilling, setbUseBillingInfo] = React.useState(false)
	const [valid, setValid] = React.useState<boolean>(false)
	const [items, setItems] = React.useState<Array<ITempCartItem>>([...CartController.getItems()])
	const [billingInfo, setBillingInfo] = React.useState<Partial<BillingInfo>>({
		country: user!.nationality!,
	})

	async function validateBilling() {
		return (
			typeof billingInfo.name !== 'undefined' &&
			billingInfo.name.length > 0 &&
			typeof billingInfo.surname !== 'undefined' &&
			billingInfo.surname.length > 0 &&
			typeof billingInfo.businessName !== 'undefined' &&
			billingInfo.businessName.length > 0 &&
			typeof billingInfo.country !== 'undefined' &&
			billingInfo.country.length > 0 &&
			typeof billingInfo.vat !== 'undefined' &&
			typeof billingInfo.country !== 'undefined' &&
			(await isValidVat(billingInfo.country, billingInfo.vat)) &&
			typeof billingInfo.city !== 'undefined' &&
			billingInfo.city.length > 0 &&
			typeof billingInfo.zip !== 'undefined' &&
			isValidZIP(billingInfo.zip) &&
			typeof billingInfo.street !== 'undefined' &&
			billingInfo.street.length > 0
		)
	}

	React.useEffect(() => {
		const bValidCart = CartController.isValid()

		if (bUseBilling) {
			if (bValidCart) {
				validateBilling().then(bValidBilling => setValid(bValidCart && bValidBilling))
			} else {
				setValid(false)
			}
		} else {
			setValid(bValidCart)
		}
	}, [items, bUseBilling, billingInfo])

	React.useEffect(() => {
		const cid = CartController.onChange(() => {
			setItems([...CartController.getItems()])
		})

		return () => {
			CartController.unbind(cid)
		}
	}, [])

	return {
		valid,
		items,
		billingInfo,
		setBillingInfo,
		bUseBilling,
		setbUseBillingInfo,
	}
}

export default useCart
