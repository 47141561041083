import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { RiCloseCircleLine } from 'react-icons/ri'
import { connect } from 'react-redux'
import { IRootState } from '../../types/store'

interface IModalProps {
	bMobile: boolean
	open: boolean
	noCloseButton?: boolean
	close?: (e: React.MouseEvent) => void
	bCloseOnBackground?: boolean
	children?: JSX.Element
}

function Modal(props: IModalProps) {
	const modalRoot: HTMLElement = document.getElementById('modal-root') as HTMLElement
	const container: HTMLDivElement = document.createElement('div')

	React.useEffect(() => {
		modalRoot.appendChild(container)

		return () => {
			modalRoot.removeChild(container)
		}
	}, [modalRoot, container])

	let children = props.children

	if (props.children) {
		children = React.cloneElement(props.children, { close: props.close })
	}

	return props.open
		? ReactDOM.createPortal(
				<div className={`modal modal--${props.bMobile ? 'mobile' : 'desktop'}`}>
					<div className="modal__background" onClick={e => props.bCloseOnBackground && props.close && props.close(e)} />
					<div className="modal__content">
						{!props.noCloseButton && (
							<div className="modal__content__close-icon">
								<RiCloseCircleLine onClick={props.close} name="close" />
							</div>
						)}
						<div className="modal__content__wrapper">{children}</div>
					</div>
				</div>,
				container
		  )
		: null
}

export default React.memo(
	connect((state: IRootState) => ({
		bMobile: state.device.bMobile,
	}))(Modal)
)
