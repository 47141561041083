import * as React from 'react'
import { useIntersection } from '@faceyourmanga/fym-shared'

interface IAppearProp {
	children?: JSX.Element | string | null
	className?: string

	delay?: number
	origin?: string
	duration?: number
	stopOnVisible?: boolean
}

function Appear({
	children,
	className,
	style,
	stopOnVisible,
	...props
}: IAppearProp & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
	const ref = React.createRef<HTMLDivElement>()

	const visible = useIntersection<HTMLDivElement>(
		ref,
		{
			threshold: 0.25,
		},
		stopOnVisible
	)

	return (
		<div ref={ref}>
			<div
				style={{
					transformOrigin: props.origin || '',
					transitionDelay: (props.delay || 0) + 'ms',
					transitionDuration: (props.duration || 300) + 'ms',
					...style,
				}}
				{...props}
				className={`${className || ''} appear appear--${visible ? 'visible' : 'hidden'}`}
			>
				{children}
			</div>
		</div>
	)
}

export default React.memo(Appear)
