import * as React from 'react'
import { IconContext } from 'react-icons'
import { connect } from 'react-redux'
import { RouteChildrenProps, withRouter } from 'react-router'
import '../sass/app.scss'
import { IRootState } from '../types/store'
import Aside from './Aside'
import GDPR from './auth/GDPR'
import Footer from './Footer'
import Header from './Header'
import Messages from './messages/Messages'
import Modals from './Modals'
import Router from './Router'
import ServerDown from './ServerDown'
import ReactGA from 'react-ga4'

//ReactGA.initialize('G-K4425CK0HB')
ReactGA.initialize('G-ZPDZFLS5YN')

interface IAppProps extends RouteChildrenProps {
	serverDown: boolean
	bMobile: boolean
	needGDPRConfirmation: boolean
}

function App(props: IAppProps) {
	const appSection = React.createRef<HTMLDivElement>()

	React.useEffect(() => {
		let wr = false

		function resize() {
			if (appSection && appSection.current) {
				appSection.current.style.height = window.innerHeight + 'px'
			}
		}

		if (
			props.bMobile &&
			(props.location.pathname.indexOf('/create') >= 0 || props.location.pathname.indexOf('/update') >= 0)
		) {
			wr = true
			window.addEventListener('resize', resize, { passive: true })
			resize()
		} else if (appSection && appSection.current) {
			appSection.current.style.height = ''
		}

		return () => {
			if (wr) {
				window.removeEventListener('resize', resize)
			}
		}
	}, [appSection, props.location.pathname])

	React.useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: props.location.pathname + props.location.search,
			title: "Custom Title"
		});
	}, [appSection, props.location.pathname])

	React.useEffect(() => {
		if (!props.bMobile) {
			function onResize() {
				const windowWidth = window.innerWidth
				const clampedWidth = windowWidth <= 320 ? 320 : windowWidth >= 2560 ? 2560 : windowWidth

				const rootBase = Math.floor(11 + (clampedWidth / 2560) ** 0.5 * 6)

				document.documentElement.style.fontSize = rootBase + 'px'
			}

			window.addEventListener('resize', onResize, { passive: true })

			onResize()

			return () => {
				window.removeEventListener('resize', onResize)
			}
		} else {
			document.documentElement.style.fontSize = ''
		}
	}, [props.bMobile])

	return props.serverDown ? (
		<ServerDown display={props.bMobile ? 'mobile' : 'desktop'} />
	) : (
		<React.Fragment>
			<main className={`app app--${props.bMobile ? 'mobile' : 'desktop'}`}>
				<Header />

				{props.needGDPRConfirmation ? (
					<section className="app__section">
						<GDPR />
					</section>
				) : (
					<React.Fragment>
						<section ref={appSection} className="app__section">
							<IconContext.Provider value={{ className: 'icon' }}>
								<Router />
							</IconContext.Provider>
						</section>
						{props.bMobile && <Aside />}
					</React.Fragment>
				)}

				{(props.location.pathname !== '/create' || !props.bMobile) && <Footer />}

				<Messages />
			</main>
			<Modals />
		</React.Fragment>
	)
}

export default React.memo(
	withRouter(
		connect((state: IRootState) => ({
			serverDown: state.hosts.serverDown,
			bMobile: state.device.bMobile,
			needGDPRConfirmation: state.auth.passed_gdpr === false,
		}))(App)
	)
)
