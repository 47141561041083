import { ApiException, Auth, PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { Redirect } from 'react-router'
import { IValidationMessage } from '../../../types/validation'
import Input from '../../components/form/Input'
import ValidationMessages from '../ValidationMessages'

function PasswordReset() {
	const resetToken = new URLSearchParams(window.location.search).get('reset')
	const [newPassword, setNewPassword] = React.useState<string>('')
	const [repeatPassword, setRepeatPassword] = React.useState<string>('')

	const [resetted, setResetted] = React.useState<boolean>(false)
	const [redirect, setRedirect] = React.useState<boolean>(false)

	const [errors, setErrors] = React.useState<Array<IValidationMessage>>([])

	async function reset() {
		try {
			await Auth.passwordReset(resetToken || '', newPassword)
			setResetted(true)
			setTimeout(() => {
				setRedirect(true)
			}, 3000)
		} catch (e: any) {
			console.log(e)
			setErrors((e as ApiException).data.validation)
		}
	}

	if (redirect) {
		return <Redirect to="/sign-in" />
	}

	if (resetted) {
		return (
			<section className="app__page auth-emails__password-reset">
				<section className="app__content">
					<h1 className="auth-emails__password-reset__success">
						Password has been reset, you will be redirected to login shortly
					</h1>
				</section>
			</section>
		)
	}

	if (resetToken) {
		return (
			<section className="app__page auth-emails__password-reset">
				<section className="app__content">
					<div className="auth-emails__form">
						<h1>Password Reset</h1>

						<Input label="New password" type="password" value={newPassword} onChange={setNewPassword} />

						<Input
							label="Repeat password"
							type="password"
							value={repeatPassword}
							onChange={setRepeatPassword}
						/>

						<PromiseButton
							disabled={newPassword.length <= 3 || newPassword !== repeatPassword}
							className="button"
							onClick={reset}
						>
							Reset
						</PromiseButton>

						<div className="auth-emails__form-errors">
							<ValidationMessages errors={errors} />
						</div>
					</div>
				</section>
			</section>
		)
	}

	return null
}

export default PasswordReset
